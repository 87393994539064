import React from "react";
import { BooleanFlag } from "@coworker/components";
import { Widget } from "@coworker/components/src/components/Widget";

export default function TaskFormBoolean({
  value,
  onChange,
  conf: { title, icon, testId, overridePadding, titleMargin },
}) {
  React.useEffect(() => {
    if (value !== true && value !== false) {
      onChange(false);
    }
  }, [value, onChange]);
  return (
    <Widget>
      <BooleanFlag
        testId={testId}
        title={title}
        checked={value}
        onToggle={() => onChange(!value)}
        icon={icon}
        overridePadding={overridePadding}
        titleMargin={titleMargin}
      />
    </Widget>
  );
}
