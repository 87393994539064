import React from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import InlineMessage from "@ingka/inline-message";
import ListView, { ListViewItem } from "@ingka/list-view";
import checkmark from "@ingka/ssr-icon/paths/checkmark"; // NOSONAR
import informationCircle from "@ingka/ssr-icon/paths/information-circle"; // NOSONAR
import { Trans, useTranslation } from "@coworker/locales";
import { RoomSettings } from "@coworker/types/lib/tasks/room_settings";
import { WidgetDivider } from "@coworker/reusable/components/Widgets";
import { useArticleById } from "../hooks/useArticleById";
import { useArticlesByRoomId } from "../hooks/useArticlesByRoomId";
import { useCreateTask } from "../../../hooks/TaskActions/useCreateTask";
import { useInputPopup } from "../../InputPopup";
import { useRoomById } from "../hooks/useRoomById";
import { useStoreId, useUserId } from "../../../core/auth/useLoggedInUser";
import { useTaskStateUpdates } from "../hooks/useTaskStateUpdates";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { Reasons } from "../constants";
import { AssigneeSelection } from "../../../core/components/widgets/AssigneeSelection";
import { getTranslationKeyFromReason } from "../tools";
import { insertTaskLink } from "../services/tasks.service";
import {
  isMainArticle,
  isBackupForProduct,
} from "../Molecules/RoomArticles.helper";
import { todosAggregation, getHeader } from "./CreateTaskView.helper";
import { ToggleField } from "../../../core/components/widgets/ToggleField";
import { roomSettingsCreateTaskFactory } from "../ObjectFactories/TaskFactory";
import { Article, ArticleInfo } from "../types/article";
import { TaskLink } from "../types/taskLink";
import { TaskTodos, TaskTodosSummaries } from "../types/taskCreation";
import { Flag } from "../Atoms/Icons/Flag";
import { HeadLine } from "../Atoms/HeadLine";
import { Person } from "../Atoms/Icons/Person";
import { Priority } from "../Atoms/Icons/Priority";
import { Sofa } from "../Atoms/Icons/Sofa";
import { Text } from "../Atoms/Text";
import AddDescription from "../Molecules/AddDescription";
import { ArticleListItem } from "../Molecules/ArticleListItem";
import { TaskCard } from "../Molecules/TaskCard";
import { CreateTaskModal } from "../Organisms/Modals/CreateTaskModal";
import { FullWidthButton } from "../../SkapaButton";
import { DeleteArticleModal } from "../Organisms/Modals/DeleteArticleModal";
import { ReplaceProduct } from "../Organisms/ReplaceProduct";
import { TodoListCard } from "../Organisms/TodoListCard";
import { CreateTaskViewSkeleton } from "../Skeletons/CreateTaskViewSkeleton";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { ListWrapper, PaddedContainer } from "../styles/styles";
import AttachPhoto from "../../AttachPhoto";

const WidthFix = styled.div`
  .list-view-item__action {
    width: auto;
  }
`;
const Container = styled.div`
  border-bottom: 1px solid var(--grey150);
`;

export function CreateTaskView() {
  const { roomId, articleId } = useParams();
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const { getInput } = useInputPopup();
  const uid = useUserId();
  const storeId = useStoreId();
  const { call: createTask } = useCreateTask();

  const { data: article, isLoading: articleLoading } =
    useArticleById(articleId);
  const { data: existingArticles } = useArticlesByRoomId(roomId || "");
  const { data: room, isLoading: roomLoading } = useRoomById(roomId);
  const [newArticle, setNewArticle] = React.useState<ArticleInfo | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const [todoSummary, setTodoSummary] = React.useState<TaskTodosSummaries>([
    { pieces: 1, is_new_article: true, is_ptag: false },
  ]);
  const [removeArticleChecked, setRemoveArticleChecked] = React.useState(false);

  const [onClick, setOnClick] = React.useState(false);

  const [localImages, setLocalImages] = React.useState<string[]>([]);

  const defaultTask = roomSettingsCreateTaskFactory(
    uid,
    roomId || "",
    articleId || "",
    article?.itemName || "",
    article?.supplierNumber || "",
    article?.dateStamp || "",
    article?.nbrArticles || 0,
    false,
    false,
    false,
    false,
    room?.name || "",
    Reasons.DAILY_MAINTENANCE,
    article?.productArticleId || "",
    storeId,
    "",
    article?.itemType || "",
    article?.itemColor || "",
    " ",
    article?.imageSmall || "",
    false,
    ""
  );

  const {
    task,
    setTask,
    togglePriority,
    updateAssignedTo,
    setDescription,
    updateImages,
  } = useTaskStateUpdates(defaultTask);

  const existingArticleIds =
    existingArticles?.map(
      (existingArticle) => existingArticle.productArticleId
    ) || [];

  // For now the default setup of useState does not work. Therefor this line
  // is needed to make sure that the task is initiated with the default task.
  if (defaultTask.room_name !== task.room_name) setTask(defaultTask);

  const handleTypeOfTask = async () => {
    const response = await getInput("typeOfRoomSettingTask");
    if (response) {
      setTask({ ...task, reason: response });
      if (
        (response !== Reasons.DAILY_MAINTENANCE &&
          isMainArticle(
            article?.id ?? "",
            room?.main_article_id_1 ?? "",
            room?.main_article_id_2 ?? ""
          )) ||
        isBackupForProduct(
          article?.id ?? "",
          room?.backup_article_id_1 ?? "",
          room?.backup_article_id_2 ?? ""
        )
      ) {
        setOnClick(true);
      }
    }
  };

  async function createTaskByReason(art: Article | null = null) {
    if (!art) {
      return;
    }

    const createTaskWithTaskLink = async (
      task: RoomSettings,
      taskLink: TaskLink
    ) => {
      const taskId = await createTask(task, task.images);
      taskLink.taskId = taskId;
      await insertTaskLink(taskLink);
    };

    if (task.reason === Reasons.REPLACE_PRODUCT) {
      await createTaskWithTaskLink(
        {
          ...task,
          new_product_article_id: newArticle?.id,
          product_article_id: art.productArticleId,
        } as RoomSettings,
        {
          articleId,
          roomId,
          storeId,
          productArticleId: art?.productArticleId,
        } as TaskLink
      );
    } else if (task.reason === Reasons.REMOVE_ARTICLE) {
      await createTaskWithTaskLink(task, {
        articleId,
        roomId,
        storeId,
        productArticleId: art.productArticleId,
      } as TaskLink);
    } else {
      for (const summary of todoSummary) {
        await createTaskWithTaskLink(
          {
            ...task,
            ...summary,
          } as RoomSettings,
          {
            articleId,
            roomId,
            storeId,
            productArticleId: art.productArticleId,
          } as TaskLink
        );
      }
    }
  }

  if (articleLoading || roomLoading)
    return (
      <FullScreenPopup
        appBarConfig={{
          title: t("loadingString"),
          actions: [],
        }}
        noPadding
      >
        <CreateTaskViewSkeleton />
      </FullScreenPopup>
    );

  return (
    <FullScreenPopup
      appBarConfig={{
        title: getHeader(task, room, t),
        actions: [],
      }}
      noPadding
      actionBarContent={
        <>
          {article && !removeArticleChecked && (
            <FullWidthButton
              text={t("createString")}
              disabled={!task.assigned_team_id}
              onClick={async () => {
                await createTaskByReason(article);
                push(`/roomsettings/articles/${article.id}`);
              }}
            />
          )}
          {article && removeArticleChecked && (
            <FullWidthButton
              type={"danger"}
              text={`${t("deleteArticleString")}`}
              onClick={() => setDeleteModalOpen(true)}
              fluid
            />
          )}
          {article && (
            <DeleteArticleModal
              article={article}
              modalOpen={deleteModalOpen}
              onModalOpenChange={(modalOpen) => {
                setDeleteModalOpen(modalOpen);
              }}
            />
          )}
        </>
      }
    >
      <CreateTaskModal
        modalVisibility={onClick}
        onModalChange={() => setOnClick(!onClick)}
        onCancel={() => {
          setTask({ ...task, reason: Reasons.DAILY_MAINTENANCE });
          setOnClick(false);
        }}
      />
      {room && article && (
        <>
          <TaskCard
            leftIcon={<Flag />}
            headLine={<Text text={t("typeOfTaskString")} bold />}
            subText={
              <Text
                text={t(getTranslationKeyFromReason(task.reason))}
                italic
                grey
              />
            }
            onClick={handleTypeOfTask}
          />
          <ListWrapper>
            <ArticleListItem
              article={article}
              isMainProduct={() =>
                isMainArticle(
                  article.id,
                  room.main_article_id_1,
                  room.main_article_id_2
                )
              }
              isBackupForProduct={() =>
                isBackupForProduct(
                  article.id,
                  room.backup_article_id_1 ?? "",
                  room.backup_article_id_2 ?? ""
                )
              }
            />
          </ListWrapper>
          <WidgetDivider />
          {task.reason === Reasons.REPLACE_PRODUCT && (
            <ReplaceProduct
              oldProductArticleIds={existingArticleIds}
              setNewArticle={setNewArticle}
            />
          )}
          {task.reason === Reasons.DAILY_MAINTENANCE && (
            <TodoListCard
              maxPieces={article.nbrArticles}
              todosChanged={(todos: TaskTodos) => {
                setTodoSummary(todosAggregation(todos));
              }}
            />
          )}
          {task.reason === Reasons.REMOVE_ARTICLE && (
            <WidthFix>
              <ListView id="article-removed-list" size={"small"}>
                <ListViewItem
                  title={t("markAsRemovedString")}
                  leadingIcon={checkmark}
                  control={"switch"}
                  controlProps={{
                    value: "",
                    checked: removeArticleChecked,
                    subtle: true,
                  }}
                  onChange={() =>
                    setRemoveArticleChecked(!removeArticleChecked)
                  }
                  emphasised
                  inset
                />
              </ListView>
            </WidthFix>
          )}
          <WidgetDivider />
          <Container>
            <TaskCard
              leftIcon={<Sofa />}
              headLine={<Text text={t("roomSettingNameString")} bold />}
              subText={
                <HeadLine
                  main={room.name}
                  sub1={room.main_article_name_1}
                  sub2={room.main_article_name_2}
                  bold={false}
                />
              }
            />
          </Container>
          <AssigneeSelection
            selectedTeamId={task.assigned_team_id}
            selectedUserId={task.assigned_user_id}
            onConfirm={updateAssignedTo}
            image={<Person />}
          />
          <ToggleField
            title={
              <b>
                <Trans>priorityString</Trans>
              </b>
            }
            image={<Priority />}
            onChange={togglePriority}
            value={task.priority_flag}
          />
          <AddDescription
            editDescription={setDescription}
            value={task.description}
          />
          <AttachPhoto
            images={task.images}
            onImageListChange={updateImages}
            folder={"task_images"}
            localImages={localImages}
            setLocalImages={setLocalImages}
            removeBottomBorder={false}
            lblColor="var(--grey900)"
            overridePadding="32px 16px 32px 24px"
          />
          {task.reason === Reasons.DAILY_MAINTENANCE && (
            <PaddedContainer $py={"8px"} $px={"16px"}>
              <InlineMessage
                title={t("whatsNextString")}
                body={t("whatsNextDescriptionString")}
                variant={"informative"}
                ssrIcon={informationCircle}
                dismissable
              />
            </PaddedContainer>
          )}
        </>
      )}
    </FullScreenPopup>
  );
}
