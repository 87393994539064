import React from "react";
import UserFiltersRow from "../../InputPopup/UserFiltersRow/UserFiltersRow";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences.json";
import {
  areaIsWholeStore,
  defaultFilters,
  getAreaFilter,
} from "../../InputPopup/UserFiltersRow/filterChoices";
import { Trans } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useTestBuys as useTestBuysFS } from "../CommonComponents/Repositories/testbuy.repository";
import { useTestbuys as useTestBuysPG } from "../CommonComponents/Repositories/useTestbuyService";
import { usePeriodTimestamps } from "../../InputPopup/UserFiltersRow/usePeriodTimestamps";
import { TitleHeader } from "../CommonComponents/TitleHeader/TitleHeader";
import { useFixaUID, useUID } from "../../../core/auth/useLoggedInUser";
import { TestBuyList } from "./TestBuyList";
import { PositionedLoaderIcon } from "@coworker/reusable/Loader";
import { HfbRanks } from "./Analytics/Ranking/HfbRanks";
import EmptyPage from "../../EmptyStateScreen";
import { COMPLETED_STATUS } from "../constants";
import closeReasonTypeOptions from "@coworker/functions/src/testbuy/closeReasonTypes.json";
import {
  callInternal,
  TESTBUY_CLOSE,
} from "../../../hooks/API/useCallInternal";
import trackerHelper from "../../../helpers/tracker";
import Confirmation from "../../InputPopup/ConfirmationPrompt";
import { Button, useToastNotification } from "@coworker/components";
import { WARNING_TOAST } from "@coworker/components/src/components/ToastNotification";
import { postToTestbuyService } from "../../../hooks/API/testbuy.service";
import { TestBuyId } from "@coworker/types";
import { ActionBar } from "@coworker/apprestructured/src/layout/components/ActionBar/ActionBar";
import { MainContentScrollDirectionContext } from "@coworker/apprestructured/src/layout/context/MainContentScrollDirectionContextProvider";
import { SCROLL_UP } from "../../../hooks/useScrollDirection";

export const TestBuyOverview = () => {
  const uid = useUID();
  const fixaUid = useFixaUID();
  const { showToast } = useToastNotification();

  const [filters] = useUserPreference(
    profilePreferences.TESTBUY_PREFIX + profilePreferences.USER_FILTERS,
    defaultFilters
  );
  const { push } = useWorkspacesAction();

  const [sinceTs, untilTs] = usePeriodTimestamps(
    filters,
    profilePreferences.TESTBUY_PREFIX
  );
  const [divisionId] = React.useState("");
  // Send in undefined if the whole store is selected, to not filter by area
  const areaFilter = getAreaFilter(filters);
  const area = !areaIsWholeStore(filters) ? areaFilter : undefined;
  // @ts-ignore
  const avoidFirebase = window.callTestbuyService;
  const useTestBuys = avoidFirebase ? useTestBuysPG : useTestBuysFS;
  const { loading, testbuys } = useTestBuys(
    area,
    sinceTs,
    untilTs,
    divisionId,
    fixaUid
  );
  const { testbuys: allTestbuys } = useTestBuys(
    undefined,
    sinceTs,
    untilTs,
    divisionId,
    fixaUid
  );

  const [isWholeStoreActive, setIsWholeStoreActive] =
    React.useState<boolean>(false);
  const [totalTestbuysDone, setTotalTestbuysDone] = React.useState<number>();
  // const [closedTestbuy, setClosedTestbuy] = React.useState<boolean>();
  const [popup, setPopup] = React.useState<boolean>(false);

  const { scrollDirection } = React.useContext(
    MainContentScrollDirectionContext
  );

  React.useEffect(() => {
    if (!area) {
      setIsWholeStoreActive(true);
    } else {
      setIsWholeStoreActive(false);
    }
  }, [area, isWholeStoreActive]);

  React.useEffect(() => {
    if (testbuys && filters) {
      const totalCompletedTestbuys = testbuys.filter(
        // Only getting data from 1st of September 2022
        (tb) =>
          (tb.finishedAtTs ?? 0) >= 1661990400000 &&
          tb.status === COMPLETED_STATUS
      );

      setTotalTestbuysDone(totalCompletedTestbuys.length);
    }
  }, [testbuys, filters]);

  if (loading) return <PositionedLoaderIcon />;

  const showOnlyClosedTestBuys = !totalTestbuysDone && !!testbuys?.length;

  const testBuyInProgress = testbuys?.find(
    (tb) =>
      (tb.createdBy === uid || tb.createdBy === fixaUid) &&
      tb.status === "IN_PROGRESS"
  );
  const closeTestbuyPG = async () => {
    await postToTestbuyService(
      "testbuy/close_testbuy",
      {
        testbuyId: testBuyInProgress?.id,
        reasonType: closeReasonTypeOptions.NEW_TESTBUY_CREATED,
      },
      (data: TestBuyId[]) => {
        console.log("Data", data);

        if (data.length > 0) {
          trackerHelper.trackCreateAdditionalTestBuy(
            "CREATE_NEW",
            testBuyInProgress?.id
          );
          push("/testbuy/new");
        } else {
          showToast(
            <Trans>searchErrorString</Trans>,
            undefined,
            undefined,
            5,
            WARNING_TOAST
          );
        }
      }
    );
  };

  const closeTestbuyFS = async () => {
    const { data: closedTestbuy } = await callInternal(TESTBUY_CLOSE, {
      testbuyId: testBuyInProgress?.id,
      reasonType: closeReasonTypeOptions.NEW_TESTBUY_CREATED,
    });
    trackerHelper.trackCreateAdditionalTestBuy(
      "CREATE_NEW",
      testBuyInProgress?.id
    );
    if (!closedTestbuy) {
      showToast(
        <Trans>searchErrorString</Trans>,
        undefined,
        undefined,
        5,
        WARNING_TOAST
      );
    } else {
      push("/testbuy/new");
    }
  };

  return (
    //This div exist due to render issues on RDT
    <div>
      <div>
        <TitleHeader title={<Trans>testBuysString</Trans>} />
        <UserFiltersRow
          filters={filters.filter((filter) => filter !== "")}
          settingsPrefix={profilePreferences.TESTBUY_PREFIX}
        />
        {!!totalTestbuysDone && (
          <>
            <HfbRanks
              testbuys={testbuys ?? []}
              allTestbuys={allTestbuys ?? []}
              filters={filters}
              isWholeStoreActive={isWholeStoreActive}
              totalTestbuysDone={totalTestbuysDone}
            />
            <TestBuyList testbuys={testbuys ?? []} />
          </>
        )}
        {!totalTestbuysDone && (
          <EmptyPage
            titleKey="noTestbuysYetString"
            subtitleKey="tapOnTheButtonToStartTestbuyString"
          />
        )}
        {showOnlyClosedTestBuys && <TestBuyList testbuys={testbuys} />}
        <ActionBar hidden={scrollDirection === SCROLL_UP}>
          {
            <Button
              data-testid="button-startNewTestBuy"
              primary
              dark
              text={<Trans>createTestbuyString</Trans>}
              onClick={() => {
                if (testBuyInProgress) {
                  setPopup(!popup);
                } else {
                  push("/testbuy/new");
                }
              }}
            />
          }
        </ActionBar>
      </div>
      {popup && (
        <Confirmation
          onSubmit={avoidFirebase ? closeTestbuyPG : closeTestbuyFS}
          onCancel={() => {
            trackerHelper.trackCreateAdditionalTestBuy(
              "SEE_ONGOING",
              testBuyInProgress?.id
            );
            push(`/testbuy/${testBuyInProgress?.id}`);
          }}
          onClose={() => {
            setPopup(false);
            trackerHelper.trackCreateAdditionalTestBuy(
              "CLICKED_OUTSIDE",
              testBuyInProgress?.id
            );
          }}
          swapButtons={false}
          question={<Trans>ongoingTestBuyString</Trans>}
          description={<Trans>seeOngoingOrCloseTestBuyString</Trans>}
          positiveText={<Trans>createNewString</Trans>}
          negativeText={<Trans>seeOngoingString</Trans>}
        />
      )}
    </div>
  );
};
