import { getTokenAsync } from "./getAuthToken";
import packageJson from "../../../package.json";

export const makeHeaders = (token: string) => ({
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Client-Version": packageJson.version,
});

export async function callToFetch(method: string, url: string, headers = {}) {
  const token = await getTokenAsync(true);
  const params = {
    method,
    headers: { ...makeHeaders(token), ...headers },
  };
  const response = await fetch(url, params);
  if (!response.ok) {
    throw new Error("Fetching from core-service failed.");
  }
  return response.json();
}
