import React, { MouseEvent } from "react";
import SkapaButton from "@ingka/button";
import { useTranslation } from "@coworker/locales";
const buttonStyle = {
  padding: "25px 20px",
};

interface AddCustomLocationCTAButtonProps {
  onClick: (
    event: MouseEvent<HTMLButtonElement | HTMLAnchorElement | Element>
  ) => void;
}

const AddCustomLocationCTAButton: React.FC<AddCustomLocationCTAButtonProps> = ({
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <SkapaButton
      type="secondary"
      key="addCustomLocationButton"
      text={t("createCustomLocationString")}
      small={false}
      fluid={true}
      onClick={onClick}
      iconPosition="trailing"
      style={buttonStyle}
    ></SkapaButton>
  );
};

export default AddCustomLocationCTAButton;
