import React from "react";

import styles from "./main-content.module.css";
import { MainContentScrollDirectionContext } from "../../context/MainContentScrollDirectionContextProvider";
import { useScrollDirection } from "@coworker/app/src/hooks/useScrollDirection";

interface MainContentProps {
  children: React.ReactNode;
}

const MainContent = ({ children }: MainContentProps) => {
  const { setScrollDirection } = React.useContext(
    MainContentScrollDirectionContext
  );

  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [scrollDirection, setScrollTarget] = useScrollDirection();

  React.useEffect(() => {
    if (!scrollContainerRef.current) return;

    setScrollTarget(scrollContainerRef.current);
  }, [scrollContainerRef, setScrollTarget]);

  React.useEffect(() => {
    setScrollDirection(scrollDirection);
  }, [setScrollDirection, scrollDirection]);

  return (
    <div className={styles["main-content"]} ref={scrollContainerRef}>
      {children}
    </div>
  );
};

export default MainContent;
