import React from "react";
import { useTranslation } from "@coworker/locales";
import { Text } from "./Text";
import { getRoomsCountTranslation } from "../tools";
import { PaddedContainer } from "../styles/styles";

interface RoomsCountTextProps {
  roomSettings: number;
}

export function RoomsCountText({ roomSettings }: RoomsCountTextProps) {
  const { t } = useTranslation();

  const settingsOrSetting = t(getRoomsCountTranslation(roomSettings));
  return (
    <PaddedContainer $py="8px" $px="24px">
      <Text text={`${roomSettings} ${settingsOrSetting}`} grey />
    </PaddedContainer>
  );
}
