import React from "react";
import styled from "styled-components";
import posed from "react-pose";
import {
  getCompletedByMeCount,
  resetPrepareToCelebrateWithAnAnimation,
} from "../../helpers/taskFilters";
import WavingHand from "../../assets/images/waving-hand.png";
import { ReactComponent as CheckmarksIcon } from "../../assets/svg/checkmark-icon.svg";
import { firestore } from "@coworker/common/config/firebase";
import { logoutUser } from "../../services/user.service";
import { useTranslation } from "@coworker/locales";
import { useAuth } from "../../core/auth/useAuth";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const TextRow = styled.div`
  position: absolute;
  top: 75%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  & > span {
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: -0.8px;
    color: var(--white);
  }

  & > span:nth-child(2) {
    color: var(--yellow);
  }
`;

const UpperPosedText = posed.span({
  pre: { y: "-10px", opacity: 0 },
  show: { y: 0, opacity: 1 },
  post: { y: "10px", opacity: 0 },
});

const LowerPosedText = posed.span({
  pre: { y: "-10px", opacity: 0, delay: 100 },
  show: { y: 0, opacity: 1, delay: 100 },
  post: { y: "10px", opacity: 0, delay: 100 },
});

const IconContainer = styled.div`
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > svg {
    color: var(--yellow);
    /* transform: scale(2.5); */
  }
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 72px;
  height: 72px;
  animation: 2.9s wave_animation ease-in-out;
  animation-fill-mode: forwards;
  transform-origin: 50% 75%;
  @keyframes wave_animation {
    0% {
      transform: translate(-50%, -50%) scale(0) rotate(-10deg);
    }
    100% {
      transform: translate(-50%, -50%) scale(0) rotate(10deg);
    }
    10%,
    64% {
      transform: translate(-50%, -50%) scale(1) rotate(10deg);
    }
    36%,
    90% {
      transform: translate(-50%, -50%) scale(1) rotate(-10deg);
    }
  }
`;

export function LogoutView() {
  const { t } = useTranslation();
  const completedTasks = getCompletedByMeCount();

  const screens = [
    completedTasks > 0 && {
      icon: CheckmarksIcon,
      upperText: t("completedString"),
      lowerText: t("tasksTodayString", { number: completedTasks }),
    },
    {
      image: WavingHand,
    },
  ].filter((i) => i);

  const [currentScreen, setCurrentScreen] = React.useState(0);
  const [pose, setPose] = React.useState("pre");

  React.useEffect(() => {
    if (currentScreen >= screens.length) {
      return;
    }
    setPose("show");
    let to = setTimeout(next, 2000);
    function next() {
      setPose("post");
      to = setTimeout(() => {
        setPose("pre");
        resetPrepareToCelebrateWithAnAnimation();
        to = setTimeout(() => setCurrentScreen(currentScreen + 1), 500);
      }, 500);
    }
    return () => clearTimeout(to);
  }, [currentScreen, screens.length]);

  const { msalLogout } = useAuth();

  React.useEffect(() => {
    if (currentScreen === screens.length) {
      firestore.waitForPendingWrites().finally(() => {
        logoutUser().then(msalLogout);
      });
      // fallback if the waitForPendingWrites fails to reject.
      setTimeout(() => logoutUser().then(msalLogout), 5000);
    }
  }, [currentScreen, msalLogout, screens.length]);

  if (!screens[currentScreen]) {
    return null;
  }
  const screen = screens[currentScreen];
  const ScreenIcon = screen.icon || (() => null);

  return (
    <Container>
      {screen.image && <Image src={screen.image} />}
      {screen.icon && (
        <IconContainer>
          <ScreenIcon pose={pose} />
        </IconContainer>
      )}
      {(screen.upperText || screen.lowerText) && (
        <TextRow>
          <UpperPosedText pose={pose}>{screen.upperText}</UpperPosedText>
          <LowerPosedText pose={pose}>{screen.lowerText}</LowerPosedText>
        </TextRow>
      )}
    </Container>
  );
}
