import React from "react";
import { Trans } from "@coworker/locales";
import { Widget } from "@coworker/components/src/components/Widget";
import { AddTitle } from "@coworker/components";
import { useInputPopup } from "../../InputPopup";

export default function TaskFormCustomTitle({ value, onChange }) {
  const { getInput } = useInputPopup();
  const getTitle = async () => {
    const data = await getInput("title", {
      initialValue: value,
      title: "",
    });
    if (data) {
      onChange(data);
    }
  };

  return (
    <Widget onClick={getTitle} data-testid="customTitleWidget">
      <AddTitle
        value={value}
        deactivated={!value}
        titleString={<Trans>titleAddString</Trans>}
        optional={<Trans>optionalString</Trans>}
      />
    </Widget>
  );
}
