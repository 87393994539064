import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  ${({ last }) => (last ? "border-bottom: 8px solid var(--grey100);" : "")}
  background: ${(props) =>
    props.idx % 2 === 1 ? "var(--white)" : "var(--grey100)"};
  padding: 50px 28px;
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;
const StepContainer = styled.div`
  position: relative;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
  margin: 0 29px 29px;
  @media (min-width: 768px) {
    width: 20%;
    display-flex;
    justify-content: center;
    align-items: center;
  }
`;
const Image = styled.img`
  width: 100%;
  border-radius: 10px;
`;
const Step = styled.div`
  color: var(--black);
  font-size: 14px;
  margin-bottom: 32px;
`;
const Num = styled.span`
  position: absolute;
  top: 0;
  left: -20px;
`;
// const Video = styled.video`
//   width: calc(100% - 28px - 28px);
//   max-height: 150px;
// `;

export const ImageStep = ({ num, txt, media }) => (
  <StepContainer>
    <Step>
      <Num>
        <b>
          {num}
          {num !== " " && "."}
        </b>
      </Num>
      {txt}
    </Step>
    <Image src={media} />
  </StepContainer>
);

// export const VideoStep = ({ num, txt, media }) => (
//   <React.Fragment>
//     <Video width="320" height="240" controls>
//       <source src={media} type="video/mp4" />
//       Your browser does not support the video tag.
//     </Video>
//     <Step>
//       <b>{num}. </b> {txt}
//     </Step>
//   </React.Fragment>
// );
