import React from "react";
import { Link } from "react-router-dom";
import { LoginButton } from "@coworker/components";
import { Trans } from "@coworker/locales";
import { useOnline } from "../../core/hooks/useOnline";
import styled from "styled-components";
import { useAuth } from "../../core/auth/useAuth";
import { PRIVACY_PATH } from "../../paths";

const StyledLink = styled(Link)`
  display: block;
  margin-top: 32px;
  color: var(--white);
  font-size: 14px;
  width: 100%;
  text-align: center;
`;

const LoginError = styled.div`
  min-height: 24px;
  font-size: 12px;
  line-height: 2;
  color: var(--yellow);
  text-align: center;
  margin-bottom: 20px;
`;

export const LoginComponent = () => {
  const { isOnline } = useOnline();
  const { isAuthInProgress, authError, loginRedirect, cleanUpSignInStatus } =
    useAuth();
  return (
    <>
      {!!authError && <LoginError>{`${authError}`}</LoginError>}
      <LoginButton
        type="submit"
        disabled={isAuthInProgress || !isOnline}
        onClick={() => {
          cleanUpSignInStatus().then(() => {
            loginRedirect();
          });
        }}
      >
        {isAuthInProgress ? (
          <Trans>loggingIn</Trans>
        ) : isOnline ? (
          <Trans>login</Trans>
        ) : (
          "Go online to login"
        )}
      </LoginButton>
      <StyledLink to={PRIVACY_PATH}>
        <Trans>privacyString</Trans>
      </StyledLink>
    </>
  );
};
