import React, { Reducer, createContext } from "react";
import { ModalType } from "./modalType";

export type ModalAction = {
  type: ModalType;
  payload?: any;
};

type ModalInitialState = {
  isMainNavigationOpen: boolean;
  isProfileModalOpen: boolean;
  isTaskDetailSheetOpen: boolean;
  props: any;
};
interface ModalContextProps {
  modalState: ModalInitialState;
  toggleModal: React.Dispatch<ModalAction>;
}

const initialModalState: ModalInitialState = {
  isMainNavigationOpen: false,
  isProfileModalOpen: false,
  isTaskDetailSheetOpen: false,
  props: {},
};

const modalReducer: Reducer<ModalInitialState, ModalAction> = (
  state,
  action
) => {
  switch (action.type) {
    case ModalType.MainNavigation:
      return {
        ...state,
        isMainNavigationOpen: !state.isMainNavigationOpen,
      };
    case ModalType.Profile:
      return {
        ...state,
        isProfileModalOpen: !state.isProfileModalOpen,
      };
    case ModalType.TaskDetail:
      return {
        ...state,
        isTaskDetailSheetOpen: !state.isTaskDetailSheetOpen,
        props: Object.assign({}, state.props, {
          taskDetail: action.payload,
        }),
      };
    default:
      return state;
  }
};

export const ModalContext = createContext<ModalContextProps>({
  modalState: initialModalState,
  toggleModal: () => {},
});

const ModalContextProvider: React.FC = ({ children }) => {
  const [modalState, toggleModal] = React.useReducer(
    modalReducer,
    initialModalState
  );

  return (
    <ModalContext.Provider value={{ modalState, toggleModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
