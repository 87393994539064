import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { Button } from "@coworker/components";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { ReactComponent as Confetti } from "../../../assets/svg/confetti.svg";
import { Container, ImageStep } from "./_common";
import { useIntersect } from "@coworker/components";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import trackerHelper from "../../../helpers/tracker";
import { widgets, allSteps, mediaTypeEnums } from "./instructions_data";
import { Spacer } from "../../Layout/styles";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";

const Ingress = styled.div`
  border-top: 2px solid var(--grey100);
  padding: 20px 29px;
  font-size: 14px;
  line-height: 21px;
  color: var(--grey718);
  @media (min-width: 768px) {
    text-align: center;
  }
`;
const BottomWrapper = styled.div`
  height: 180px;
  margin: 0 10px;
    @media (min-width: 768px) {
    width: 20%;
    display-flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
`;
const CompletedContainer = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Text = styled.div`
  padding-left: 5px;
  text-align: center;
  }
`;

const SeparatorTitle = styled.div`
  padding: 24px 15px;
  background: var(--grey200);
  color: var(--grey900);
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;

  @media (min-width: 768px) {
    text-align: center;
  }
`;

const stepComponents = {
  [mediaTypeEnums.image]: ImageStep,
  // [mediaTypeEnums.video]: VideoStep,
};

function Step({ idx, step, last, extra }) {
  const StepComponent = stepComponents[step.type];
  return (
    <React.Fragment key={idx}>
      {step.separator && <SeparatorTitle>{step.separator}</SeparatorTitle>}
      <Container idx={idx} last={last}>
        <StepComponent
          idx={idx + 1}
          num={step.num || 1 + idx}
          txt={step.text}
          media={step.media}
          key={idx}
        />

        {extra}
      </Container>
    </React.Fragment>
  );
}

function Completed({ title, type }) {
  const [intersectRef, entry] = useIntersect({
    threshold: 0,
  });
  const [completed, setCompleted] = useUserPreference(
    profilePreferences.INSTRUCTIONS_COMPLETED,
    []
  );

  React.useEffect(() => {
    if (entry.isIntersecting) {
      setCompleted([...new Set([...completed, type])]);
      trackerHelper.trackInstructionsComplete(type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry.isIntersecting]);

  return (
    <CompletedContainer ref={intersectRef}>
      <Confetti />
      <Spacer height={20} />
      <Text>
        <Trans>learnedAboutString</Trans> <b>{title}</b>
      </Text>
    </CompletedContainer>
  );
}

const StyledButton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Instruction = ({ type }) => {
  const { pop, push } = useWorkspacesAction();
  const instruction = widgets.find((widget) => widget.type === type);
  const stepIndex = widgets.findIndex((item) => item.type === type);
  const nextStep = widgets[stepIndex + 1];

  const instructionTitle = instruction?.title;
  const renderedSteps = React.useMemo(() => {
    const completed = <Completed title={instructionTitle} type={type} />;
    const steps = allSteps[type];
    return steps?.map((step, idx) => {
      const last = steps?.length - 1 === idx;
      if (!step) return null;
      return (
        <Step
          key={idx}
          idx={idx}
          step={step}
          last={last}
          extra={last && completed}
        />
      );
    });
  }, [instructionTitle, type]);

  if (!instruction) return null;
  return (
    <FullScreenPopup
      noPadding
      appBarConfig={{
        title: instruction.title,
        actions: [],
      }}
    >
      <>
        <Ingress>{instruction.ingress || instruction.content}</Ingress>
        {renderedSteps}
        {!!nextStep && (
          <BottomWrapper>
            <Text>
              <Spacer height={20} />
              <b>
                <Trans>nextTopicString</Trans>
              </b>{" "}
              {nextStep.title}
            </Text>
            <StyledButton
              customMargin
              type="submit"
              text={<Trans>next</Trans>}
              primary
              dark
              fullWidth
              data-testid="instruction_submit_button"
              onClick={() => {
                const element = document.querySelector(
                  "div[id=scrollableContainer]"
                );
                if (element) element.scrollTo(0, 0);
                pop();
                push(`/tutorials/${nextStep.type}`);
                trackerHelper.trackTutorialsNextButtonClick(nextStep.type);
              }}
            />
          </BottomWrapper>
        )}
      </>
    </FullScreenPopup>
  );
};

export default Instruction;
