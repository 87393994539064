import React from "react";
import VotesGraphCard from "../../../components/MFAQ/VotesGraphCard";
import { useWorkspacesState } from "../../../hooks/useWorkspaces";
import {
  areaIsWholeStore,
  getAreaFilter,
  defaultFilters,
} from "../../InputPopup/UserFiltersRow/filterChoices";
import { useFeatureFlagHooks } from "../../MFAQ/useMFAQs.featureToggle";
import { usePeriodTimestamps } from "../../InputPopup/UserFiltersRow/usePeriodTimestamps";

export default function TaskFormMfaqGraphcard() {
  const { useMFAQ } = useFeatureFlagHooks();
  const { navigationState } = useWorkspacesState();
  const questionId = navigationState?.question?.question_id;
  const filters =
    navigationState?.question?.question_current_filters || defaultFilters;
  const [startDate, endDate] = usePeriodTimestamps(filters);
  const question = useMFAQ(questionId, startDate, endDate);

  // We don't need to send this as number of votes not changing in this view.
  const votesFudgeFactorsPerArea = 0;
  const voteCountForArea =
    question?.upvotes_by_area?.find(
      (votes) => votes.area === getAreaFilter(filters)
    )?.votes || 0;

  const isWholeStore = areaIsWholeStore(filters);

  return (
    <VotesGraphCard
      question_id={questionId}
      totalUpvotes={voteCountForArea}
      votesFudgeFactor={votesFudgeFactorsPerArea}
      filters={filters}
      isWholeStore={isWholeStore}
      displayTasksOnGraph={true}
    />
  );
}
