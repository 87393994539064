import React from "react";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";

import NotificationsIcon from "@ingka/ssr-icon/paths/bell";

import styles from "./notifications-link.module.css";
import SSRIcon from "@ingka/ssr-icon";
import trackerHelper from "@coworker/app/src/helpers/tracker";

import { useFetchNotifications } from "@coworker/app/src/hooks/notificationHooks/useFetchNotifications";

const NotificationsLink = () => {
  const { data: events } = useFetchNotifications();

  const number =
    events && events.filter((a: any) => a.is_read === false).length;
  const { push } = useWorkspacesAction();

  const handleNotificationNavigation = () => {
    push(`/tasks/activity/notifications`);
    trackerHelper.trackNotificationButtonClick();
  };
  return (
    <div
      className={styles["notifications-link"]}
      onClick={handleNotificationNavigation}
    >
      <div className={styles["bell-wrapper"]}>
        <SSRIcon paths={NotificationsIcon} />
        {number > 0 && <div className={styles["counter"]}>{number}</div>}
      </div>
    </div>
  );
};

export default NotificationsLink;
