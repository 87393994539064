import React from "react";
import { Widget } from "@coworker/components/src/components/Widget";
import AttachPhoto from "../../AttachPhoto";
import { isChineseEnvironment } from "@coworker/reusable";

export default function TaskFormPhotos({
  formType,
  value = [],
  onChange,
  taskPath,
  last,
  conf: { icon, lblColor, overridePadding },
  additionalSubTitle = <></>,
}) {
  const isChinesePQR = isChineseEnvironment() && formType === "product_quality";
  return (
    <Widget data-testid="photoWidget">
      <AttachPhoto
        contextPath={`${taskPath}images`}
        onImageListChange={onChange}
        images={value}
        folder="tasks"
        removeBottomBorder={last}
        icon={icon}
        lblColor={lblColor}
        isObligatory={isChinesePQR}
        overridePadding={overridePadding}
        additionalSubTitle={
          isChinesePQR && (value?.length ?? 0) === 0 ? (
            additionalSubTitle
          ) : (
            <></>
          )
        }
      />
    </Widget>
  );
}
