import { defaultTimezone } from "../../../constants/clientTime";
import { useTranslation } from "@coworker/locales";
import moment from "moment";
import "moment-timezone";

export function useFetchLatestShoppingToolsSessionTime(
  timestamp: number
): string {
  const { t } = useTranslation();
  const utcDate = moment.utc(timestamp);

  const timeZoneDate = moment.tz(utcDate, defaultTimezone);

  const currentTime = moment();

  const isToday = timeZoneDate.isSame(currentTime, "day");

  const currentTimeMoment = moment(currentTime);
  const timeZoneDateMoment = moment(timeZoneDate);

  const hoursDifference = currentTimeMoment.diff(timeZoneDateMoment, "hours");
  const daysDifference = currentTimeMoment.diff(timeZoneDateMoment, "days");
  const weeksDifference = currentTimeMoment.diff(timeZoneDateMoment, "weeks");
  const monthsDifference = currentTimeMoment.diff(timeZoneDateMoment, "months");
  const yearsDifference = currentTimeMoment.diff(timeZoneDateMoment, "years");

  if (timestamp === 0) {
    return t("notStartedString");
  }

  if (hoursDifference < 24) {
    if (isToday) {
      return t("ongoingString");
    } else {
      return `${hoursDifference} ${
        hoursDifference === 1 ? t("hourString") : t("hoursString")
      } ${t("agoString")}`;
    }
  } else if (daysDifference < 7) {
    return `${daysDifference} ${
      daysDifference === 1 ? t("dayString") : t("daysString")
    } ${t("agoString")}`;
  } else if (weeksDifference <= 4 && monthsDifference === 0) {
    return `${weeksDifference} ${
      weeksDifference === 1 ? t("weekString") : t("weekString_plural")
    } ${t("agoString")}`;
  } else if (monthsDifference < 12) {
    return `${monthsDifference} ${
      monthsDifference === 1 ? t("monthString") : t("monthsString")
    } ${t("agoString")}`;
  } else {
    return `${yearsDifference} ${
      yearsDifference === 1 ? t("yearString") : t("yearsString")
    } ${t("agoString")}`;
  }
}
