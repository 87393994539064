import React from "react";
import styled, { css } from "styled-components";
import { isFinishedState } from "../constants/taskState";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import {
  shouldDisplayCompleteTask,
  shouldDisplayPickupTask,
} from "./TaskDetail/actions/helpers";
import { useWorkspacesAction } from "../hooks/useWorkspaces";
import { useOnline } from "../core/hooks/useOnline";
import { usePickUpTask } from "../hooks/TaskActions/usePickUpTask";
import { useCompleteTask } from "../hooks/TaskActions/useCompleteTask";
import { useMultiSelectForTab } from "../hooks/useMultiSelect";
import trackerHelper from "../helpers/tracker";
import { Link } from "./Link";
import MultiSelectCheckbox from "./MultiSelectCheckbox";
import { TaskCardWithData } from "./TaskCardWithData";
import {
  CompleteButton,
  CompleteWithChangesButton,
  MoreButton,
  PickupButton,
} from "./TaskCardButtons";
import SlidingWrapper from "./SlidingWrapper";

const StyledWrapper = styled.div`
  display: flex;
  align-items: stretch;
  height: 100%;
  overflow: hidden;
`;

const StyledLink = styled(Link)`
  width: 100%;
  ${({ isSelected }) =>
    css`
      background: var(--${isSelected ? "grey100" : "white"});
    `}
  ${(props) => props.closedstate && "background: var(--grey50);"}
  border-bottom: 1px solid var(--grey150);
  box-sizing: border-box;
  display: block;
  ${({ isDraggable }) =>
    isDraggable &&
    `
    position: absolute;
    left: 0;
    height: 100%;
  `};
`;

const TaskCardLink = ({
  task,
  uid,
  team_id,
  hasNewNote,
  style,
  isDraggable,
  addonFilterOption,
  testId,
  tabType,
  onClickTrack,
}) => {
  const { setNavigationState } = useWorkspacesAction();
  const wrapperRef = React.useRef(null);

  // Multiselect
  const { checkIfSelected, toggle, setShowingMultiSelect, showingMultiSelect } =
    useMultiSelectForTab(tabType);
  const isSelected = checkIfSelected?.(task.id);

  React.useEffect(() => {
    // TODO: This is the smallest possible fix to avoid iOS erroring with "Over 100 replaceState within 30 seconds". A nicer fix is available, but more involved, so as this should be 100% safe let's deliver this one first, then have the other included in a later more involved testing round.
    setTimeout(() => {
      setNavigationState({
        navPropsIsHidden: showingMultiSelect,
        layoutPropsFullHeight: showingMultiSelect,
        appTheme: showingMultiSelect ? "transparent" : "grey",
      });
    }, 25);
    // Ignoring setNavigationState to avoid loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showingMultiSelect]);

  React.useEffect(() => {
    const { current } = wrapperRef; // Extract the current element so that both attach AND remove will operate on the same element.
    if (!current || !toggle || !setShowingMultiSelect) return;
    const handler = (event) => {
      event.preventDefault();
      if (!toggle || !setShowingMultiSelect) return;
      toggle(task.id);
      if (!showingMultiSelect) {
        setShowingMultiSelect(true);
        trackerHelper.multipleSelect.logMultiselectLongpress();
      }
    };
    current.addEventListener("contextmenu", handler, true);
    return () => current.removeEventListener("contextmenu", handler);
  }, [toggle, task.id, showingMultiSelect, setShowingMultiSelect]);

  const taskCard = (
    <TaskCardWithData
      addonFilterOption={addonFilterOption}
      padLeft={showingMultiSelect}
      onClickTrack={onClickTrack}
      task={task}
      uid={uid}
      tabType={tabType}
      hasNewNote={hasNewNote}
    />
  );

  const { isOnline } = useOnline();
  const { push } = useWorkspacesAction();

  const { call: completeTask } = useCompleteTask(task, task.id, {
    undo: true,
    afterUndo: () => push(`/task/${task.id}`),
  });
  const { call: pickUpTask } = usePickUpTask(task, task.id);

  /* Drag Implementation Start */
  const isPickupFlow = React.useMemo(
    () => shouldDisplayPickupTask(task, { team_id }),
    [task, team_id]
  );
  const isCompleteFlow = React.useMemo(
    () => shouldDisplayCompleteTask(task, { uid }, false),
    [task, uid]
  );
  const isCompleteWithChangesFlow =
    task.task_type === taskTypeOptions.ADDON && isCompleteFlow;

  const leftButtons = React.useMemo(() => {
    if (isPickupFlow) {
      return [<PickupButton onClick={pickUpTask} />];
    }

    if (isCompleteFlow) {
      let elements = [<CompleteButton onClick={completeTask} />];

      if (isCompleteWithChangesFlow) {
        elements.push(
          <CompleteWithChangesButton
            onClick={() => push(`/task/${task.id}/changes`, { picking: false })}
          />
        );
      }

      return elements;
    }
  }, [
    completeTask,
    isCompleteFlow,
    isCompleteWithChangesFlow,
    isPickupFlow,
    pickUpTask,
    push,
    task.id,
  ]);

  const rightButtons = React.useMemo(() => {
    return [<MoreButton task={task} />];
  }, [task]);

  if (showingMultiSelect) {
    const toggleThis = () => toggle(task.id);
    return (
      <StyledWrapper
        data-type={task.task_type}
        data-testid={testId}
        data-id={task.id}
        style={{ ...style }}
        ref={wrapperRef}
      >
        <MultiSelectCheckbox
          value={task.id}
          checked={isSelected}
          onClick={toggleThis}
        />
        <StyledLink
          isDraggable={isDraggable}
          key={task.id}
          closedstate={isFinishedState(task.state) ? "true" : null}
          isSelected={isSelected}
          onClick={toggleThis}
        >
          {taskCard}
        </StyledLink>
      </StyledWrapper>
    );
  }

  const swipeBinding = task.task_type !== taskTypeOptions.PRODUCT_QUALITY;

  return (
    <SlidingWrapper
      style={style}
      isDraggable={isOnline && isDraggable}
      swipeBinding={swipeBinding}
      dataType={task.task_type}
      dataTestId={testId}
      dataId={task.id}
      animatedLinkRedirect={`/task/${task.id}`}
      animatedLinkClosed={!!isFinishedState(task.state)}
      leftButtons={leftButtons}
      leftButtonExpandable={true}
      rightButtons={rightButtons}
      wrapperRef={wrapperRef}
    >
      {taskCard}
    </SlidingWrapper>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    // Check if it is a different task
    prevProps.task.id === nextProps.task.id &&
    // Check if filters have changed
    prevProps.addonFilterOption?.toString() ===
      nextProps.addonFilterOption?.toString()
  );
}

export default React.memo(TaskCardLink, arePropsEqual);
