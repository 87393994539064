import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { useTranslation } from "@coworker/locales";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import taskState from "@coworker/functions/src/enums/taskState.json";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import useFormatter from "../../hooks/useFormatter";
import { RadioButton } from "@coworker/components";
import { MFAQHeader } from "../MFAQ/MFAQHeader";
import { useFeatureFlagHooks } from "../MFAQ/useMFAQs.featureToggle";
import { defaultFilters } from "./UserFiltersRow/filterChoices";
import { WideButton } from "@coworker/components";
import {
  QuestionWrapper,
  TextWrapper,
  QuestionText,
  Subtitle,
} from "../MFAQ/Question";
import { Icon } from "@coworker/components";
import { usePeriodTimestamps } from "../InputPopup/UserFiltersRow/usePeriodTimestamps";
import tracker from "../../helpers/tracker";
import { isFinishedState } from "../../constants/taskState";
import { useStoreId } from "../../core/auth/useLoggedInUser";

const { MFAQ_PREFIX, USER_FILTERS } = profilePreferences;

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--white);
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const RadioButtonWrapper = styled.div`
  padding-left: 20px;
  width: 71px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  min-width: 16px;
`;

const QuestionTextTitle = styled(QuestionText)`
  font-weight: bold;
`;

const MergeButton = styled(WideButton)`
  margin-top: 60px;
`;

const MessageWrapper = styled.div`
  margin: 40px;
  display: inline-block;
`;

const MessageText = styled.span`
  margin-left: 10px;
  font-size: 14px;
  color: var(--error);
`;

export function MFAQmergeQuestion(props) {
  const { searchMFAQ, useMFAQ } = useFeatureFlagHooks();
  const store_id = useStoreId();
  const [filters] = useUserPreference(
    MFAQ_PREFIX + USER_FILTERS,
    defaultFilters
  );
  const { t } = useTranslation();
  const { toTimeAgo, formatNumberShort } = useFormatter();
  const [startDate, endDate] = usePeriodTimestamps(filters);
  const firstQuestionData = useMFAQ(props.questionId, startDate, endDate);
  const [foundQuestions, setFoundQuestions] = React.useState([]);
  const [selectedQuestionId, setSelectedQuestionId] = React.useState(null);

  // Remove the base question from search results
  const filteredQuestions = React.useMemo(
    () =>
      foundQuestions.filter(
        (question) => question.question_id !== props.questionId
      ),
    [foundQuestions, props.questionId]
  );

  const selectedQuestionExist = React.useMemo(
    () => filteredQuestions.some((q) => q.question_id === selectedQuestionId),
    [filteredQuestions, selectedQuestionId]
  );

  const selectedQuestionOngoingTasks = React.useMemo(() => {
    const list = [];
    for (const q of filteredQuestions) {
      if (q.question_id !== selectedQuestionId) continue;
      for (const task of q?.tasks || []) {
        if (!isFinishedState(task?.state)) list.push(task);
      }
    }
    return list;
  }, [filteredQuestions, selectedQuestionId]);

  // Currently, merge not allowed if both questions have ongoing tasks in any area.
  const questionHasTask =
    !!selectedQuestionOngoingTasks.length &&
    firstQuestionData?.tasks?.some(
      (task) => ![taskState.COMPLETED, taskState.CLOSED].includes(task?.state)
    );

  React.useEffect(() => {
    // Unselect previously selected question
    if (selectedQuestionId && !selectedQuestionExist) {
      setSelectedQuestionId(null);
    }
  }, [selectedQuestionId, selectedQuestionExist]);

  // We want to track if both questions have tasks.
  if (questionHasTask) {
    tracker.MFAQ.trackMergeNotPossible(props.questionId, selectedQuestionId);
  }

  return (
    <Container>
      <MFAQHeader
        onQuery={async (text) => {
          const found = await searchMFAQ(text, null, filters[1], store_id);
          setFoundQuestions(found);
        }}
        onCancelSearch={() => {
          setFoundQuestions([]);
          setSelectedQuestionId(null);
        }}
        mergeQuestion={true}
        cancelMergeQuestion={props.onClose}
      />
      {filteredQuestions.map((question) => {
        const date = toTimeAgo(question?.last_asked_at);

        return (
          <QuestionWrapper key={question.question_id}>
            <RadioButtonWrapper>
              <RadioButton
                readOnly
                checked={selectedQuestionId === question.question_id}
                data-testid={question.question_id + "Button"}
                onClick={() => {
                  setSelectedQuestionId(question.question_id);
                }}
              />
              <strong>{formatNumberShort(question.upvotes)}</strong>
            </RadioButtonWrapper>
            <TextWrapper>
              <QuestionTextTitle>{question.question_text}</QuestionTextTitle>
              <Subtitle>{t("lastAskedAtString", { date })}</Subtitle>
            </TextWrapper>
          </QuestionWrapper>
        );
      })}
      {questionHasTask && (
        <MessageWrapper>
          <Icon
            name="warning_triangle"
            size="16"
            family="icons"
            color="error"
          />
          <MessageText>
            <Trans>mergeNotPossibleMessageString</Trans>
          </MessageText>
        </MessageWrapper>
      )}
      <MergeButton
        data-testid="MFAQmergeQuestion"
        primary
        dark
        disabled={!selectedQuestionId || questionHasTask}
        text={t("mfaqMergeString")}
        onClick={(event) => {
          event.preventDefault();
          tracker.MFAQ.trackMergeButton(props.questionId, selectedQuestionId);
          props.onSubmit(selectedQuestionId);
        }}
      />
    </Container>
  );
}
