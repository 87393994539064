import React from "react";
import { TaskTodos } from "../types/taskCreation";
import { TaskPillRow } from "../Molecules/TaskPillRow";
import { CheckmarkBox } from "../Atoms/Icons/CheckmarkBox";
import { useTranslation } from "@coworker/locales";
import { TaskCard } from "../Molecules/TaskCard";
import { ShoppingToolsQuantityStepper } from "../../ShoppingTools/Components/ShoppingToolsQuantityStepper";
import { Text } from "../Atoms/Text";

interface TodoListCardProps {
  maxPieces: number;
  todosChanged: (taskTodos: TaskTodos) => void;
}

export function TodoListCard({ maxPieces, todosChanged }: TodoListCardProps) {
  const { t } = useTranslation();
  const [todos, setTodos] = React.useState<TaskTodos>([
    { index: 0, is_new_article: true, is_ptag: false },
  ]);
  const [quantity, setQuantity] = React.useState(1);

  function handleQuantityStepper(delta: number, index: number) {
    let tempTodos = [...todos];
    if (delta === 1) {
      tempTodos.push({ index, is_new_article: true, is_ptag: false });
    } else if (delta === -1) {
      tempTodos = tempTodos.slice(0, -1);
    }
    setQuantity(quantity + delta);
    todosChanged(tempTodos);

    setTodos(tempTodos);
  }

  function updateTodo(
    is_new_article: boolean,
    is_ptag: boolean,
    index: number
  ) {
    const tempTodos = [...todos];
    const todo = tempTodos[index];
    if (todo) {
      todo.is_new_article = is_new_article;
      todo.is_ptag = is_ptag;
      setTodos(tempTodos);
      todosChanged(tempTodos);
    }
  }

  return (
    <>
      <TaskCard
        leftIcon={<CheckmarkBox />}
        headLine={<Text text={t("todoBasicString")} bold />}
        subText=""
      />
      <TaskCard
        leftIcon={<></>}
        headLine={<Text text={t("insightPiecesLongString")} bold />}
        subText={<Text text={`${t("totalString")}: ${maxPieces}`} />}
        rightIcon={
          <ShoppingToolsQuantityStepper
            value={quantity}
            small
            minVal={1}
            maxVal={maxPieces}
            ariaDescribedById="unique-dom-id"
            ariaDescribedBy="Type in a number or use arrow up or arrow down to change the quantity"
            onIncrease={() => handleQuantityStepper(1, todos.length)}
            onDecrease={() => handleQuantityStepper(-1, 0)}
          />
        }
      />
      {todos.map((todo, index) => (
        <TaskPillRow
          key={index}
          isNewArticleSelected={todo.is_new_article}
          isPTagSelected={todo.is_ptag}
          onNewArticleClick={() => {
            updateTodo(true, false, index);
          }}
          onPTagClick={() => {
            updateTodo(false, true, index);
          }}
        />
      ))}
    </>
  );
}
