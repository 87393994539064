import { Pill } from "@coworker/components";
import { Trans } from "@coworker/locales";
import * as React from "react";
import styled from "styled-components";
import { describeAreaFilter } from "../../InputPopup/UserFiltersRow/filterChoices";
import { ARTICLE_HASH } from "../constants";

const Container = styled.div`
  display: flex;
  padding: 0.8rem;
  border-bottom: 1px solid var(--grey100);
  font-weight: bold;
  color: black;
`;

export interface StaticFilterRowProps {
  numberOfArticles?: number | undefined;
  area: string | undefined;
  typeOfArticles: string | undefined;
}

export default function StaticFilterRow({
  numberOfArticles,
  area,
  typeOfArticles,
}: StaticFilterRowProps) {
  return (
    //This div exist due to render issues on RDT
    <div>
      <Container data-testid="static-filter-row">
        <Pill
          disabled
          text={
            (typeOfArticles === ARTICLE_HASH.ACESANDKING?.type && (
              <Trans>fourAcesAndKingString</Trans>
            )) ||
            (typeOfArticles === ARTICLE_HASH.CUSTOM?.type && (
              <Trans>{ARTICLE_HASH.CUSTOM?.transKey}</Trans>
            )) ||
            (typeOfArticles === ARTICLE_HASH.RANDOM?.type && (
              <Trans>{ARTICLE_HASH.RANDOM?.transKey}</Trans>
            ))
          }
        />
        <Pill disabled text={area ? describeAreaFilter([area]) : ""} />
        {numberOfArticles && (
          <Pill
            disabled
            text={
              <Trans count={numberOfArticles ?? 0}>countArticlesString</Trans>
            }
          />
        )}
      </Container>
    </div>
  );
}
