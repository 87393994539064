import { ChartOptions } from "chart.js";
import { LineChartProps } from "../LineChartProps";
import { useTranslation } from "@coworker/locales";

export const useGetOptions = (chartData: LineChartProps[], _period: string) => {
  const { t } = useTranslation();

  const options: ChartOptions<"line"> = {
    responsive: true,
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: function () {
            return "";
          },
          label: function (context) {
            const totalTestBuysDone = chartData.find(
              (data) => t(data.label) === context.label
            )?.totalTestBuysDone;
            return [
              `${t("testBuysString")}: ${totalTestBuysDone}`,
              `Pass ratio: ${context.raw}%`,
            ];
          },
        },
      },
    },
    scales: {
      y: {
        afterDataLimits: (scale) => {
          scale.max = 100;
        },
        ticks: {
          stepSize: 10,
          callback: function (value) {
            return `${value}%`;
          },
        },
      },
    },
  };

  return options;
};
