import { useQuery } from "@tanstack/react-query";
import { getGroupsByRoom } from "../services/groups.service";

export const useGroupsByRoomId = (roomId: string) => {
  return useQuery({
    queryKey: ["groupsByRoom", roomId],
    queryFn: () => getGroupsByRoom(roomId),
    enabled: !!roomId,
  });
};
