import { TutorialsTypes } from "../enums/tutorialsTypes";
import appBasicsImage from "../../assets/images/appBasics.png";
import creatingTasksImage from "../../assets/images/creatingTasks.png";
import myTasksImage from "../../assets/images/myTasks.png";
import openTasksImage from "../../assets/images/openTasks.png";
import activityImage from "../../assets/images/activity.png";
import insightsImage from "../../assets/images/insights.png";
import mfaqImage from "../../assets/images/mfaq.png";
import submitPQRImage from "../../assets/images/submitPQR.png";
import pickUpPQRImage from "../../assets/images/pickPQR.png";

export const Tutorials: TutorialItemType[] = [
  {
    type: TutorialsTypes.APP_BASICS,
    title: "appBasicsString",
    imageSrc: appBasicsImage,
  },
  {
    type: TutorialsTypes.CREATING_TASKS,
    title: "creatingTasksString",
    imageSrc: creatingTasksImage,
  },
  {
    type: TutorialsTypes.MY_TASKS,
    title: "myString",
    imageSrc: myTasksImage,
  },
  {
    type: TutorialsTypes.OPEN_TASKS,
    title: "openTasksString",
    imageSrc: openTasksImage,
  },
  {
    type: TutorialsTypes.ACTIVITY,
    title: "activityString",
    imageSrc: activityImage,
  },
  {
    type: TutorialsTypes.INSIGHTS,
    title: "insightsString",
    imageSrc: insightsImage,
  },
  {
    type: TutorialsTypes.MFAQ,
    title: "mfaqGuideString",
    imageSrc: mfaqImage,
  },
  {
    type: TutorialsTypes.PQ_SUBMIT,
    title: "submitProductQualityReportString",
    imageSrc: submitPQRImage,
  },
  {
    type: TutorialsTypes.PQ_PICKUP,
    title: "pickupProductQualityReportString",
    imageSrc: pickUpPQRImage,
  },
];

export type TutorialItemType = {
  type: TutorialsTypes;
  title: string;
  imageSrc: string;
};
