export enum TutorialsTypes {
  "APP_BASICS" = "app-basics",
  "CREATING_TASKS" = "creating-tasks",
  "MY_TASKS" = "my-tasks",
  "OPEN_TASKS" = "open-tasks",
  "ACTIVITY" = "activity",
  "INSIGHTS" = "insights",
  "PQ_SUBMIT" = "pq-submit",
  "PQ_PICKUP" = "pq-pickup",
  "MFAQ" = "mfaq",
}
