import { useCallMFSSearchProducts } from "../../../hooks/API/MFS/useCallMFSSearchProducts";

type Items = {
  Colour: string;
  ColourDescription: string;
  Images: unknown[];
  IsAvailableInStore: true;
  IsBreathTakingItem: false;
  ItemId: string;
  ItemType: string;
  ItemUnitCode: string;
  LatestSalesDate?: string;
  Name: string;
  PaNumber: string;
  PredictedAssemblyTime: number;
  ProductType: string;
};

export function useLatestSalesDate(articleId: string, storeId: string) {
  const MFSResponse = useCallMFSSearchProducts(
    { query: articleId, page: 1, store_id: storeId },
    {
      ...{ blockRequest: false },
      blockRequest: false,
    }
  );

  const items = MFSResponse?.data?.items as Items[];

  return {
    loading: false,
    latestSalesDate: (items && items[0]?.LatestSalesDate) || "",
  };
}
