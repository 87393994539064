import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Tag } from "../assets/svg/backspace.svg";
import { ReactComponent as RightArrow } from "../assets/svg/arrow-right.svg";
import { ReactComponent as CheckMark } from "../assets/svg/checkmark.svg";

const Container = styled.div`
  margin: 0 auto;
  padding-bottom: 8px;
  text-align: center;
  color: var(--grey900);
  user-select: none;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  width: 60px;
  height: 34px;
  line-height: 34px;
  font-size: 24px;
  margin: 4px 10px;
  ${({ buttonsDisabled }) =>
    buttonsDisabled
      ? css`
          color: var(--grey200);
        `
      : css`
          color: var(--grey900);
        `}
`;

const BackspaceButton = styled(ButtonWrapper)`
  color: var(--grey900);
`;

const RoundCTA = styled(ButtonWrapper)`
  width: 34px;
  color: var(--white);
  & svg {
    margin-bottom: 1px;
  }
  border-radius: 50%;
  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          color: var(--grey500);
          background-color: var(--grey150);
        `
      : css`
          color: var(--white);
          background-color: var(--success);
        `}
  ${({ showCheckMark }) =>
    showCheckMark &&
    css`
      background-color: var(--blue-medium);
    `}
`;

const Centered = styled.div`
  margin: 0 auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

function useKeyUp(handler) {
  const previousEvent = React.useRef({});
  const debouncedHandler = React.useCallback(
    (event) => {
      if (previousEvent.current.timeStamp !== event.timeStamp) {
        previousEvent.current = event;
        handler(event.key);
      }
      event.stopPropagation();
    },
    [handler]
  );
  React.useEffect(() => {
    window.addEventListener("keyup", debouncedHandler);
    return () => {
      window.removeEventListener("keyup", debouncedHandler);
    };
  });
}

export function PositiveNumberKeypad({
  disabled,
  onComplete,
  initialValue = "",
  onUpdate,
  completeTestid = "continueButton",
  showCheckMark = false,
  requiredLength,
  buttonsDisabled = false,
  showComma = false,
}) {
  const [value, setValue] = React.useState(initialValue);

  const handleKeypress = (key) => {
    if (!buttonsDisabled) {
      if (key === "Backspace") {
        setValue((value) => value.slice(0, value.length - 1));
      } else if (
        ("0123456789".indexOf(key) !== -1 &&
          requiredLength &&
          value.length < requiredLength) ||
        !requiredLength
      ) {
        setValue((oldValue) => `${oldValue}${key}`);
      }
    }
    if (!disabled && key === "Enter") {
      onComplete(value);
    }
  };

  useKeyUp(handleKeypress);

  React.useEffect(() => {
    onUpdate(value.replace(/[^0-9,.]/g, ""));
  }, [value, onUpdate]);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const Key = ({ value }) => (
    <ButtonWrapper
      data-testid={`key${value}`}
      onClick={() => handleKeypress(value)}
      buttonsDisabled={buttonsDisabled}
    >
      {value}
    </ButtonWrapper>
  );

  return (
    <Container>
      <Centered>
        <Row>
          <Key value={1} />
          <Key value={2} />
          <Key value={3} />
          <ButtonWrapper />
        </Row>
        <Row>
          <Key value={4} />
          <Key value={5} />
          <Key value={6} />
          <ButtonWrapper />
        </Row>
        <Row>
          <Key value={7} />
          <Key value={8} />
          <Key value={9} />
          <BackspaceButton
            data-testid="backspace"
            onClick={() => handleKeypress("Backspace")}
          >
            <Tag>x</Tag>
          </BackspaceButton>
        </Row>
        <Row>
          <ButtonWrapper />
          <Key value={0} />
          {!showComma && <ButtonWrapper />}
          {showComma && <Key value={"."} />}
          <ButtonWrapper>
            <RoundCTA
              disabled={disabled}
              showCheckMark={showCheckMark}
              onClick={() => !disabled && onComplete(value)}
              data-testid={completeTestid}
            >
              {showCheckMark ? <CheckMark /> : <RightArrow />}
            </RoundCTA>
          </ButtonWrapper>
        </Row>
      </Centered>
    </Container>
  );
}
