import React from "react";
import { ChartData } from "chart.js";
import { getChartData } from "../helper/LineChart.helper";
import { TestBuy } from "@coworker/types";
import { COMPLETED_STATUS } from "../../../../constants";

export const useGetData = (
  testbuys: TestBuy[],
  sinceTs: number,
  untilTs: number,
  period: string
) => {
  const [labels, setLabels] = React.useState<string[]>();
  const [passRatio, setPassRatio] = React.useState<number[]>();

  React.useEffect(() => {
    const totalFinishedTestbuys = testbuys.filter(
      (tb) => tb.status === COMPLETED_STATUS
    );

    const chartData = getChartData(
      totalFinishedTestbuys,
      sinceTs || 0,
      untilTs || 0,
      period
    );

    setLabels(chartData.map((d) => d.label));
    setPassRatio(chartData.map((d) => parseInt(d.passRatio.toFixed(0))));
  }, [sinceTs, untilTs, testbuys, period]);

  const data: ChartData<"line"> = {
    labels: labels!,
    datasets: [
      {
        borderColor: "#EAF5E9",
        pointBorderColor: function (context) {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          const numericalValue = typeof value === "number" ? value : value?.y;
          return (numericalValue || 0) >= 85 ? "#0A8A00" : "#E00751";
        },
        backgroundColor: "white",
        pointRadius: 9,
        pointStyle: "circle",
        borderWidth: 5,
        data: passRatio!,
      },
    ],
  };
  return data;
};
