import React from "react";
import { useTranslation } from "@coworker/locales";
import { Text } from "./Text";
import { getPiecesTranslation } from "../tools";

interface PiecesTextProps {
  pieces: number;
}

export function PiecesText({ pieces }: PiecesTextProps) {
  const { t } = useTranslation();

  const piecesOrPiece = t(getPiecesTranslation(pieces));
  return <Text text={`${pieces} ${piecesOrPiece}`} bold grey />;
}
