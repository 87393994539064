import React from "react";
import styled from "styled-components";
import { useInputPopup } from "../../components/InputPopup/index";
import { Trans } from "@coworker/locales";
import { Icon } from "@coworker/components";
import { useToastNotification } from "@coworker/components";
import {
  getTranslatedQuestionText,
  removeQuestionMarks,
} from "../../components/MFAQ/AddQuestionInput";
import { useOnline } from "../../core/hooks/useOnline";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import { ReactComponent as SearchIcon } from "../../assets/svg/icon-search-24.svg";
import tracker from "../../helpers/tracker";
import { useFeatureFlagHooks } from "../../components/MFAQ/useMFAQs.featureToggle";

const StyledSearchIcon = styled(SearchIcon)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  color: var(--black);
`;

export function useMfaqOptionPopupMenu(questionId, questionText) {
  const { mergeQuestionRequest, updateQuestionRequest } = useFeatureFlagHooks();
  const { getInput, closePopup } = useInputPopup();
  const { isOnline } = useOnline();
  const { showToast } = useToastNotification();
  const { push } = useWorkspacesAction();
  const [updatedQuestion, setUpdatedQuestion] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const updateQuestion = (newQuestionText) => {
    const subject = removeQuestionMarks(newQuestionText);
    const questionText = getTranslatedQuestionText(newQuestionText);

    const payload = {
      question_id: questionId,
      question_text: questionText,
      subject: subject,
    };
    updateQuestionRequest(questionId, questionText, subject);
    setUpdatedQuestion(payload);
  };

  const mergeQuestion = async (secondQuestionId) => {
    // questionId is the base question(first question)
    const newQuestionId = await mergeQuestionRequest(
      questionId,
      secondQuestionId
    );
    if (newQuestionId) {
      showToast(<Trans>mfaqMergeCompletedString</Trans>);
      push(`/customerquestions/${newQuestionId}`);
    } else {
      //  showToast(<Trans>mergeUnsuccessString</Trans>);
      console.error("Error: MFAQ merge question", newQuestionId);
    }
  };

  const mergeQuestionAction = {
    isDisabled: !isOnline,
    onClick: async () => {
      const mergeQuestionId = await getInput("mfaqMergeQuestion", {
        questionId,
      });
      tracker.MFAQ.trackMergeQuestion(questionText, questionId);
      if (!mergeQuestionId) return;
      setLoading(true);
      mergeQuestion(mergeQuestionId);
      closePopup();
    },
    title: <Trans>mfaqMergeQuestionString</Trans>,
    icon: <StyledSearchIcon />,
  };
  const actions = [
    {
      isDisabled: !isOnline,
      onClick: async () => {
        const newQuestionText = await getInput("mfaqEditQuestion", {
          questionText,
        });
        tracker.MFAQ.trackEditQuestion(questionText, questionId);
        if (!newQuestionText) return;
        updateQuestion(newQuestionText);
        closePopup();
      },
      title: <Trans>mfaqEditQuestionString</Trans>,
      enum: "EDIT",
      icon: (
        <Icon
          size="16"
          family="icons"
          name="pencil"
          color="grey900"
          className="removeHeight"
        />
      ),
    },
    mergeQuestionAction,
  ];

  const getMfaqOptionsMenu = () => getInput("popupMenu", { actions });

  return { updatedQuestion, getMfaqOptionsMenu, loading };
}
