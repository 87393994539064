import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import useFormatter from "../../hooks/useFormatter";
import { useTaskHistory } from "./useTaskHistory";
import { sign } from "@coworker/reusable/helpers/math";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--grey200);
`;
const Row = styled.div`
  align-items: center;
`;
const Heading = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin: 17px 24px;
`;

const TaskWrapper = styled.div`
  margin-left: 24px;
  flex-direction: column;
`;

const Taskdetails = styled.div`
  display: flex;
  margin: 19px 0px;
  float: left;
`;

const TaskSummary = styled.div`
  font-size: 14px;
  margin-left: 22px;
  font-weight: bold;
`;

const VotesCount = styled(TaskSummary)`
  font-weight: normal;
`;

export default function TaskHistory({ tasks, actions }) {
  const { formatDateMedium } = useFormatter();
  if (!tasks?.length && !actions?.length) return null;

  // To display history we are combining both tasks and actions array into one.
  const historyItems = [...(tasks || []), ...(actions || [])];
  // Display history in decending order by date.
  historyItems.sort(
    (a, b) =>
      -sign((a.finished_at || a.taken_at) - (b.finished_at || b.taken_at))
  );

  return (
    <Container data-testid="mfaq-task-history">
      <Heading>
        <Trans>History</Trans>
      </Heading>
      {
        <TasksHistoryContainer
          data={historyItems}
          dateFormat={formatDateMedium}
        />
      }
    </Container>
  );
}

function TasksHistoryContainer({ data, dateFormat }) {
  const history = useTaskHistory(data, dateFormat);

  return history.map(({ key, taskIcon, taskSummaryText, votesCountText }) => {
    return (
      <TaskWrapper key={key}>
        <Taskdetails>
          {taskIcon}
          <Row>
            <TaskSummary>{taskSummaryText}</TaskSummary>
            <VotesCount>{votesCountText}</VotesCount>
          </Row>
        </Taskdetails>
      </TaskWrapper>
    );
  });
}
