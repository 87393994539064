import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import { issueTypeDescriptions } from "../constants/issueTypeDescriptions";
import { describeTheProblemList } from "../constants/describeTheProblemList";
import { getTranslationKeyFromFlags } from "@coworker/app/src/components/RoomSettings/tools";
import { Task } from "@coworker/types/lib/tasks/base";
import { AddonTask } from "@coworker/types/lib/tasks/addon";
import { ProductQualityTask } from "@coworker/types/lib/tasks/product_quality";
import { ProductIssueTask } from "@coworker/types/lib/tasks/product_issue";
import { MFAQFollowUp } from "@coworker/types/lib/tasks/mfaq_followup";
import { TestBuyFollowUp } from "@coworker/types/lib/tasks/testbuy_followup";
import { RoomSettings } from "@coworker/types/lib/tasks/room_settings";
import { CustomTask } from "@coworker/types/lib/tasks/custom";

export const getRelatedTaskInfo = (
  task: Task,
  t: (key?: string) => string,
  productUnitTitle: string
) => {
  let type, title, subtitle;

  switch (task.task_type) {
    case taskTypeOptions.ADDON:
      const addonTask = task as AddonTask;
      type =
        addonTask.picked ||
        addonTask.state === taskStateOptions.COMPLETED ||
        addonTask.state === taskStateOptions.CLOSED
          ? t("refillString")
          : t("addonTitleString");
      title =
        addonTask.state === taskStateOptions.COMPLETED
          ? addonTask.refilled_quantity === addonTask.actual_requested_quantity
            ? `${addonTask.actual_requested_quantity} ${productUnitTitle} ${t(
                "itemsToString"
              )} ${addonTask.location_grid_code}`
            : `${addonTask.actual_requested_quantity} → ${
                addonTask.refilled_quantity
              } ${productUnitTitle} ${t("itemsToString")} ${
                addonTask.location_grid_code
              }`
          : `${addonTask.actual_requested_quantity} ${productUnitTitle} ${t(
              "itemsFromString"
            )}
        ${
          addonTask.pickup_location_custom
            ? addonTask.pickup_location_custom
            : addonTask.pickup_location_grid_code
        } ${t("itemsToString")} ${
              addonTask.location_custom
                ? addonTask.location_custom
                : addonTask.location_grid_code
            }`;
      subtitle = "";
      break;

    case taskTypeOptions.PRODUCT_QUALITY:
      const productQualityTask = task as ProductQualityTask;
      type = t("productQualityReportString");
      title = t(
        describeTheProblemList.find(
          (problem) =>
            problem.type === productQualityTask.pq_describe_the_problem_type
        )?.transKey
      );
      subtitle = "";
      break;

    case taskTypeOptions.PRODUCT_ISSUE:
      const productIssueTask = task as ProductIssueTask;
      type = t("taskTypeTitleProductIssue");
      title = t(
        issueTypeDescriptions.find(
          (issue) => issue.type === productIssueTask.issue_type
        )?.transKey
      );
      subtitle = "";
      break;

    case taskTypeOptions.MFAQ_FOLLOW_UP:
      const mfaqTask = task as MFAQFollowUp;
      type = t("mfaqFollowUpTitle");
      title = mfaqTask.question_text;
      subtitle = "";
      break;

    case taskTypeOptions.TESTBUY_FOLLOW_UP:
      const testbuyTask = task as TestBuyFollowUp;
      type = t("testBuyFollowUpString");
      title = `${t("atString")} ${task.location}, ${
        testbuyTask.failedCriteria?.length
      } ${t("criteraFailedString")}`;
      subtitle = "";
      break;

    case taskTypeOptions.ROOM_SETTINGS:
      const roomSettingsTask = task as RoomSettings;
      type = t("roomSettingsString");
      title = `${t(getTranslationKeyFromFlags(task as any))} ${
        roomSettingsTask.room_name
      }`;
      subtitle = "";
      break;

    case taskTypeOptions.CUSTOM:
      const customTask = task as CustomTask;
      const isScheduledTask =
        customTask.planned || customTask.repeatable_task_id;
      type = isScheduledTask ? t("scheduledTaskString") : t("customString");
      title = customTask.custom_title;
      subtitle = task.description;
      break;
  }

  return { type, title, subtitle };
};
