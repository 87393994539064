import React from "react";
import { getCachedInternalApi } from "../hooks/API/useCallInternal";
import { useStoreId } from "../core/auth/useLoggedInUser";

export function useTaskActivities(taskId: string) {
  const [result, setResult] = React.useState([]);
  const storeId = useStoreId();
  React.useEffect(() => {
    if (taskId) {
      getCachedInternalApi(
        `task/activities?id=${taskId}`,
        ({ data }: any) => setResult(data?.list || []),
        60_000,
        false,
        "",
        { store_id: storeId }
      );
    }
  }, [taskId, storeId]);
  return result;
}
