import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { AmountPopupMultiPack } from "./AmountPopupMultiPack";
import SkapaTabs from "./SkapaTabs";
import { Container, OverflowBackground } from "./CommonComponents";
import { useWorkspacesState } from "../../hooks/useWorkspaces";
import {
  storeAmountSettings,
  useProductAmountSettings,
} from "../../hooks/useProductSettings";
import { StockInfoPillsWithRelatedTasks } from "./StocknfoPillsWithRelatedTasks";
import { useTasksForProduct } from "../../hooks/useTasksForProduct";
import amountTypeOptions from "@coworker/functions/src/enums/amountTypes";
import { PositiveNumberKeypad } from "../PositiveNumberKeypad";
import useAmountConfirmation from "./shared/useAmountConfirmation";
import useFormatter from "../../hooks/useFormatter";
import { useTranslation } from "@coworker/locales";
import { ReactComponent as CheckMarkCircle } from "../../assets/svg/checkmark-circle.svg";
import { ReactComponent as Community } from "../../assets/svg/community.svg";
import { BlueLink } from "../Link";
import { useParentPopup } from ".";
import { useStoreId, useTeamId } from "../../core/auth/useLoggedInUser";
import tracker from "../../helpers/tracker";
import { SkapaModalHeader } from "../SkapaModalHeader";

const { PALLET_SETTINGS, MULTI_PACK_SETTINGS, MULTI_PACK, PALLET, PIECES } =
  amountTypeOptions;

const isSettingsTab = (active) =>
  [MULTI_PACK_SETTINGS, PALLET_SETTINGS].includes(active);

const settingKeys = {
  [PALLET_SETTINGS]: "pieces_per_pallet",
  [MULTI_PACK_SETTINGS]: "pieces_per_multi_pack",
};

const InputWrapper = styled.div`
  margin: 0px 24px;
  margin-top: 12px;
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
`;

const SuccessMessage = styled.div`
  font-size: 12px;
  color: var(--green);
`;

const SettingsSavedMessage = () => (
  <SuccessMessage>
    <CheckMarkCircle width="20px" height="20px" />{" "}
    <Trans>savedForStoreString</Trans>
  </SuccessMessage>
);

const ChangeTeamContainer = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const ChangeLink = styled((props) => <BlueLink {...props} />)`
  margin-top: 0px;
`;

const AssignedToContainer = styled.div`
  margin-left: 10px;
  margin-right: 8px;
`;

const TeamChangeNearbyAddons = ({ name, onClick }) => (
  <ChangeTeamContainer data-testid="changeTeamContainer">
    <Community />
    <AssignedToContainer>
      <Trans values={{ name }}>assignedToWithNameString</Trans>
    </AssignedToContainer>
    <ChangeLink onClick={onClick}>
      <Trans>changeTeamString</Trans>
    </ChangeLink>
  </ChangeTeamContainer>
);

const Title = ({ i18nKey, count }) => {
  const { t } = useTranslation();
  return <Trans count={count || t("notSetString")}>{i18nKey}</Trans>;
};

export function AmountTabsWithPills({
  oldAmountType = "",
  oldActualAmount = "",
  maxAmount,
  onClose,
  changeAmount,
  onSubmit,
  initializeWithOldAmount = false,
  available,
  productId,
  productArticleType,
  navigateToTaskForm,
  showTeamChange,
  lastAssignedTeam,
}) {
  const { t } = useTranslation();
  const { getInput } = useParentPopup();
  const { productUnit } = useFormatter();
  const { formData } = useWorkspacesState().navigationState;
  const amountConfirmation = useAmountConfirmation();
  const [assignedTo, setAssignedTo] = React.useState("");

  const normalizedOldAmountType = (oldAmountType || "").toUpperCase(); // Just uppercasing is good enough if only "pieces" appear in lowercase in old task documents, which is true on -test.
  const [active, setActive] = React.useState(normalizedOldAmountType || PIECES);

  const [isSettingsSaved, setIsSettingsSaved] = React.useState(false);
  const [hasSettingsChanged, setHasSettingsChanged] = React.useState(false);
  const shortId = formData?.product_article_id || productId;
  const storeId = useStoreId();
  const fromStore = useProductAmountSettings(shortId);
  const [amountSettings, setAmountSettings] = React.useState(fromStore);
  React.useEffect(() => setAmountSettings(fromStore), [fromStore]);

  const isPackOrPallet = [MULTI_PACK, PALLET].includes(active);
  const getSetting = (type) => {
    if (type === MULTI_PACK) {
      return amountSettings?.pieces_per_multi_pack?.toString(); //toString is required since tasks service will return number
    }
    if (type === PALLET) {
      return amountSettings?.pieces_per_pallet?.toString(); ////toString is required since tasks service will return number
    }
  };

  const hasSetting = (type) => !!getSetting(type);
  const actualAmount = initializeWithOldAmount ? oldActualAmount : "";

  /**
   * @param {*} type A type from amountTypeOptions
   * @returns {String} the amount to be displayed in the input
   */
  const getInputValue = (type) => {
    if (type === MULTI_PACK_SETTINGS) {
      return getSetting(MULTI_PACK);
    } else if (type === PALLET_SETTINGS) {
      return getSetting(PALLET);
    } else if (type === normalizedOldAmountType && !hasSettingsChanged) {
      return String(actualAmount);
    } else return "";
  };

  const [valueAsString, setValue] = React.useState(getInputValue(active) || "");
  const [maxAmountExceededEvent, setMaxAmountExceededEvent] = React.useState({
    warningShown: false,
    popupShown: false,
    popupSelection: "",
  });

  // This effect is used to update the SkapaInput field value. It should trigger when we switch selected tab.
  React.useEffect(
    () => {
      setValue(getInputValue(active));
    },
    // eslint-disable-next-line
    [active]
  );

  const teamId = useTeamId();
  React.useEffect(() => {
    setAssignedTo({
      assignedTeamId: lastAssignedTeam?.id || teamId,
      assignedName: lastAssignedTeam?.name || t("teamYourString"),
      assignedUserId: "",
    });
  }, [lastAssignedTeam, teamId, t]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (isSettingsSaved) setIsSettingsSaved(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [isSettingsSaved]);

  // TODO: The Math.round(valueAsString) is ugly. Any bug risks?
  const disabled =
    !valueAsString ||
    valueAsString.length > 6 ||
    Number(valueAsString) !== Math.round(valueAsString) ||
    (!changeAmount && Number(valueAsString) === 0) ||
    (isPackOrPallet && !hasSetting(active)) ||
    Number(valueAsString) < 0;

  const calculateTotalPieces = (amount, piecesPerPack) => {
    return Number(amount) * (piecesPerPack || 1);
  };

  const submitWithConfirmation = React.useCallback(
    async ({ amount }) => {
      const totalPieces = isPackOrPallet
        ? calculateTotalPieces(amount, getSetting(active))
        : amount;
      if (
        !(await amountConfirmation(
          totalPieces,
          maxAmount,
          available,
          (confirmationSelection) => {
            maxAmountExceededEvent.popupShown = true;
            maxAmountExceededEvent.popupSelection = confirmationSelection;
          }
        ))
      ) {
        return;
      }
      onSubmit({
        amount: totalPieces,
        actualAmount: amount,
        amountType: active,
        assignedTeamId: assignedTo?.assignedTeamId,
        assignedUserId: assignedTo?.assignedUserId,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      amountConfirmation,
      maxAmount,
      onSubmit,
      available,
      active,
      isPackOrPallet,
      assignedTo,
      amountSettings,
    ]
  );

  const getMaxPiecesText = () => {
    if (maxAmount) {
      const displayUnit = productUnit(PIECES);
      return (
        <Trans values={{ quantity: maxAmount, unit: displayUnit }}>
          maxNUnitsString
        </Trans>
      );
    }
  };

  const setHelperText = (type) => {
    if (type === MULTI_PACK) {
      return valueAsString && hasSetting(type) ? (
        <p>
          = {calculateTotalPieces(valueAsString, getSetting(type))}{" "}
          {t("piecesUnit")}
          {!!maxAmount ? " (" : ""}
          {getMaxPiecesText()}
          {!!maxAmount ? ")" : ""}
        </p>
      ) : hasSetting(type) ? (
        getMaxPiecesText()
      ) : (
        ""
      );
    } else if (type === PALLET) {
      return valueAsString && hasSetting(type) ? (
        <p>
          = {calculateTotalPieces(valueAsString, getSetting(type))}{" "}
          {t("piecesUnit")}
          {!!maxAmount ? " (" : ""}
          {getMaxPiecesText()}
          {!!maxAmount ? ")" : ""}
        </p>
      ) : hasSetting(type) ? (
        getMaxPiecesText()
      ) : (
        ""
      );
    } else if (type === PALLET_SETTINGS || type === MULTI_PACK_SETTINGS)
      return "";
    return getMaxPiecesText();
  };

  const goToSettings = React.useCallback(() => {
    setActive((active) =>
      active === MULTI_PACK ? MULTI_PACK_SETTINGS : PALLET_SETTINGS
    );
  }, []);

  const getTabContent = (dataTestId) => {
    return (
      <AmountPopupMultiPack
        maxAmount={maxAmount}
        oldAmountSettings={amountSettings}
        goToSettings={goToSettings}
        activeTab={active}
        inputValue={valueAsString}
        helperText={setHelperText(active)}
        totalPieces={calculateTotalPieces(valueAsString, getSetting(active))}
        dataTestId={dataTestId}
        maxAmountExceeded={() => {
          maxAmountExceededEvent.warningShown = true;
        }}
      />
    );
  };

  const tabs = [
    {
      title: <Trans>piecesTitleString</Trans>,
      key: PIECES,
      "data-testid": PIECES,
      content: getTabContent(PIECES),
    },
    {
      title: (
        <Title i18nKey="multipackTitleString" count={getSetting(MULTI_PACK)} />
      ),
      key: MULTI_PACK,
      "data-testid": MULTI_PACK,
      content: getTabContent(MULTI_PACK),
    },
    {
      title: <Title i18nKey="palletTitleString" count={getSetting(PALLET)} />,
      key: PALLET,
      "data-testid": PALLET,
      content: getTabContent(PALLET),
    },
    {
      title: <Trans>multipackSettingsTitleString</Trans>,
      key: MULTI_PACK_SETTINGS,
      "data-testid": MULTI_PACK_SETTINGS,
      content: getTabContent(MULTI_PACK_SETTINGS),
    },
    {
      title: <Trans>palletSettingsTitleString</Trans>,
      key: PALLET_SETTINGS,
      "data-testid": PALLET_SETTINGS,
      content: getTabContent(PALLET_SETTINGS),
    },
  ];

  const openAssignToPopUp = React.useCallback(async () => {
    const data = await getInput("selectAssignees", {
      title: <Trans>reassignString</Trans>,
      shortId,
      assignees: {
        gid: assignedTo.assignedTeamId,
        uid: assignedTo.assignedUserId,
      },
    });
    if (!data) return;
    setAssignedTo({
      assignedName: data.name,
      assignedTeamId: data.gid,
      assignedUserId: data.uid,
    });
  }, [assignedTo, getInput, shortId]);

  const { formattedCount, ofWhichClosed } = useTasksForProduct(shortId);

  const onComplete = React.useCallback(
    (value) => {
      if (isSettingsTab(active)) {
        const nextAmountSettings = {
          pieces_per_multi_pack: amountSettings?.pieces_per_multi_pack ?? "",
          pieces_per_pallet: amountSettings?.pieces_per_pallet ?? "",
          [settingKeys[active]]: value,
        };
        setAmountSettings(nextAmountSettings);
        setHasSettingsChanged(true);
        storeAmountSettings(shortId, storeId, nextAmountSettings).then(() => {
          setIsSettingsSaved(true);
        });
      } else {
        submitWithConfirmation({ amount: Number(value) });
      }
    },
    [amountSettings, active, submitWithConfirmation, shortId, storeId]
  );

  const callStastisticsFunction = () => {
    console.log("callStastisticsFunction");
  };

  React.useEffect(() => {
    setMaxAmountExceededEvent(maxAmountExceededEvent);

    // Here we use the unmount hook to send off a message to the tracker.
    // So the tracking happens when this screen is closed (or just garbage collected and then re-rendered, but that's uncommon enough for now to not be an issue)
    return () => {
      if (maxAmountExceededEvent.warningShown) {
        tracker.trackMaxAmountExceeded(maxAmountExceededEvent);
      }
    };
  }, [maxAmountExceededEvent]);

  const full_id = `${
    formData?.product_article_type || productArticleType
  }${shortId}`;

  const tabChangedHandler = (goodArgumentName) => {
    setActive(goodArgumentName);
    callStastisticsFunction(goodArgumentName);
  };

  return (
    <>
      <OverflowBackground
        data-testid="background"
        onClick={() => {
          tracker.trackDismissModal(false);
          onClose();
        }}
      />
      <Container data-testid="amountScreen">
        <SkapaModalHeader titleString="piecesTitleString" onClose={onClose} />
        <InputWrapper>
          <StockInfoPillsWithRelatedTasks
            relatedTasksCount={formattedCount}
            ofWhichClosed={ofWhichClosed}
            productFullId={full_id}
            productId={shortId}
            navigateToTaskForm={navigateToTaskForm}
            onClose={onClose}
          />
          <SkapaTabs
            tabs={tabs}
            active={active}
            onTabChanged={tabChangedHandler}
          />
          {isSettingsTab(active) && isSettingsSaved && <SettingsSavedMessage />}
          {!!showTeamChange && (
            <TeamChangeNearbyAddons
              name={assignedTo.assignedName}
              onClick={openAssignToPopUp}
            />
          )}
          <Centered>
            <PositiveNumberKeypad
              disabled={disabled}
              initialValue={(valueAsString || "").replace(/\D/, "")}
              onUpdate={setValue}
              onComplete={onComplete}
              showCheckMark={isSettingsTab(active)}
            />
          </Centered>
        </InputWrapper>
      </Container>
    </>
  );
}
