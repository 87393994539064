import React from "react";
import {
  CenterContainer,
  SizedText,
  PaddedContainer,
  SimpleContainer,
} from "../styles/styles";
import { Trans } from "@coworker/locales";
import Button from "@ingka/button";

interface NoRoomsLaunchAdminProps {
  keyForMessageToUser: string;
}

export function NoRoomsLaunchAdmin({
  keyForMessageToUser,
}: NoRoomsLaunchAdminProps) {
  return (
    <PaddedContainer $px={"0px"} $py={"48px"}>
      <CenterContainer $centerText={true}>
        <SimpleContainer $direction={"column"}>
          <SizedText $fontSize={20} $bold={true}>
            <Trans>{keyForMessageToUser}</Trans>
          </SizedText>
          <Button
            type="primary"
            onClick={() =>
              window.open("https://fixa-admin.ingka.com/roomsettings")
            }
          >
            <Trans>gotoAdminString</Trans>
          </Button>
        </SimpleContainer>
      </CenterContainer>
    </PaddedContainer>
  );
}
