import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import tracker from "../../helpers/tracker";
import useInsightsGraphData from "./useInsightsGraphData";
import useInsightsAddonLists from "../../hooks/useInsightsAddonLists";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import { ReactComponent as ExpandIcon } from "../../assets/svg/expand.svg";
import { useMyStore } from "../../hooks/useMyStore";
const _ = require("lodash");

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
  background-color: var(--white);
  border-radius: 5px;
  border: 20px solid var(--grey100);
`;
const Skeleton = styled.div`
  background: var(--grey200);
  border-radius: 5px;
  height: 21px;
  width: 120px;
  margin: 2px 0px 3px 0px;
`;

const Period = styled.div`
  padding-top: 24px;
  font-size: 11px;
`;

const Store = styled.div`
  font-weight: bold;
  font-size: 28px;
`;

const Title = styled.div`
  font-size: 11px;
  color: var(--grey718);
  margin-top: 29px;
  margin-bottom: 5px;
`;

const Data = styled.div`
  font-weight: bold;
`;
const BoxContent = styled.div`
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 26px;
  & > div {
    width: 50%;
  }
`;
const AlignBottom = styled.div`
  margin-top: auto;
`;

const StyledExpandIcon = styled(ExpandIcon)`
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
`;

const weekStrings = {
  0: "thisWeekString",
  4: "fourWeeksString",
  8: "eightWeeksString",
};

export function StoreBox({
  dataFetchParams: baseFetchParams,
  onChangeInsightsParams,
}) {
  const { push } = useWorkspacesAction();
  const storeName = useMyStore()?.name;
  const dataFetchParams = { ...baseFetchParams, hfb: null };

  const { totalTurnover, loading } = useInsightsGraphData(
    dataFetchParams,
    true
  );

  const bestProductData = useInsightsAddonLists(false, {
    ...dataFetchParams,
    order_by: "turnover",
    order_by_asc: false,
    limit: 1,
  });

  return (
    <Container
      data-testid="store-box"
      onClick={() => {
        tracker.insights.trackStoreBox();
        const updatedInsightsFilter = {};
        _.set(updatedInsightsFilter, ["deptFilters", "hfb"], "ALL");
        onChangeInsightsParams(updatedInsightsFilter);
        push(`/insights/store`);
      }}
    >
      <StyledExpandIcon />
      <Period>
        <Trans>{weekStrings[dataFetchParams?.period?.weeksBack]}</Trans>
      </Period>
      <Store>{storeName}</Store>
      <BoxContent>
        <AlignBottom>
          <Title>
            <Trans>estAddonTurnoverString</Trans>
          </Title>
          <Data data-testid="store-turnover">
            {loading ? <Skeleton /> : totalTurnover}
          </Data>
        </AlignBottom>
        <AlignBottom>
          <Title>
            <Trans>bestString</Trans>
          </Title>
          <Data data-testid="store-best">
            {bestProductData.loading ? (
              <Skeleton />
            ) : (
              bestProductData.data[0]?.title
            )}
          </Data>
        </AlignBottom>
      </BoxContent>
    </Container>
  );
}
