import React from "react";
import Text from "@ingka/text";
import { Trans } from "@coworker/locales";
import styles from "./tutorials.module.css";

export const TutorialsTitle: React.FC = () => {
  return (
    <div className={styles["tutorials-title-container"]}>
      <Text tagName="h2" headingSize="s">
        <Trans>howToString</Trans>
      </Text>
      <Text tagName="span" bodySize="s">
        <Trans>tutorialTitleBodyString</Trans>
      </Text>
    </div>
  );
};
