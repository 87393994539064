import React from "react";
import styled from "styled-components";
import { Icon } from "@coworker/components";
import {
  callInternal,
  EXCEL_EXPORT,
  MFAQ_EXCEL_EXPORT,
} from "../hooks/API/useCallInternal";
import { useFirebase } from "react-redux-firebase";
import { LoaderIcon } from "@coworker/reusable/Loader";
import tracker, { logEvent } from "../helpers/tracker";
import { postToTasksService } from "../hooks/API/tasks.service.helper";
import { postToMFAQService } from "./MFAQ/BackendApiRepository/BaseRequests";
import { MfaqApiEndpoints } from "./MFAQ/BackendApiRepository/EndpointsTypes";

const storageFolder = (type) => `${type}_exports`;
const LoaderComponent = styled(LoaderIcon)`
  padding: 10px;
`;

const ButtonWrapper = styled.div`
  margin-right: 12px;
`;

const FUNCTIONS = {
  mfaq: MFAQ_EXCEL_EXPORT,
  insights: EXCEL_EXPORT,
};

/**
 * @param {{dataFetchParams: any, type: "mfaq"|"insights"}} props
 */
export default function ExportExcel({ dataFetchParams, type, extra = {} }) {
  const [loading, setLoading] = React.useState(false);
  const callTasksService = window.finalInsightsFromTasksServiceFlag;
  const firebase = useFirebase();
  const callMFAQService = window.callMFAQService;

  const generateReport = React.useCallback(async () => {
    setLoading(true);
    try {
      let downloadUrl = "";
      let filename = "";
      if (callTasksService && type === "insights") {
        logEvent("feature_validation:insights_excel_export");
        await postToTasksService(
          "/v1/insights/excelExport_v0",
          { ...dataFetchParams, ...extra },
          "blob"
        ).then((result) => {
          downloadUrl = window.URL.createObjectURL(
            new Blob([result], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
        });

        //TODO Need to see if we need UUID in filename
        filename = "insights-exports.xlsx";
      } else if (callMFAQService && type === "mfaq") {
        logEvent("feature_validation:mfaq_excel_export");
        let dataFromMfaqService = "";
        await postToMFAQService(
          `${MfaqApiEndpoints.EXCEL_EXPORT}`,
          { ...dataFetchParams, ...extra },
          (result) => {
            dataFromMfaqService = result;
          },
          "blob"
        );
        downloadUrl = window.URL.createObjectURL(
          new Blob([dataFromMfaqService], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        filename = "mfaq-exports.xlsx";
      } else {
        const storage = firebase.storage().ref();
        const { data: filename } = await callInternal(FUNCTIONS[type], {
          ...dataFetchParams,
          ...extra,
        });
        downloadUrl = await storage
          .child(`${storageFolder(type)}/${filename}`)
          .getDownloadURL();
      }
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", filename);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      setLoading(false);
      tracker[type].trackExport(dataFetchParams);
    } catch (error) {
      setLoading(false);
    }
    //ignored firebase
    // eslint-disable-next-line
  }, [type, dataFetchParams, extra, callTasksService]);

  return loading ? (
    <LoaderComponent size="24" />
  ) : (
    <ButtonWrapper onClick={generateReport} data-testid="exportExcel">
      <Icon family="icons" name="download" size={24} />
    </ButtonWrapper>
  );
}
