import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { WideButton } from "@coworker/components";
import trackerHelper from "../../helpers/tracker";

const Fullscreen = styled.div`
  background: var(--white);
  padding: 1em;
  padding-top: 90px;
  padding-bottom: 70px;
  height: 100vh;
`;

const Title = styled.div`
  font-weight: bold;
  padding: 1em 0;
`;

const Section = styled.div`
  padding-bottom: 1em;
  border-bottom: 1px solid var(--grey200);
`;

export function SafetyAlarmMoreInfo({
  onSubmit,
}: {
  onSubmit: (ok?: boolean) => void | Promise<void>;
}) {
  return (
    <Fullscreen>
      <h3>
        <Trans>safetyAlarmRequiresString</Trans>
      </h3>

      <Title>
        <Trans>safetyAlarmTitle1String</Trans>
      </Title>
      <Section>
        <Trans>safetyAlarmText1String</Trans>
      </Section>

      <Title>
        <Trans>safetyAlarmTitle2String</Trans>
      </Title>
      <Section>
        <Trans>safetyAlarmText2String</Trans>
      </Section>

      <Title>
        <Trans>safetyAlarmTitle3String</Trans>
      </Title>
      <Section>
        <Trans>safetyAlarmText3String</Trans>
      </Section>

      <Title>
        <Trans>safetyAlarmTitle4String</Trans>
      </Title>
      <Section>
        <Trans>safetyAlarmText4String</Trans>
      </Section>

      <WideButton
        onClick={() => {
          onSubmit(true);
          trackerHelper.safetyAlarmInfo.closeOK();
        }}
        dark
        primary
        text={<Trans>okiunderstandString</Trans>}
      />
    </Fullscreen>
  );
}
