import React from "react";
import { TutorialsTitle } from "./TutorialsTitle";
import { TutorialsContent } from "./TutorialsContent";
import styles from "./tutorials.module.css";

export const Tutorials: React.FC = () => {
  return (
    <div className={styles["tutorials-main-container"]}>
      <TutorialsTitle />
      <TutorialsContent id="tutorials_home" />
    </div>
  );
};
