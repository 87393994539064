import React, { ReactNode, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  CenterContainer,
  LeftContainer,
  ListItemContainer,
  ListItemImage,
  ListItemImageContainer,
} from "../styles/styles";
import { useImagesByRoomId } from "../hooks/useImagesByRoomId";
import { UploadImageButton } from "./UploadImageButton";
import { useImagesBySignedUrl } from "../hooks/useImagesBySignedUrl";
import { Room } from "../types/room";
import { useDeleteImages } from "../hooks/useDeleteImages";
import { DeleteImageButton } from "./DeleteImageButton";
import Button from "@ingka/button";
import Checkbox from "@ingka/checkbox";
import { useTranslation } from "@coworker/locales";
import { ArrowCounterClockWise } from "../Atoms/Icons/ArrowCounterClockWise";
import { Image } from "../types/images";
import { updateImage } from "../services/images.service";

interface RoomImagesListItemProps {
  icon?: ReactNode;
  room: Room;
}

export const RoomImagesListItem = ({ icon, room }: RoomImagesListItemProps) => {
  const queryClient = useQueryClient();
  const { data: images } = useImagesByRoomId(room.id);
  const deleteImageMutation = useDeleteImages();
  const [selectImage, setSelectImage] = useState<string[]>([]);
  const [selectButtonClick, setSelectButtonClick] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState(false);
  const [showButton, setShowButton] = useState<"upload" | "delete">("upload");
  const { t } = useTranslation();

  const signedUrlImages = useImagesBySignedUrl(room);

  const allImages = [
    ...(images?.map((image, index) => ({
      ...image,
      url: signedUrlImages[index]?.url || "",
    })) || []),
  ];

  const handleDeleteImage = async (imageIds: string[]) => {
    try {
      await deleteImageMutation.mutateAsync({ imageIds, roomId: room.id });
    } catch (error) {
      alert(t("backendOperationFailedString"));
    }
    setSelectImage([]);
    setShowButton("upload");
    setSelectButtonClick(false);
  };

  const handleSelectedImage = (imageId: string) => {
    if (selectImage.includes(imageId)) {
      setSelectImage(selectImage.filter((id) => id !== imageId));
    } else {
      setSelectImage([...selectImage, imageId]);
    }
  };

  const handleRotateImage = async (
    image: Image,
    index: number
  ): Promise<void> => {
    image.rotation = image.rotation ? (image.rotation + 90) % 360 : 90;
    const carouselImage = document.getElementById(`${image.id}-${index}`);
    if (carouselImage) {
      carouselImage.style.transform = "rotate(" + image.rotation + "deg)";
    }

    if (image.id) {
      try {
        await updateImage(image.id, image);
        await queryClient.invalidateQueries({ queryKey: ["imagesByRoom"] });
      } catch (error) {
        alert(t("backendOperationFailedString"));
      }
    }
  };

  if (!images) return <></>;

  return (
    <>
      <ListItemContainer>
        <LeftContainer>{icon}</LeftContainer>
        <CenterContainer $centerText={false}>
          <ListItemImageContainer>
            {allImages.map((image, index) => (
              <div style={{ position: "relative" }}>
                <ListItemImage
                  id={`${image.id}-${index}`}
                  key={image.id}
                  src={image.url ?? ""}
                  $rotation={image.rotation || 0}
                />
                {selectButtonClick && image.id && (
                  <div
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  >
                    <Checkbox
                      id={image.id || ""}
                      value={image.id || ""}
                      onChange={() => {
                        image.id && handleSelectedImage(image.id);
                      }}
                    />
                  </div>
                )}
                <div
                  onClick={() => {
                    void handleRotateImage(image, index);
                  }}
                >
                  <ArrowCounterClockWise />
                </div>
              </div>
            ))}

            {showButton === "upload" && <UploadImageButton roomId={room.id} />}
            {showButton === "delete" && (
              <DeleteImageButton
                deleteImage={handleDeleteImage}
                selectedImages={selectImage}
                disabled={selectImage.length === 0}
              />
            )}
          </ListItemImageContainer>
        </CenterContainer>
      </ListItemContainer>
      {images.length > 0 && (
        <Button
          style={{ marginLeft: "40px", marginBottom: "5px" }}
          type="secondary"
          onClick={() => {
            setShowButton(showButton === "upload" ? "delete" : "upload");
            setIsCancel(!isCancel);
            setSelectButtonClick(!selectButtonClick);
            if (isCancel) {
              setSelectImage([]);
            }
          }}
          text={showButton === "upload" ? t("deleteString") : t("cancelString")}
          small
        />
      )}
    </>
  );
};
