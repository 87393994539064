import React from "react";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import Tasks from "@coworker/app/src/components/Tasks";
import { Trans } from "@coworker/locales";
import { useLocation } from "react-router-dom";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import { TaskTabsTypes } from "../../../shared/constants/taskTabsTypes";
import "./task-list-tabs.css";

export const TasksListTabs = () => {
  const location = useLocation();
  const activeTab = location.search.split("=")[1] ?? "my";
  const { push } = useWorkspacesAction();

  const tabs = [
    <Tab
      key={TaskTabsTypes.MY}
      text={<Trans>myString</Trans>}
      tabPanelId={TaskTabsTypes.MY}
    />,
    <Tab
      key={TaskTabsTypes.OPEN}
      text={<Trans>openTasksString</Trans>}
      tabPanelId={TaskTabsTypes.OPEN}
    />,
  ];

  const tabPanels = [
    <TabPanel key={TaskTabsTypes.MY} tabPanelId={TaskTabsTypes.MY}>
      <Tasks type={TaskTabsTypes.MY} key={TaskTabsTypes.MY} />
    </TabPanel>,
    <TabPanel key={TaskTabsTypes.OPEN} tabPanelId={TaskTabsTypes.OPEN}>
      <Tasks type={TaskTabsTypes.OPEN} key={TaskTabsTypes.OPEN} />
    </TabPanel>,
  ];

  return (
    <Tabs
      className="task-list-tabs"
      tabs={tabs}
      tabPanels={tabPanels}
      activeTab={activeTab}
      onTabChanged={(id) => push(`/tasks?active=${id}`)}
    />
  );
};
