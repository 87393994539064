import React from "react";
import { useInputPopup } from "../InputPopup";
import { Filter } from "@coworker/components";
import { useTranslation } from "@coworker/locales";
import useFormatter from "../../hooks/useFormatter";
import tracker from "../../helpers/tracker";
import { isEqual } from "lodash";
const _ = require("lodash");

const options = {
  time: [
    { data: 0, name: "thisWeekString", key: 0 },
    { data: 4, name: "fourWeeksString", key: 4 },
    { data: 8, name: "eightWeeksString", key: 8 },
  ],
  value: [
    {
      key: "turnover",
      name: "insightTurnoverString",
    },
    {
      key: "pieces",
      name: "insightPiecesLongString",
    },
  ],
  share: [
    {
      key: "HOME",
      name: "homeDepartmentString",
    },
    {
      key: "AWAY",
      name: "awayDepartmentString",
    },
  ],
  quantity: [
    {
      name: "mostPieces",
      key: "mostPieces",
      data: {
        order_by: "refill",
        order_by_asc: false,
      },
    },
    {
      name: "leastPieces",
      key: "leastPieces",
      data: {
        order_by: "refill",
        order_by_asc: true,
      },
    },
    {
      name: "highestEstTurnover",
      key: "highestTurnover",
      data: {
        order_by: "turnover",
        order_by_asc: false,
      },
    },
    {
      name: "lowestEstTurnover",
      key: "lowestTurnover",
      data: {
        order_by: "turnover",
        order_by_asc: true,
      },
    },
  ],
  home_sort: [
    {
      name: "hfbNumberString",
      key: "hfbNumber",
    },
    {
      name: "mostPieces",
      key: "mostPieces",
    },
    {
      name: "leastPieces",
      key: "leastPieces",
    },
    {
      name: "highestEstTurnover",
      key: "highestTurnover",
    },
    {
      name: "lowestEstTurnover",
      key: "lowestTurnover",
    },
  ],
  home_sort_value: [
    {
      key: "HOME",
      name: "homeDepartmentString",
    },
    {
      key: "AWAY",
      name: "awayDepartmentString",
    },
  ],
};

const timeName = (options, default_weeksBack, defaultCustomDate = {}) => {
  if (default_weeksBack === "custom") {
    const { startDate, endDate } = defaultCustomDate;
    return `${startDate} - ${endDate}`;
  }

  return options.time.find((option) => option.data === default_weeksBack)?.name;
};

const quantityName = (options, default_order_by, default_order_by_asc) => {
  const option = options.quantity.find(
    (option) =>
      option.data.order_by === default_order_by &&
      option.data.order_by_asc === default_order_by_asc
  )?.name;

  return option ?? "select2String";
};

const homeSortName = (options, default_home_sort) =>
  options.home_sort.find((option) => option.key === default_home_sort)?.name;

const homeSortValueName = (options, default_home_sort_value) =>
  options.home_sort_value.find(
    (option) => option.key === default_home_sort_value
  )?.name;

const quantityKey = (options, default_order_by, default_order_by_asc) =>
  options.quantity.find(
    (option) =>
      option.data.order_by === default_order_by &&
      option.data.order_by_asc === default_order_by_asc
  )?.key;

const hfbName = (hfb, t) => {
  if (hfb === "ALL" || !hfb) {
    return t("allHFBs2String");
  } else if (hfb.match(/(CL|OW|OT)/)) {
    return t(`hfb${hfb}String`);
  } else {
    return "HFB" + hfb;
  }
};

const valueName = (options, default_home_value) =>
  options.value.find((option) => option.key === default_home_value)?.name;
const shareName = (options, default_share) =>
  options.share.find((option) => option.key === default_share)?.name;

const Filters = ({
  onChangeInsightsParams = () => null,
  dataFetchParams = { weeksBack: 0 },
  withQuantity,
  activeHFB = [],
  level,
}) => {
  const { t } = useTranslation();
  const { formatDate } = useFormatter();
  const { getInput } = useInputPopup();
  const [data, setData] = React.useState();

  const default_weeksBack = dataFetchParams?.period?.weeksBack;
  const default_order_by = dataFetchParams?.deptFilters?.orderBy;
  const default_order_by_asc = dataFetchParams?.deptFilters?.orderByAsc;
  const default_hfb = dataFetchParams?.deptFilters?.hfb;
  const default_share = dataFetchParams?.deptFilters?.salesShare;
  const default_home_value = dataFetchParams?.home?.valueToShow;
  const default_home_sort = dataFetchParams?.home?.sortBy?.type ?? "hfbNumber";
  const default_home_sort_value = dataFetchParams?.home?.sortBy?.value;
  const defaultCustomDate = dataFetchParams?.period?.customDate;
  const defaultCustomGraphOption = dataFetchParams?.graph?.option;

  const selectedFilterValues = {
    time: {
      name: timeName(options, default_weeksBack, {
        startDate: formatDate(new Date(defaultCustomDate?.startDate)),
        endDate: formatDate(new Date(defaultCustomDate?.endDate)),
      }),
      data: default_weeksBack,
      active: !!activeHFB?.data?.length ? ["ALL", ...activeHFB?.data] : ["ALL"],
      ...({ ...defaultCustomDate, graphOption: defaultCustomGraphOption } ||
        {}),
    },
    value: {
      key: data?.value?.key ?? default_home_value,
      name: shareName(options, data?.value?.key ?? default_home_value),
    },
    share: {
      key: data?.share?.key ?? default_share,
      name: valueName(options, data?.share?.key ?? default_share),
    },
    quantity: {
      key: quantityKey(options, default_order_by, default_order_by_asc),
      name: quantityName(options, default_order_by, default_order_by_asc),
      order_by: default_order_by,
      order_by_asc: default_order_by_asc,
    },
    department: default_hfb,
    home_sort: {
      key: data?.home_sort?.key ?? default_home_sort,
      name: homeSortName(options, data?.home_sort?.key ?? default_home_sort),
    },
    home_sort_value: {
      key: data?.home_sort_value?.key ?? default_home_sort_value,
      name: homeSortValueName(
        options,
        data?.home_sort_value?.key ?? default_home_sort_value
      ),
    },
  };

  const getFilterOption = async () => {
    const filterData = await getInput("insightsFilters", {
      level,
      activeHFB,
      onChangeInsightsParams,
      options,
      dataFetchParams,
      defaults: selectedFilterValues,
    });

    if (filterData) {
      let updatedInsightsFilter = {};
      if (
        filterData?.share?.key &&
        filterData?.share?.key !== (data?.share?.key ?? default_share)
      ) {
        tracker.insights.trackChangeSecondLevelShare(filterData.share.key);
      }
      if (
        filterData?.value?.key &&
        filterData.value.key !== (data?.value?.key ?? default_home_value)
      ) {
        tracker.insights.trackChangeHomeValueToShow(filterData.value.key);
      }
      setData(filterData);
      if (!isEqual(filterData.time.data, selectedFilterValues.time.data))
        _.set(
          updatedInsightsFilter,
          ["period", "weeksBack"],
          filterData.time.data
        );
      if (filterData.time.data === "custom") {
        if (
          !isEqual(
            filterData.time.startDate,
            selectedFilterValues.time.startDate
          ) ||
          !isEqual(filterData.time.endDate, selectedFilterValues.time.endDate)
        ) {
          _.set(
            updatedInsightsFilter,
            ["period", "customDate", "startDate"],
            filterData.time.startDate || ""
          );
          _.set(
            updatedInsightsFilter,
            ["period", "customDate", "endDate"],
            filterData.time.endDate || ""
          );
        }
        if (
          !isEqual(
            filterData.time.graphOption,
            selectedFilterValues.time.graphOption
          )
        )
          _.set(
            updatedInsightsFilter,
            ["graph", "option"],
            filterData.time.graphOption
          );
      } else {
        _.set(
          updatedInsightsFilter,
          ["period", "customDate", "startDate"],
          null
        );
        _.set(updatedInsightsFilter, ["period", "customDate", "endDate"], null);
      }
      if (level === 1) {
        if (!isEqual(filterData.value.key, selectedFilterValues.value.key))
          _.set(
            updatedInsightsFilter,
            ["home", "valueToShow"],
            filterData.value.key
          );
        if (
          !isEqual(filterData.home_sort.key, selectedFilterValues.home_sort.key)
        )
          _.set(
            updatedInsightsFilter,
            ["home", "sortBy", "type"],
            filterData.home_sort.key
          );
        if (
          !isEqual(
            filterData.home_sort_value.key,
            selectedFilterValues.home_sort_value.key
          )
        )
          _.set(
            updatedInsightsFilter,
            ["home", "sortBy", "value"],
            filterData.home_sort_value.key
          );
      } else {
        if (
          !isEqual(
            filterData.quantity.order_by,
            selectedFilterValues.quantity.order_by
          )
        )
          _.set(
            updatedInsightsFilter,
            ["deptFilters", "orderBy"],
            filterData.quantity.order_by
          );
        if (
          !isEqual(
            filterData.quantity.order_by_asc,
            selectedFilterValues.quantity.order_by_asc
          )
        )
          _.set(
            updatedInsightsFilter,
            ["deptFilters", "orderByAsc"],
            filterData.quantity.order_by_asc
          );
        if (!isEqual(filterData.department, selectedFilterValues.department))
          _.set(
            updatedInsightsFilter,
            ["deptFilters", "hfb"],
            filterData.department
          );
        if (!isEqual(filterData.share.key, selectedFilterValues.share.key))
          _.set(
            updatedInsightsFilter,
            ["deptFilters", "salesShare"],
            filterData.share.key
          );
      }
      onChangeInsightsParams(updatedInsightsFilter);
    }
  };

  return (
    <Filter
      onClick={() => getFilterOption()}
      time={t(
        data?.time?.name ||
          timeName(options, default_weeksBack, {
            startDate: formatDate(new Date(defaultCustomDate?.startDate)),
            endDate: formatDate(new Date(defaultCustomDate?.endDate)),
          })
      )}
      quantity={t(
        data?.quantity?.name ||
          quantityName(options, default_order_by, default_order_by_asc)
      )}
      hfb={hfbName(default_hfb, t)}
      value={t(data?.value?.name || valueName(options, default_home_value))}
      share={t(data?.share?.name || shareName(options, default_share))}
      home_sort={t(
        data?.home_sort?.name || homeSortName(options, default_home_sort)
      )}
      show_home_sort_value={default_home_sort !== "hfbNumber"}
      home_sort_value={t(
        data?.home_sort_value?.name ||
          homeSortValueName(options, default_home_sort_value)
      )}
      level={level}
      notAll={data?.department !== "ALL" && default_hfb !== "ALL"}
    />
  );
};

export default Filters;
