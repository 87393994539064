import React from "react";
import { RoundCard } from "./RoundCard";
import { SubHeaderCard } from "../Components/SubHeaderCard";
import { Trans } from "@coworker/locales";
import styled from "styled-components";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { DIVISION_HASH, HEADER_COLOR_SCHEMA } from "../constants";
import { RoundCounts } from "../Types";

export interface DivisionCardProps {
  round: string;
  title: string;
  roundCounts: RoundCounts | undefined;
  loading: boolean;
}

const Container = styled.div`
  width: 100%;
`;

export const Round = ({
  round,
  title,
  roundCounts,
  loading,
}: DivisionCardProps) => {
  const { push } = useWorkspacesAction();
  const activeArea =
    localStorage.getItem("shoppingToolsAreaFilter") ?? "wholeStore";

  return (
    <Container>
      <SubHeaderCard
        title={<Trans>{title}</Trans>}
        fontSize={16}
        colorSchema={HEADER_COLOR_SCHEMA.GREY}
      />

      {(activeArea === "showroomOTW" || activeArea === "wholeStore") && (
        <RoundCard
          title={DIVISION_HASH.SHOWROOM?.transKey}
          currentValue={roundCounts?.showroom}
          onClick={() => push(`/shoppingtools/progress/showroom/${round}`)}
          loading={loading}
        />
      )}
      {(activeArea === "markethallOTW" || activeArea === "wholeStore") && (
        <RoundCard
          title={DIVISION_HASH.MARKETHALL?.transKey}
          currentValue={roundCounts?.markethall}
          onClick={() => push(`/shoppingtools/progress/markethall/${round}`)}
          loading={loading}
        />
      )}
      {(activeArea === "otherAreaCashline" || activeArea === "wholeStore") && (
        <RoundCard
          title={DIVISION_HASH.CASHLINE?.transKey}
          currentValue={roundCounts?.cashline}
          onClick={() => push(`/shoppingtools/progress/cashline/${round}`)}
          loading={loading}
        />
      )}
    </Container>
  );
};
