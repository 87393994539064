import React from "react";
import { RoomSettings } from "@coworker/types/lib/tasks/room_settings";
import { TaskCard } from "./TaskCard";
import { FullWidthButton } from "../../SkapaButton";
import { ArrowCounterClockWise } from "../Atoms/Icons/ArrowCounterClockWise";
import { useTranslation } from "@coworker/locales";
import {
  getPassedTimeAndTranslationKey,
  getPiecesTranslation,
  isOngoing,
} from "../tools";
import { Text } from "../Atoms/Text";
import { ListItemWrapper } from "../styles/styles";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";

interface CreateTaskCardProps {
  task: RoomSettings | undefined;
  roomName: string;
  pieces: number;
  isCurrentArticle: boolean;
  articleId: string;
  roomId: string;
}

export function CreateTaskCard({
  task,
  roomName,
  pieces,
  isCurrentArticle,
  articleId,
  roomId,
}: CreateTaskCardProps) {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();

  const headLine = `${roomName}: ${pieces} ${t(getPiecesTranslation(pieces))} ${
    isCurrentArticle ? t("selectedString") : ""
  }`;

  let subText = "";
  let buttonText = t("createString");

  let isOngoingTask = false;
  if (task) {
    const createdAt = new Date(task.created_at).toISOString();
    const timePassedAndKey = getPassedTimeAndTranslationKey(createdAt);
    isOngoingTask = isOngoing(task);

    let timePassed = `${timePassedAndKey.passedTime} ${t(
      timePassedAndKey.translationKey
    )}`;
    if (timePassedAndKey.translationKey === "lessThanDayString") {
      timePassed = t(timePassedAndKey.translationKey);
    }

    subText = `${t("lastCreatedString")}: ${
      isOngoingTask ? t("ongoingString") : timePassed
    }`;
    buttonText = isOngoingTask ? t("showTaskString") : t("createString");
  }

  const onClick = () => {
    if (isOngoingTask) {
      push(`/task/${task?.id || "NoTaskId"}`);
    } else {
      push(`/roomsettings/${roomId}/articles/${articleId}/createtask`);
    }
  };

  return (
    <>
      <TaskCard
        leftIcon={<ArrowCounterClockWise />}
        headLine={<Text text={headLine} bold />}
        subText={<Text text={subText} grey />}
        rightIcon={<></>}
      />
      <ListItemWrapper $hasBanner={false}>
        <FullWidthButton
          text={buttonText}
          onClick={onClick}
          type={isOngoingTask ? "secondary" : "primary"}
        />
      </ListItemWrapper>
    </>
  );
}
