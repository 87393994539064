import React from "react";
import SSRIcon from "@ingka/ssr-icon";
import searchIcon from "@ingka/ssr-icon/paths/magnifying-glass";
import styles from "./global-search.module.css";
import trackerHelper from "@coworker/app/src/helpers/tracker";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";

// This component is used in the MainHeader componentcon
const GlobalSearch = () => {
  const { push } = useWorkspacesAction();
  const handleClick = () => {
    trackerHelper.trackSearchButtonClick();
    push("/search");
  };
  return (
    <div className={styles["global-search"]} onClick={handleClick}>
      <SSRIcon paths={searchIcon} />
    </div>
  );
};

export default GlobalSearch;
