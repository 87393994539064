import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { PillShell } from "@coworker/components/src/components/Pill";
import useFormatter from "../../hooks/useFormatter";
import { Icon } from "@coworker/components";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import { usePeriodTimestamps } from "../InputPopup/UserFiltersRow/usePeriodTimestamps";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences.json";
import {
  defaultFilters,
  periodIsCustom,
  getPeriodFilter,
} from "../InputPopup/UserFiltersRow/filterChoices";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  justify-items: center;
  & > div:first-child {
    display: block;
    color: var(--grey600);
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    margin: 4px auto;
  }
`;

const PillWrapper = styled.div`
  height: 50px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const MessageWrapper = styled.div`
  padding-top: 10px;
  margin: 0 auto;
  display: flex;
`;

const MessageText = styled.span`
  margin-left: 5px;
  font-size: 14px;
  color: var(--warning);
`;

const StyledPill = styled(PillShell)`
  height: 26px;
  line-height: 26px;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid var(--grey100);
  background-color: white;

  & > span:nth-child(2) {
    margin: 0 43px;
  }

  & > span:first-child {
    ${({ noVotes }) =>
      noVotes && `color: var(--gray200); pointer-events: none;`}
  }
`;

const { MFAQ_PREFIX, USER_FILTERS } = profilePreferences;

export function VotePill({ question_id, initialCount, addVote }) {
  const [count, setCount] = React.useState(initialCount);

  const [filters] = useUserPreference(
    MFAQ_PREFIX + USER_FILTERS,
    defaultFilters
  );

  const [, endDate] = usePeriodTimestamps(filters);

  const isCustomPeriod = periodIsCustom(getPeriodFilter(filters));
  const endDateObj = new Date(endDate);
  const today = new Date();
  const isEndDateToday =
    endDateObj.getDate() !== today.getDate() ||
    endDateObj.getMonth() !== today.getMonth() ||
    endDateObj.getFullYear() !== today.getFullYear();

  const canVoteInPast = !isCustomPeriod
    ? true
    : isCustomPeriod && !isEndDateToday;

  React.useEffect(() => {
    setCount(initialCount);
  }, [initialCount]);

  const makeVoteHandler = (change) => () => {
    if (count + change < 0) return;
    setCount(count + change);
    addVote(question_id, change);
  };
  const { formatNumber } = useFormatter();
  return (
    <Container>
      <div>
        <Trans>voteCountString</Trans>
      </div>
      <PillWrapper>
        <StyledPill noVotes={!count}>
          <span
            data-testid="pill-downvote"
            onClick={canVoteInPast ? makeVoteHandler(-1) : undefined}
          >
            -
          </span>
          <span data-testid="pill-count">{formatNumber(count)}</span>
          <span
            data-testid="pill-upvote"
            onClick={canVoteInPast ? makeVoteHandler(1) : undefined}
          >
            +
          </span>
        </StyledPill>
      </PillWrapper>
      {!canVoteInPast && (
        <MessageWrapper>
          <Icon
            name="warning_triangle"
            size="18"
            family="icons"
            color="warning"
          />
          <MessageText>
            <Trans>cantUpvoteInThePastString</Trans>
          </MessageText>
        </MessageWrapper>
      )}
    </Container>
  );
}
