import React from "react";
import styled from "styled-components";
import { Header } from "@coworker/components";
import { HeaderSearchInput } from "../../features/searching/HeaderSearchInput";
import { Trans, useTranslation } from "@coworker/locales";
import ExportExcel from "../ExportExcel";
import { isRDTDevice } from "@coworker/reusable";
import { useFocus } from "../../hooks/useFocus";

const ExportWrapper = styled.div`
  & > div {
    margin-top: 10px;
  }
`;

export function MFAQHeader({
  onQuery,
  onCancelSearch,
  onBack,
  exportParams,
  mergeQuestion = false,
  cancelMergeQuestion,
}) {
  const { t } = useTranslation();
  const [showSearch, setShowSearch] = React.useState(mergeQuestion);
  const { setInputFocus, setInputBlur, inputRef } = useFocus();
  const [focus, setFocus] = React.useState(true);
  const [query, setQuery] = React.useState("");
  const hideExcelExport = window.hideExcelExport;
  const update = (string, shouldFocus) => {
    setQuery(string);
    setFocus(shouldFocus);
    if (shouldFocus) {
      setInputFocus();
    } else {
      setInputBlur();
    }
    onQuery(string);
  };

  const showExport = !isRDTDevice() && !hideExcelExport;
  const rightComponent = React.useMemo(
    () =>
      showExport ? (
        <ExportWrapper>
          <ExportExcel dataFetchParams={exportParams} type="mfaq" />
        </ExportWrapper>
      ) : null,
    // TODO: This should not need memo, as it is either null (= a stable reference) or a component, so wrapping a component in a memo = a "component" should only be wasted work?
    [exportParams, showExport]
  );

  return showSearch ? (
    <HeaderSearchInput
      noBottomMargin
      inputRef={inputRef}
      query={query}
      onChange={(e) => update(e.target.value, true)}
      onClose={() => {
        update("", true);
        onCancelSearch && mergeQuestion
          ? cancelMergeQuestion()
          : onCancelSearch();
      }}
      onCancel={() => {
        update("", false);
        setShowSearch(false);
        onCancelSearch && mergeQuestion
          ? cancelMergeQuestion()
          : onCancelSearch();
      }}
      focus={focus}
      placeholder={t("searchQuestionString")}
    />
  ) : (
    <Header
      onTapSearch={() => setShowSearch(true)}
      showSearch
      displayBackAsArrow
      title={<Trans>customerQuestionsString</Trans>}
      onBack={onBack}
      rightComponent={rightComponent}
    />
  );
}
