import { FilterOptionsKeys } from "../types/filterOptionsTypes";

type AppliedPillsType = {
  transKey: string;
  id: FilterOptionsKeys;
};

export const appliedPills: AppliedPillsType[] = [
  {
    transKey: "assignedToTaskFilterString",
    id: "assignedTo",
  },
  {
    transKey: "createdByString",
    id: "createdBy",
  },
  {
    transKey: "task_typeString",
    id: "taskType",
  },
  {
    transKey: "sortedByString",
    id: "sortedBy",
  },
  {
    transKey: "groupByTitleString",
    id: "groupBy",
  },
  {
    transKey: "location3String",
    id: "locations",
  },
];

type DropdownPillsType = {
  transKey: string;
  id: Extract<
    FilterOptionsKeys,
    "assignedTo" | "createdBy" | "taskType" | "sortedBy" | "locations"
  >;
};

export const dropdownPills: DropdownPillsType[] = [
  {
    transKey: "assignedToTaskFilterString",
    id: "assignedTo",
  },
  {
    transKey: "createdByString",
    id: "createdBy",
  },
  {
    transKey: "task_typeString",
    id: "taskType",
  },
  {
    transKey: "sortedByString",
    id: "sortedBy",
  },
  {
    transKey: "location3String",
    id: "locations",
  },
];

type CheckboxPillsType = {
  transKey: string;
  id: Partial<FilterOptionsKeys>;
};

export const checkBoxPills: CheckboxPillsType[] = [
  {
    transKey: "createdByMeString",
    id: "createdBy",
  },
];
