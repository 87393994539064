import React, { useContext } from "react";
import Avatar from "@coworker/app/src/components/Avatar/Avatar";
import { logEvent } from "@coworker/app/src/helpers/tracker";
import { useFixaUID } from "@coworker/app/src/core/auth/useLoggedInUser";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";

import TabNavigation from "./components/TabNavigation/TabNavigation";
import GlobalSearch from "./components/GlobalSearch/GlobalSearch";
import HejAppSwitcher from "./components/HejAppSwitcher/HejAppSwitcher";

import styles from "./main-header.module.css";
import HamburgerMenu from "./components/HamburgerMenu/HamburgerMenu";
import { FixaWindow } from "@coworker/reusable";
import { ModalAction, ModalContext } from "../../context/ModalContextProvider";
import { ModalType } from "../../context/modalType";
import { Profile } from "../../../home/components/Profile/Profile";
import NotificationsLink from "./components/NotificationsLink/NotificationsLink";
import trackerHelper from "@coworker/app/src/helpers/tracker";

const handleProfileLinkClick = (toggleModal: (action: ModalAction) => void) => {
  logEvent("ce:hej_profile_link_clicked");
  toggleModal({ type: ModalType.Profile });
};

const MainHeader = () => {
  const fixaUid = useFixaUID();
  const { push } = useWorkspacesAction();
  const hideForGermany = (window as FixaWindow).hideForGermanyUsers;

  const { toggleModal } = useContext(ModalContext);

  return (
    <div className={styles["main-header"]}>
      <div className={styles["left-side"]}>
        <>
          <HamburgerMenu />
          <h1 onClick={() => push("/")}>Fixa</h1>
          <TabNavigation />
        </>
      </div>
      <div className={styles["right-side"]}>
        <GlobalSearch />
        <div
          onClick={() => {
            handleProfileLinkClick(toggleModal);
            trackerHelper.trackProfileButtonClick();
          }}
          className={styles["profile-link"]}
        >
          <Avatar fixaUid={fixaUid} big={false} />
        </div>
        <NotificationsLink />
        <Profile />
        {/* @ts-ignore */}
        {!hideForGermany && <HejAppSwitcher />}
      </div>
    </div>
  );
};

export default MainHeader;
