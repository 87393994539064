//@ts-ignore
import { mfaqServiceBaseUrl } from "@coworker/functions/src/triggers/cloud-services.helper";
import { ApiEndpoints, BackendServices } from "./EndpointsTypes";
import { getEnvironmentId, isChineseEnvironment } from "@coworker/reusable";
import { perform } from "../../../hooks/API/tasks.service.helper";

const isChina = isChineseEnvironment();

const MFAQ_SERVICE_URL = mfaqServiceBaseUrl(getEnvironmentId(), isChina);

export async function postRequest<TBody, TResponse>(
  body: TBody,
  path: ApiEndpoints,
  service: BackendServices
) {
  return (await internalRequest(body, path, service, "POST")) as TResponse;
}

export async function putRequest<TBody, TResponse>(
  body: TBody,
  path: ApiEndpoints,
  service: BackendServices
) {
  return (await internalRequest(body, path, service, "PUT")) as TResponse;
}

async function internalRequest<TBody, TResponse>(
  body: TBody,
  path: ApiEndpoints,
  service: BackendServices,
  requestType: string
) {
  const response = await baseRequest(body, path, service, requestType);
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.includes("application/json")) {
    const data = await response.json();
    return data as TResponse;
  } else {
    const responseText = await response.text();
    return responseText as TResponse;
  }
}

async function baseRequest<TBody>(
  body: TBody,
  path: ApiEndpoints,
  _service: BackendServices,
  requestType: string
) {
  const url = `${MFAQ_SERVICE_URL}/${path}`;

  const response = await fetch(url, {
    method: requestType,
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response;
}

export async function postToMFAQService<TBody>(
  path: ApiEndpoints,
  bodyData: TBody,
  callback: Function,
  responseType: string
) {
  const url = `${MFAQ_SERVICE_URL}${path}`;

  await perform("POST", url, bodyData, callback, responseType);
}
