import React from "react";
import { callTasksService } from "./API/tasks.service.helper";
import { getFirebaseDownloadUrl } from "./firebase.storage.helper";
import { firebase } from "@coworker/common/config/firebase";
import { TASKS_SERVICE_URL } from "./API/tasks.service.helper";
import { CloudProvider } from "../constants/cloudProvider";
import { getTokenAsync } from "./API/getAuthToken";

export type ImageDetails = {
  folder: string; // tasks-service will use "tasks" here
  file_name: string;
  src?: string; // If the image is not to be fetched from storage, because we already know the source URL.
  orientation?: number;
  provider?: string; // gcp or alicloud or firebase
};

export function getThumbnailPathFromSize(folder = "", name = "", size = "") {
  switch (size) {
    case "small":
      return `${folder}/resized/100x100/${name}`;
    case "medium":
      return `${folder}/resized/640x640/${name}`;
    case "large":
      return `${folder}/resized/1024w/${name}`;
    default:
      return `${folder}/${name}`;
  }
}

// @returns {string} url to be used with <img src={url}>
export function getFirebaseImage(image: ImageDetails, size = "large") {
  const path = getThumbnailPathFromSize(image.folder, image.file_name, size);
  return getFirebaseDownloadUrl(path).catch(() => {
    // if loading thumbnail fails, we load the full picture
    // Example task where fallback is needed for all the oldest note images: http://localhost:3000/task/YeDWFLd8SY3KqZk86Zhe
    return getFirebaseDownloadUrl(
      getThumbnailPathFromSize(image.folder, image.file_name)
    );
  });
}

// Used for firebase only (and temporary for showing the loading spinner?? maybe not needed there)
export function getCleanFileNameWithTimestamp(originalFileName: string) {
  const label = originalFileName
    .replace(/[^a-z0-9.\s-]/gi, "")
    .trim()
    .replace(/\s+/g, "_");
  const timestamp = new Date().getTime();
  return [`${timestamp}_${label}`, timestamp];
}

export async function putFirebaseImage(
  folder: string,
  fileName: string,
  file: File
) {
  const storage = firebase.storage().ref();
  const imageRef = storage.child(`images/${folder}/${fileName}`);
  return await imageRef.put(file);
}

export async function postHelper(url = "", body: FormData) {
  const token = await getTokenAsync(true);
  const params = {
    method: "POST",
    body,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(url, params);
  if (!response.ok) throw new Error("Could not upload image");
  return JSON.parse(await response.text());
}

export async function putImageToTasksService(file: File) {
  const formData = new FormData();
  formData.append("image", file);
  // This works. But maybe rework to use callTasksService instead.

  return await postHelper(
    `${TASKS_SERVICE_URL}/v1/storage/image-upload`,
    formData
  );
}

// @returns {string[]} url to be used with <img src={url}>
export async function getSignedUrls(images: ImageDetails[], size = "large") {
  const filenames = images.map((item) => item?.file_name).join(",");
  const path = `storage/get_signed_urls?size=${size}&filenames=${filenames}`;
  return (await callTasksService(path))?.urls;
}

export function useRemoteImages(images: ImageDetails[], size = "large") {
  const [urls, setUrls] = React.useState<string[]>([]);
  // Track and avoid React error for setState on unmounted component.
  const mounted = React.useRef(true);
  React.useEffect(
    () => () => {
      mounted.current = false;
    },
    [urls]
  );

  React.useEffect(() => {
    if (!images || images[0]?.src) return;

    switch (images?.[0]?.provider) {
      case CloudProvider.GCP:
      case CloudProvider.ALICLOUD:
        getSignedUrls(images, size).then((found: string[]) => {
          if (mounted.current) setUrls(found);
        });
        break;

      case CloudProvider.FIREBASE:
      default:
        Promise.all(
          images.map((img: ImageDetails) => getFirebaseImage(img, size))
        ).then((found: string[]) => {
          if (mounted.current) setUrls(found);
        });
    }
  }, [images, size]);

  return urls;
}
