import React from "react";
import { OverflowCarousel } from "@ingka/carousel";
import Image from "@ingka/image";
import Text from "@ingka/text";
import { TutorialItemType, Tutorials } from "../../constants/tutorials";
import { Trans } from "@coworker/locales";
import { TutorialsTypes } from "../../enums/tutorialsTypes";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import styles from "./tutorials.module.css";
import trackerHelper from "../../../../../coworker-app/src/helpers/tracker";
import { FixaWindow } from "@coworker/reusable";

interface TutorialsContentProps {
  id: string;
}

export const TutorialsContent: React.FC<TutorialsContentProps> = ({ id }) => {
  const { push } = useWorkspacesAction();
  const handleTutorialItemClick = (tutorialType: TutorialsTypes) => {
    push(`/tutorials/${tutorialType}`);
    trackerHelper.trackTutorials(tutorialType);
  };

  const hideForGermanyUsers = (window as FixaWindow).hideForGermanyUsers;

  const filteredTutorials = hideForGermanyUsers
    ? Tutorials.filter(
        (tutorial) =>
          tutorial.type !== TutorialsTypes.PQ_SUBMIT &&
          tutorial.type !== TutorialsTypes.PQ_PICKUP
      )
    : Tutorials;

  const tutorialsItemArray: any = filteredTutorials.map(
    (tutorial: TutorialItemType) => {
      return (
        <div
          className={styles["tutorials-content"]}
          onClick={() => handleTutorialItemClick(tutorial.type)}
        >
          <Image
            className={styles["tutorials-image"] || ""}
            src={tutorial.imageSrc}
          />
          <div className={styles["overlay"]}>
            <Text className={styles["overlay-text"] || ""} tagName="h3">
              <Trans>{tutorial.title}</Trans>
            </Text>
          </div>
        </div>
      );
    }
  );
  return (
    <OverflowCarousel id={id} controlBtnType="primary">
      {tutorialsItemArray.map((item: any, i: any) => {
        return React.cloneElement(item, { key: i });
      })}
    </OverflowCarousel>
  );
};
