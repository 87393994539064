import React from "react";
import Modal, { ModalFooter, ModalHeader, Sheets } from "@ingka/modal";
import { Groups } from "../../types/groups";
import { ModalTypes } from "../../types/views";
import { useTranslation } from "@coworker/locales";
import { FullWidthButton } from "../../../SkapaButton";
import { Articles } from "../../types/article";
import { InputPopupContainer } from "../../styles/styles";
import ListView, { ListViewItem } from "@ingka/list-view";
import { useQueryClient } from "@tanstack/react-query";
import { handleArticleUpdateWithNewArticleNumber } from "./GroupModal.helper";
import { logEvent } from "../../../../helpers/tracker";

const dummyId = "dummyId";

interface AddToGroupModalProps {
  modalVisibility: boolean;
  groups: Groups;
  selectedArticles: Articles;
  allArticles: Articles;
  onTriggerNewModal: (modalType: ModalTypes) => void;
}

export function AddToGroupModal({
  modalVisibility,
  groups,
  selectedArticles,
  allArticles,
  onTriggerNewModal,
}: AddToGroupModalProps) {
  const [selectedGroupId, setSelectedGroupId] = React.useState<string>("");

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return (
    <Modal
      visible={modalVisibility}
      handleCloseBtn={() => {
        onTriggerNewModal(ModalTypes.KEEP_SELECTION);
      }}
    >
      <Sheets
        labelledById="add-to-group-modal"
        size={"small"}
        header={
          <ModalHeader
            ariaCloseTxt="Close"
            title={`${t("selectGroupString")}`}
          />
        }
        footer={
          <ModalFooter>
            <FullWidthButton
              disabled={!selectedGroupId}
              text={t("confirmString")}
              type={"primary"}
              onClick={async () => {
                for await (const article of selectedArticles) {
                  handleArticleUpdateWithNewArticleNumber(
                    article,
                    selectedGroupId === dummyId ? "" : selectedGroupId,
                    allArticles,
                    async () => {
                      await queryClient.invalidateQueries([
                        "articlesByRoom",
                        { id: article.roomId },
                      ]);
                    }
                  );
                }
                setSelectedGroupId("");
                onTriggerNewModal(ModalTypes.DEFAULT_VIEW);
                logEvent("ce:articles_selected_addtogroup");
              }}
              data
            />
          </ModalFooter>
        }
      >
        <InputPopupContainer>
          <ListView id={"group-selections"} size={"small"}>
            {groups.map((group) => (
              <ListViewItem
                key={group.id}
                onChange={() => {
                  setSelectedGroupId(group.id);
                }}
                title={group.name}
                control={"radiobutton"}
              />
            ))}
          </ListView>
        </InputPopupContainer>
      </Sheets>
    </Modal>
  );
}
