import React from "react";
import { updateTask } from "../../services/task.service";
import activityTypes from "@coworker/functions/src/enums/activityTypes";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import { useStoreId, useUserId } from "../../core/auth/useLoggedInUser";
import { useTaskRefresher } from "../useWatchedTasks";

export const useReopenTask = (task, taskId, config) => {
  const uid = useUserId();
  const storeId = useStoreId();

  // const { undo, afterUndo = () => {} } = config;
  const refreshTask = useTaskRefresher();

  const reopenTask = React.useCallback(async () => {
    const actionId = `reopen-${new Date().getTime()}`;

    const data = {
      action_id: actionId,
      last_editor_id: uid,
      activity_type: activityTypes.REOPEN,
      // The onTaskUpdate trigger also deletes undo_action_id, but in the case that this backend change has not propagated to the frontend we must make sure to also signal this from the frontend!
      undo_action_id: "",
      state: task.assigned_user_id
        ? taskStateOptions.ASSIGNED
        : taskStateOptions.UNASSIGNED,
    };

    await updateTask(data, task.id, task.task_type, storeId);
    refreshTask(task.id, data, task);

    return true;

    // TODO missing undo statement
    // if (!undo) return;
    // afterUndo();
  }, [uid, task, refreshTask, storeId]);

  return {
    call: reopenTask,
  };
};
