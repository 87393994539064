import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import useFormatter from "../../hooks/useFormatter";
import tracker from "../../helpers/tracker";
import { Trans } from "@coworker/locales";
import MultipleRefillGraphs from "./MultipleRefillGraphs";
import useInsightsGraphData from "./useInsightsGraphData";
import {
  getSalesWeekDatesFromDate,
  getSalesWeekFromDate,
  getCurrentYear,
} from "./helpers";
const _ = require("lodash");

dayjs.extend(require("dayjs/plugin/weekday"));

const CardSubtitle = styled.div`
  color: var(--grey900);
  font-size: 11px;
  font-weight: normal;
`;

const GraphGroupHeader = styled.div`
  margin-left: 22px;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
`;

const GraphCardWrapper = ({ dataFetchParams, onChangeInsightsParams }) => {
  const {
    start_date,
    end_date,
    graph: { option: graphOption },
  } = dataFetchParams;

  const {
    weeksBack,
    primarySeries,
    totalLabelText,
    xLabels,
    barWidths,
    reformat,
    todaysIndex,
    primaryTotal,
    secondaryTotal,
    weekdayLabels,
    error,
    loading,
  } = useInsightsGraphData(dataFetchParams);

  function createBarObject(
    key,
    label,
    formattedPrimaryValue,
    primaryBarWidth,
    secondaryBarWidth,
    isHighlighted,
    isPartial
  ) {
    return {
      key,
      label,
      formattedPrimaryValue,
      primaryBarWidth,
      secondaryBarWidth,
      isHighlighted,
      isPartial,
    };
  }

  function returnWeeksLabel(label) {
    return (
      <>
        <Trans>weekAbbreviationString</Trans>
        {label}
      </>
    );
  }

  const { formatDate } = useFormatter();

  const createGraphGroupHeader = React.useCallback(
    (week, weekStartDate, weekEndDate) => {
      const startDateDiff = dayjs(start_date).diff(weekStartDate);
      const endDateDiff = dayjs(end_date).diff(weekEndDate);

      const groupStartDate = startDateDiff < 0 ? weekStartDate : start_date;
      const groupEndDate = endDateDiff > 0 ? weekEndDate : end_date;

      const weekdayStartLabel =
        weekdayLabels[dayjs(groupStartDate).add(1, "day").weekday()];
      const weekdayEndLabel =
        weekdayLabels[dayjs(groupEndDate).add(1, "day").weekday()];

      return (
        <GraphGroupHeader>
          <Trans>weekString</Trans> {week} -{" "}
          {formatDate(new Date(groupStartDate))} -{" "}
          {formatDate(new Date(groupEndDate))} ({weekdayStartLabel} -{" "}
          {weekdayEndLabel})
        </GraphGroupHeader>
      );
    },
    [end_date, formatDate, start_date, weekdayLabels]
  );

  const [formattedStartDate, formattedEndDate] = React.useMemo(() => {
    return [formatDate(new Date(start_date)), formatDate(new Date(end_date))];
  }, [start_date, end_date, formatDate]);

  // group days by week if custom filter is active and graph option is set to days
  const graphGroups = React.useMemo(() => {
    const graphGroups = [];
    if ((weeksBack === "custom" && graphOption === "DAY") || weeksBack === 0) {
      const days = primarySeries.length;
      for (let i = 0; i < days; i++) {
        const currentDate = dayjs(start_date).add(i, "day");
        const currentDateWeek = getSalesWeekFromDate(currentDate);
        const currentYear = dayjs(currentDate).format("YYYY");

        const weekIndex = graphGroups.findIndex(
          ({ week, year }) => week === currentDateWeek && year === currentYear
        );

        const isHighlighted = i === todaysIndex;
        const [primaryBarWidth, secondaryBarWidth] = barWidths[i];
        if (weekIndex === -1) {
          const [weekStartDate, weekEndDate] =
            getSalesWeekDatesFromDate(currentDate);

          graphGroups.push({
            Header: createGraphGroupHeader(
              currentDateWeek,
              weekStartDate,
              weekEndDate
            ),
            week: currentDateWeek,
            year: currentYear,
            bars: [],
          });
        }

        graphGroups[graphGroups.length - 1].bars.push(
          createBarObject(
            currentDate,
            typeof xLabels[i] === "string"
              ? formatDate(xLabels[i])
              : xLabels[i],
            reformat(Math.floor(primarySeries[i])),
            primaryBarWidth,
            days < 8 && secondaryBarWidth,
            isHighlighted
          )
        );
      }
      return graphGroups;
    }

    // check is start and end date are full weeks for custom
    let partialFirstWeek = null;
    let partialLastWeek = null;
    if (weeksBack === "custom") {
      const [startOfFirstWeek, endOfLastWeek] = getSalesWeekDatesFromDate(
        start_date,
        end_date
      );

      partialFirstWeek = startOfFirstWeek !== start_date;
      partialLastWeek = endOfLastWeek !== end_date;
    }

    // get weeks
    const bars = xLabels.map((label, i) => {
      const [primaryBarWidth, secondaryBarWidth] = barWidths[i];
      const currentDateWeek = getSalesWeekFromDate();
      const currentYear = getCurrentYear();
      const [weekLabel, yearLabel] = label?.split?.("|") ?? [label];
      return createBarObject(
        `${i}-${weekLabel}-${yearLabel}`,
        returnWeeksLabel(weekLabel),
        reformat(Math.floor(primarySeries[i])),
        primaryBarWidth,
        secondaryBarWidth,
        Number(weekLabel) === currentDateWeek &&
          Number(yearLabel) === currentYear,
        (i === 0 && partialFirstWeek) ||
          (i === xLabels.length - 1 && partialLastWeek)
      );
    });
    graphGroups.push({ bars });

    return graphGroups;
  }, [
    primarySeries,
    weeksBack,
    graphOption,
    start_date,
    end_date,
    reformat,
    barWidths,
    todaysIndex,
    xLabels,
    createGraphGroupHeader,
    formatDate,
  ]);

  const showPartialWarning = React.useMemo(() => {
    return graphGroups.reduce((bool, { bars }) => {
      return bool || bars.map(({ isPartial }) => isPartial).includes(true);
    }, false);
  }, [graphGroups]);

  const showTotalLabel = React.useMemo(() => {
    return (
      weeksBack === 0 ||
      (weeksBack === "custom" &&
        graphOption === "DAY" &&
        primarySeries.length === 7)
    );
  }, [weeksBack, graphOption, primarySeries]);

  const onClick = () => {
    const graph_type =
      dataFetchParams?.graph?.type === "turnover" ? "pieces" : "turnover";
    tracker.insights.store.trackToggle("graph", graph_type);
    const updatedInsightsFilter = {};
    _.set(updatedInsightsFilter, ["graph", "type"], graph_type);
    onChangeInsightsParams(updatedInsightsFilter);
  };

  const title = (
    <>
      <p>
        {dataFetchParams?.graph?.type === "turnover" ? (
          <Trans>estimatedTurnoverString</Trans>
        ) : (
          <Trans>piecesString</Trans>
        )}
      </p>
      <CardSubtitle>
        <Trans>calculatedString</Trans>
      </CardSubtitle>
    </>
  );
  const actionText =
    dataFetchParams?.graph?.type === "turnover" ? (
      <Trans>viewPiecesString</Trans>
    ) : (
      <Trans>viewEstTurnoverString</Trans>
    );

  return (
    <MultipleRefillGraphs
      title={title}
      actionText={actionText}
      onClick={loading ? null : onClick}
      loading={loading}
      formattedStartDate={formattedStartDate}
      formattedEndDate={formattedEndDate}
      totalLabelText={
        showTotalLabel && totalLabelText(primaryTotal - secondaryTotal)
      }
      primaryTotal={reformat(primaryTotal)}
      graphGroups={graphGroups}
      warning={showPartialWarning && <Trans>notCompleteWeekWarning</Trans>}
      error={error}
    />
  );
};

export default GraphCardWrapper;
