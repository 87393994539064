import React from "react";
import styled from "styled-components";
import { IKEAlogo } from "@coworker/components";
import { LoginComponent } from "./LoginComponent";
import posed from "react-pose";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: var(--blue);
  padding: 40px 0;
  height: calc(100% - 80px);
`;
const LogoSpace = styled.div`
  display: flex;
  margin-top: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Animate = posed.div({
  closed: { opacity: 0 },
  open: { opacity: 1, delay: 300 },
});

export const SplashView = () => (
  <Container data-testid="splashScreen">
    <LogoSpace>
      <IKEAlogo splash="Fixa" />
    </LogoSpace>
    <Animate initialPose="closed" pose="open">
      <LoginComponent />
    </Animate>
  </Container>
);
