import React from "react";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import { areaOptionsList, periodOptionsList } from "../constants";
import { FiltersOptions } from "./FiltersOptions";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { useLocation } from "react-router-dom";

const FilterAreaOptionsWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const FiltersPopup = () => {
  const [activePeriod, setActivePeriod] = React.useState(
    localStorage.getItem("shoppingToolsPeriodFilter") ?? "today"
  );
  const [activeArea, setActiveArea] = React.useState(
    localStorage.getItem("shoppingToolsAreaFilter") ?? "wholeStore"
  );
  const { t } = useTranslation();
  const location = useLocation();
  const previousUrl = location.state?.stacks[0][0].path;

  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("shoppingToolsHeaderString"),
        actions: [],
      }}
      noPadding
    >
      {previousUrl === "/shoppingtools" && (
        <FiltersOptions
          id="period"
          title="periodString"
          list={periodOptionsList(activePeriod)}
          setState={setActivePeriod}
          showPeriod
        />
      )}
      <FilterAreaOptionsWrapper>
        <FiltersOptions
          id="area"
          title="areaString"
          list={areaOptionsList(activeArea)}
          setState={setActiveArea}
        />
      </FilterAreaOptionsWrapper>
    </FullScreenPopup>
  );
};
