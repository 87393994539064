import React from "react";
import styled from "styled-components";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import {
  DetailSection,
  DetailSectionLeft,
  SectionTitle,
  SectionText,
} from "./_common";
import { Trans } from "@coworker/locales";
import tracker from "../../../helpers/tracker";
import { ModalContext } from "@coworker/apprestructured/src/layout/context/ModalContextProvider";
import { ModalType } from "@coworker/apprestructured/src/layout/context/modalType";

const LinkText = styled.div`
  color: var(--new);
  cursor: pointer;
`;

export default function RepeatableChild({ task }) {
  const { push } = useWorkspacesAction();
  const { modalState, toggleModal } = React.useContext(ModalContext);

  if (!task.repeatable_task_id) return null;
  return (
    <DetailSection
      onClick={() => {
        tracker.plannedTasks.linkToParent();
        push(`/task/${task.repeatable_task_id}`);
        if (modalState.isTaskDetailSheetOpen) {
          toggleModal({ type: ModalType.TaskDetail });
        }
      }}
    >
      <DetailSectionLeft>
        <SectionTitle>
          <Trans>wasScheduledString</Trans>
        </SectionTitle>
        <SectionText>
          <LinkText>
            <Trans>viewScheduleDetails</Trans>
          </LinkText>
        </SectionText>
      </DetailSectionLeft>
    </DetailSection>
  );
}
