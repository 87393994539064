import { useQuery } from "@tanstack/react-query";
import { getArticlesByRoomId } from "../services/rooms.service";

export const useArticlesByRoomId = (roomId: string | undefined) => {
  return useQuery({
    queryKey: ["articlesByRoom", { id: roomId }],
    queryFn: () => getArticlesByRoomId(roomId || ""),
    enabled: !!roomId,
  });
};
