import Text from "@ingka/text";
import { Trans } from "@coworker/locales";

export const QuickActionsTitle = () => {
  return (
    <Text tagName="h2" headingSize="s">
      <Trans>quickActionsString</Trans>
    </Text>
  );
};
