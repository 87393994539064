import React from "react";
import CommercialMessage from "@ingka/commercial-message";
import { useLatestSalesDate } from "../hooks/useEndDateSales";
import styled from "styled-components";
import { formatEDSDate } from "../../Insights/helpers";

const EDSMessage = styled(CommercialMessage)`
  font-size: 12px;
  border-radius: 2px;
  color: var(--black);
  background-color: var(--grey100);
`;

export default function EndDateSalesDate({
  articleId,
  countryId,
  storeId,
}: {
  articleId: string;
  countryId: string;
  storeId: string;
}) {
  const { loading, latestSalesDate } = useLatestSalesDate(articleId, storeId);

  return !loading ? (
    <>
      {!!latestSalesDate && (
        <EDSMessage
          message={`${
            new Date(latestSalesDate) > new Date() ? "EDS" : "PEDS"
          } ${formatEDSDate(latestSalesDate, countryId)}`}
        />
      )}
    </>
  ) : (
    <></>
  );
}
