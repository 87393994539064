import React from "react";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { textForStockItemsAmount } from "../../features/stock";
import { useTooltip } from "../../components/Tooltip";
import { useTranslation, Trans } from "@coworker/locales";
import { formatProductIdWithDots, formatWithCommas } from "@coworker/reusable";
import { parseProduct } from "../../services/products.service";
import { useMFSLocations } from "../../services/locations.service";
import { useAvailableElevatedStock } from "../../hooks/useAvailableElevatedStock";
import { useWorkspacesState } from "../../hooks/useWorkspaces";
import { LocationTag, Tag } from "../../components/LocationTag";
import { PageStructure } from "../../core/components/PageStructure";
import { useCachedBasicProduct } from "../../services/products.service";

const Container = styled.div`
  background: var(--grey100);
`;

const StockBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--success);
  color: var(--white);
  height: 51px;
  padding: 0 28px;
  & span:first-child {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
  }
  & span:nth-child(2) {
    font-size: 14px;
    line-height: 1.71;
  }
  ${({ noStock }) =>
    noStock &&
    css`
      background: var(--error);
      color: var(--white);
    `}
  ${({ noInfo }) =>
    noInfo &&
    css`
      background: var(--grey500);
      color: var(--white);
    `}
`;

const ItemContainer = styled.div`
  background: var(--white);
  margin-top: 10px;
`;

const ItemInfo = styled.div`
  display: flex;
  align-items: center;
  min-height: 110px;
  & > img {
    width: 73px;
    height: 73px;
    object-fit: contain;
    margin-left: 28px;
    margin-right: 20px;
  }
  & > div {
    h1 {
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--grey900);
    }
    h2 {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: var(--grey700);
    }
  }
`;

const Table = styled.div`
  display: flex;
  justify-content: space-between;
  & > div:first-child > * {
    padding-left: 28px;
  }
  & > div:last-child {
    flex-grow: unset;
  }
  & > div:last-child > * {
    padding-right: 28px;
  }
  & > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    & > div:first-child {
      height: 38px;
      padding-top: 11px;
      padding-bottom: 11px;
      font-size: 14px;
      font-weight: bold;
      color: var(--grey900);
      border-bottom: 1px solid var(--grey150);
      display: flex;
      align-items: center;
    }
    & > div:not(:first-child) {
      height: 18px;
      padding-top: 14px;
      padding-bottom: 14px;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: var(--grey700);
    }
  }
`;

const ProductInformation = styled.div`
  text-transform: capitalize;
`;

const SGFContainer = styled.div`
  margin-top: 10px;
  background: var(--white);
`;

function StockPerGroup({ basicProduct, salesLocations, itemSgfLocations }) {
  const { showTooltip } = useTooltip();
  const { t } = useTranslation();

  const hClick = (e) => showTooltip(e, <LocationTag type="home" showText />);
  const pClick = (e) => showTooltip(e, <LocationTag type="primary" showText />);

  return (
    <React.Fragment>
      <ItemContainer>
        {basicProduct.name && (
          <ItemInfo>
            <img src={basicProduct.smallImage} alt={t("product2String")} />
            <ProductInformation>
              <h1>{basicProduct.name}</h1>
              <h2>{formatWithCommas(basicProduct.descriptives)}</h2>
              <h2>{formatProductIdWithDots(basicProduct.id)}</h2>
            </ProductInformation>
          </ItemInfo>
        )}
        <Table>
          <div>
            <div>
              <span>
                <Trans>salesString</Trans>
              </span>
            </div>
            {salesLocations.map(
              ({ storeLocation, isHomeBase, isHome, isPrimary }) => (
                <div key={storeLocation}>
                  {storeLocation}
                  {(isHomeBase || isHome) && <Tag onClick={hClick}>H</Tag>}
                  {isPrimary && <Tag onClick={pClick}>P</Tag>}
                </div>
              )
            )}
          </div>
          <div>
            <div>
              <span>
                <Trans>publicString</Trans>
              </span>
            </div>
            {salesLocations.map(({ locationDescription, storeLocation }) => (
              <div key={storeLocation}>{locationDescription}</div>
            ))}
          </div>
          <div>
            <div>
              <span>
                <Trans>sizeString</Trans>
              </span>
            </div>
            {salesLocations.map(({ quantity, storeLocation }) => (
              <div key={storeLocation}>{quantity}</div>
            ))}
          </div>
        </Table>
      </ItemContainer>
      {itemSgfLocations.length > 0 && (
        <SGFContainer>
          <Table>
            <div>
              <div>
                <Trans>sgfString</Trans>
              </div>
              {itemSgfLocations.map(({ location }) => (
                <div key={location}>{location}</div>
              ))}
            </div>
            <div>
              <div>
                <Trans>qtyString</Trans>
              </div>
              {itemSgfLocations.map(({ location, quantity }) => (
                <div key={location}>{quantity}</div>
              ))}
            </div>
          </Table>
        </SGFContainer>
      )}
    </React.Fragment>
  );
}

function StockLocations({ available, locationGroups, productsMap }) {
  return (
    <React.Fragment>
      <StockBadge noStock={available === 0} noInfo={"" === available}>
        <span>{textForStockItemsAmount(available)}</span>
        <span>{available}</span>
      </StockBadge>
      {locationGroups?.map((group) => {
        const short_id = parseProduct(group.productId || group.id)[0];
        const basicProduct = productsMap?.[short_id] || {};
        return (
          <StockPerGroup
            key={short_id}
            basicProduct={basicProduct}
            salesLocations={group.itemSalesLocations || []}
            itemSgfLocations={group.itemSgfLocations || []}
          />
        );
      })}
    </React.Fragment>
  );
}

function short_ids(list) {
  if (!list?.length) return "";
  return list.map(({ productId }) => parseProduct(productId)[0]).join(",");
}

export function PIPStockView() {
  const { full_id } = useParams();
  const { available } = useAvailableElevatedStock(full_id);
  const [short_id, type] = parseProduct(full_id);
  const { loading, data: locationGroups } = useMFSLocations(short_id, type);
  const ids = React.useMemo(() => short_ids(locationGroups), [locationGroups]);
  // TODO remove later after making sure replacement works
  // const { data: productsMap, loading: loadingProducts } = useCallInternal(
  //   PRODUCT_MAPPER,
  //   { ids, store },
  //   { blockRequest: loading || !store || !ids }
  // );
  const productBasics = useCachedBasicProduct(ids);

  const productsMap = {
    [ids]: productBasics,
    loadingProducts: false,
  };
  const { navigationState } = useWorkspacesState();

  return (
    <Container>
      <PageStructure
        loading={loading || productsMap.loadingProducts || !locationGroups?.map}
        title={<Trans>stock3String</Trans>}
        subTitle={navigationState?.title}
      >
        <StockLocations
          locationGroups={locationGroups}
          available={available}
          productsMap={productsMap}
        />
      </PageStructure>
    </Container>
  );
}
