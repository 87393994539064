import { getTokenAsync } from "../../../hooks/API/getAuthToken";
import { makeHeaders } from "../tools";
import { Groups, PartialGroup } from "../types/groups";
import { ROOM_SETTINGS_SERVICE_URL } from "./service.helper";

const BASE_URL = ROOM_SETTINGS_SERVICE_URL + "/groups";

export const addGroup = async (group: PartialGroup) => {
  const token = await getTokenAsync(true);

  const response = await fetch(BASE_URL, {
    method: "POST",
    headers: makeHeaders(token),
    body: JSON.stringify(group),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  return (await response.text()) as string;
};

export const getGroupsByRoom = async (roomId: string) => {
  try {
    const token = await getTokenAsync(true);
    const response = await fetch(`${BASE_URL}/rooms/${roomId}`, {
      method: "GET",
      headers: makeHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const groups = (await response.json()) as Groups;
    return groups;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return [];
};

export const updateGroup = async (groupId: string, group: PartialGroup) => {
  const token = await getTokenAsync(true);

  const url = `${BASE_URL}/${groupId}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: makeHeaders(token),
    body: JSON.stringify(group),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  return;
};

export const deleteGroupById = async (groupId: string) => {
  const token = await getTokenAsync(true);

  const response = await fetch(`${BASE_URL}/${groupId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  return;
};
