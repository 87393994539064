import React from "react";
import { SelectedTasksContext } from "../components/SelectedTasksProvider";
import trackerHelper from "../helpers/tracker";

export function useMultiSelect() {
  const [list, setList] = React.useState([]);
  const [active, setActive] = React.useState(false);
  const checkIfSelected = (taskId) => list.includes(taskId);
  const toggle = (taskId) => {
    setList((previous) => {
      const list = [];
      // Remove first. This allows both adding and removing from the list.
      previous.forEach((item) => taskId !== item && list.push(item));
      if (taskId && !checkIfSelected(taskId)) list.push(taskId); // Then add back only if task was not selected before.
      return list;
    });
  };
  return {
    checkIfSelected,
    toggle,
    showingMultiSelect: !!active,
    setShowingMultiSelect: setActive,
    selectedTasksCount: list.length,
    selectedTaskIds: list,
    reset: () => {
      trackerHelper.multipleSelect.logMultiselectIconClicked();
      setList([]);
      setActive(true);
    },
    cancel: () => {
      trackerHelper.multipleSelect.logCancel(list.length);
      setList([]);
      setActive(false);
    },
  };
}

export function useMultiSelectForTab(type) {
  return React.useContext(SelectedTasksContext)?.[type] || { disabled: true };
}
