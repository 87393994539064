import React from "react";
import { useParentPopup } from "../index";
import { Trans } from "@coworker/locales";

const positiveText = <Trans>positiveContinueString</Trans>;
const negativeText = <Trans>cancelString</Trans>;
const question = <Trans>question2String</Trans>;

function generateConfirmationMessage(
  amount: number,
  max: number,
  stock: number
): object | undefined {
  if (max < amount && stock < amount) {
    return <Trans i18nKey="maxStockAndAmountString">{{ max, stock }}</Trans>;
  } else if (max < amount) {
    return <Trans count={max}>maxAmountInLocationString</Trans>;
  } else if (stock < amount) {
    return <Trans count={stock}>littleStockString</Trans>;
  }
  return undefined;
}

export default function useAmountConfirmation() {
  const { getInput } = useParentPopup();

  return React.useCallback(
    async (
      amount: number,
      max: number,
      available: number,
      maxAmountExceeded: (selection: string) => void
    ): Promise<boolean> => {
      if (!max || !available) return true;

      const confirmationMessage = generateConfirmationMessage(
        amount,
        max,
        available
      );
      if (!confirmationMessage) return true;

      const response = await getInput("confirmation", {
        question,
        positiveText,
        negativeText,
        description: confirmationMessage,
      });

      if (maxAmountExceeded) {
        const confirmationSelection = response ? "continue" : "cancel";
        maxAmountExceeded(confirmationSelection);
      }
      return !!response;
    },
    [getInput]
  );
}
