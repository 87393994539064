// 144 x 120
import React from "react";
import {
  CenterContainer,
  GreyBox,
  FlexibleRowContainer,
  SizedText,
} from "../styles/styles";
import { Text } from "../Atoms/Text";

interface TwoLinedGreyBoxProps {
  headLine: string;
  bottomleftText: string;
  rightComponent: React.ReactNode;
  onClick?: () => void;
}

export function TwoLinedGreyBox({
  headLine,
  bottomleftText,
  rightComponent,
  onClick,
}: TwoLinedGreyBoxProps) {
  return (
    <GreyBox onClick={onClick}>
      <CenterContainer $centerText={false} style={{ gap: "24px" }}>
        <Text text={headLine} bold />
        <FlexibleRowContainer>
          <SizedText $fontSize={48} $bold>
            {bottomleftText}
          </SizedText>
          {rightComponent}
        </FlexibleRowContainer>
      </CenterContainer>
    </GreyBox>
  );
}
