import React from "react";
import { PoseGroup } from "react-pose";
import { animatedCurtain } from "../helpers/animationHelper";
import styled from "styled-components";
import trackerHelper from "../helpers/tracker";
import { getCookieByName } from "../helpers/cookies";

const NOTIFICATION_DURATION = 3000;

const CurtainStateContext = React.createContext(null);
const CurtainAPIContext = React.createContext(null);

const ACTIONS = {
  ADD: Symbol("ADD"),
  REMOVE: Symbol("REMOVE"),
};

function useCurtainNotification() {
  const dispatch = React.useContext(CurtainAPIContext);

  const showCurtain = React.useCallback(
    (
      message = "",
      actionText = "",
      actionHandler = () => {},
      notificationHandler = () => {},
      {
        duration = NOTIFICATION_DURATION,
        tag = null,
        onExpiredDuration = () => {},
      } = {}
    ) => {
      // We hide curtain notifications in smoke checks to make them more stable
      if (window.Cypress && getCookieByName("smokeCheck")) return;

      if (!dispatch) return;
      dispatch({
        type: ACTIONS.ADD,
        item: {
          message,
          actionText,
          actionHandler,
          notificationHandler,
          duration,
          key: `${Date.now()}`,
          tag,
          onExpiredDuration,
        },
      });
    },
    [dispatch]
  );

  const closeCurtain = React.useCallback(() => {
    dispatch({ type: ACTIONS.REMOVE });
  }, [dispatch]);

  return { showCurtain, closeCurtain };
}

const curtainReducer = (state = [], action) => {
  const map = new Map([...state]);

  switch (action.type) {
    case ACTIONS.ADD:
      const key = action.item.tag || action.item.key;
      if (map.has(key)) return [...map];

      if (!action.item.duration) {
        map.set(key, action.item);
        return [...map];
      } else {
        return [...new Map([[key, action.item], ...map])];
      }
    case ACTIONS.REMOVE:
      const removeKey = [...map].shift()?.[0];
      map.delete(removeKey);
      return [...map];
    default:
      return [...map];
  }
};

function CurtainNotificationProvider({ children }) {
  const [state, dispatch] = React.useReducer(curtainReducer, []);

  return (
    <CurtainStateContext.Provider value={[state, dispatch]}>
      <CurtainAPIContext.Provider value={dispatch}>
        {children}
      </CurtainAPIContext.Provider>
    </CurtainStateContext.Provider>
  );
}

const Curtain = styled.div`
  /* shared between Curtain and Toast */
  color: ${(props) => (props.yellow ? "var(--black)" : "var(--white)")};
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  cursor: pointer;
  /* Specific for curtain */
  background: ${(props) => (props.yellow ? "var(--yellow)" : "var(--black)")};
  min-height: ${(props) => (props.yellow ? "84px" : "60px")};
  padding: 0 28px;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-toast);
  box-sizing: border-box;
`;

const PosedCurtain = animatedCurtain(Curtain);

const View = styled.div`
  font-weight: bold;
  margin-left: 29px;
`;

function CurtainNotification() {
  const [state, dispatch] = React.useContext(CurtainStateContext);

  const displayNext = React.useCallback(() => {
    dispatch({ type: ACTIONS.REMOVE });
  }, [dispatch]);

  const hideOnSwipe = { y: (y) => y < -20 };

  const [tag, current] = state?.[0] || [];

  // Do not show announcement on search and task/new screens.
  if (window.location.href.match(/\/search|\/task\/new/)) return null;

  return !!current ? (
    <PoseGroup>
      {current && (
        <PosedCurtain
          onValueChange={hideOnSwipe}
          key={current.key}
          data-testid="curtainNotification"
          onClick={current.notificationHandler}
          yellow={["tutorial", "newFeatureAnnouncement"].includes(tag)}
        >
          <NotificationDisplay
            {...current}
            onDestroy={() => {
              current.onExpiredDuration();
              displayNext();
            }}
          />
        </PosedCurtain>
      )}
    </PoseGroup>
  ) : null;
}

function NotificationDisplay({
  message,
  actionHandler,
  actionText,
  onDestroy,
  duration,
}) {
  const timeout = React.useRef(0);
  React.useEffect(() => {
    if (duration) {
      timeout.current = setTimeout(onDestroy, duration);
    }
    return () => clearTimeout(timeout.current);
  }, [duration, onDestroy]);

  const onClick = (e) => {
    e.stopPropagation();
    trackerHelper.trackNotificationCTAClick();
    actionHandler();
  };

  return (
    <>
      <div>{message}</div>
      {actionHandler && (
        <View data-testid="notificationViewLink" onClick={onClick}>
          {actionText}
        </View>
      )}
    </>
  );
}

export {
  CurtainNotification,
  CurtainNotificationProvider,
  useCurtainNotification,
};
