import React, { SVGProps, useContext } from "react";
import { useLocation } from "react-router";
import homeIcon from "@ingka/ssr-icon/paths/home";
import tasksIcon from "@ingka/ssr-icon/paths/checkmark-circle";
import menuIcon from "@ingka/ssr-icon/paths/menu";
import starIcon from "@ingka/ssr-icon/paths/star";
import { useTranslation } from "@coworker/locales";
import SSRIcon from "@ingka/ssr-icon";
import { ModalContext } from "../../context/ModalContextProvider";
import { ModalType } from "../../context/modalType";
import styles from "./bottom-navigation.module.css";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import trackerHelper from "@coworker/app/src/helpers/tracker";
import { isChineseEnvironment } from "@coworker/reusable";

const isChina = isChineseEnvironment();

interface NavItemType {
  label: string;
  href?: string;
  icon: (prefix?: string | undefined) => SVGProps<SVGElement>[];
  onClick?: () => void;
}

export const BottomNavigation = () => {
  const { push } = useWorkspacesAction();
  const location = useLocation();
  const { toggleModal } = useContext(ModalContext);

  const { t } = useTranslation();

  const navItems: NavItemType[] = [
    {
      label: t("homeString"),
      href: "/",
      icon: homeIcon,
    },
    {
      label: t("TasksString"),
      href: "/tasks?active=my",
      icon: tasksIcon,
    },
    {
      label: t("menuString"),
      onClick: () => toggleModal({ type: ModalType.MainNavigation }),
      icon: menuIcon,
    },
    {
      label: t("activityString"),
      href: !isChina ? "/activity" : "/tasks/activity",
      icon: starIcon,
    },
  ];

  return (
    <div className={styles["bottom-navigation"]}>
      {navItems?.map((navItem) => {
        const isActive =
          navItem.href &&
          (`${location.pathname}${location.search}` === navItem.href ||
            (navItem.href === "/tasks?active=my" &&
              location.search === "?active=open"));
        return (
          <div
            className={`${styles["navigation-item"]} ${
              isActive ? styles["active-item"] : ""
            }`}
            key={navItem.label}
            onClick={() => {
              if (navItem.onClick) {
                navItem.onClick();
                trackerHelper.trackButtomNavigationClick(navItem.label);
                return;
              }
              if (navItem.href) {
                push(navItem.href);
                trackerHelper.trackButtomNavigationClick(navItem.label);
              }
            }}
          >
            <div className={styles["top-border"]}></div>
            <SSRIcon paths={navItem.icon} />
            <div className={styles["label"]}>{navItem.label}</div>
          </div>
        );
      })}
    </div>
  );
};
