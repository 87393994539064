import React from "react";
import { Trans } from "@coworker/locales";
import trackerHelper from "../../../helpers/tracker";
import { LOCATION_TYPE_PICKUP } from "../../../constants/locationWidgetTypes";
import {
  locationMapper,
  amountMapper,
  assigneeMapper,
  productIssueTypeMapper,
  refillTypeMapper,
} from "./fieldMappers.helpers";

export const getLocationPopup = async ({
  init = false,
  getInput,
  onChange,
  addon,
  code,
  custom,
  title,
  locationCustom,
  locationCode,
  product_article_id,
  product_article_type,
  requestedAmount,
  isSFM,
  isFoodServices,
  isCPG,
}) => {
  const data = await getInput("addonLocation", {
    product_article_id,
    product_article_type,
    locationCode,
    locationCustom,
    title,
    requestedAmount,
    addon,
    isSFM,
    isFoodServices,
    isCPG,
  });
  if (data) {
    const {
      locationCode,
      locationSection,
      locationDepartment,
      locationCustom,
      locationQuantity,
      relatedOnThisLocation,
      primaryLocation,
      homeLocation,
    } = data;

    if (
      addon === LOCATION_TYPE_PICKUP &&
      data.locationCode !== code &&
      data.locationCustom !== custom
    ) {
      trackerHelper.trackPickupLocationChange();
    }

    const locationObject = {
      code: locationCode,
      department: locationDepartment,
      custom: locationCustom,
      section: locationSection,
      primaryLocation,
      homeLocation,
    };

    onChange(init ? locationMapper(locationObject) : locationObject);
    return {
      locationQuantity: locationQuantity || null,
      submitted: true,
      relatedOnThisLocation,
      locationCode,
      homeLocation,
    };
  }
  return { submitted: false };
};

export const getAmountPopup = async ({
  init = false,
  getInput,
  onChange,
  title = <Trans>piecesString</Trans>,
  maxAmount,
  available,
}) => {
  const data = await getInput("selectAmount", {
    title,
    initializeWithOldAmount: true,
    changeAmount: false,
    maxAmount,
    available,
  });

  if (data) {
    onChange(init ? amountMapper(data) : data);
    return true;
  }
  return false;
};

export const getAssigneesPopup = async ({
  init = false,
  getInput,
  onChange,
  title = <Trans>assignString</Trans>,
  lastAssignedTeamId,
  assignees = {},
  disableTeamAssignment,
  shortId = "",
}) => {
  const data = await getInput("selectAssignees", {
    title,
    assignees,
    disableTeamAssignment,
    shortId,
  });
  if (data) {
    const { gid, uid } = data;

    if (lastAssignedTeamId && lastAssignedTeamId !== gid) {
      trackerHelper.taskEfficiency.trackTeamChange();
    }

    onChange(init ? assigneeMapper({ gid, uid }) : { gid, uid });
  }
};

export const getProductIssuePopup = async ({
  init = false,
  issue,
  getInput,
  onChange,
  description,
  issues,
  title = <Trans>selectIssueString</Trans>,
}) => {
  const data = await getInput("selectIssue", {
    title,
    issue,
    description,
    content: issues,
  });

  if (data) {
    onChange(init ? productIssueTypeMapper(data) : data);
    return true;
  }
  return false;
};

export const getRefillTypePopup = async ({
  init = false,
  getInput,
  onChange,
  refillType,
}) => {
  const data = await getInput("refillType", {
    selectedRefillType: refillType,
  });
  //init is set to true on addOnFlow
  if (data) {
    onChange(init ? refillTypeMapper(data) : data);
    return true;
  }
  return false;
};
