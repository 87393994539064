import React, { useState } from "react";
import { Rooms } from "../types/room";
import { useTranslation } from "@coworker/locales";
import { SearchBarContainer } from "../styles/styles";
import Search from "@ingka/search";

interface RoomsSearchBarProps {
  rooms: Rooms;
  onSearch: (results: Rooms) => void;
}

export const RoomsSearchBar = ({ rooms, onSearch }: RoomsSearchBarProps) => {
  const [searchInput, setSearchInput] = useState("");
  const { t } = useTranslation();

  const handleSearch = (e: InputEvent) => {
    if (!rooms) return;

    const searchInput = (e.target as HTMLInputElement).value;

    setSearchInput((e.target as HTMLInputElement).value);
    onSearch(
      rooms.filter(
        (room) =>
          room.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          room.main_article_name_1
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          room.main_article_name_2
            .toLowerCase()
            .includes(searchInput.toLowerCase())
      )
    );
  };

  const handleClear = () => {
    setSearchInput("");
    onSearch(rooms);
  };

  return (
    <SearchBarContainer>
      <Search
        id="room-search"
        value={searchInput}
        onChange={(e: InputEvent) => {
          handleSearch(e);
        }}
        onClear={handleClear}
        placeholder={t("roomsViewSearch")}
      />
    </SearchBarContainer>
  );
};
