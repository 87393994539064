import React from "react";
import { Trans } from "@coworker/locales";
import IngkaButton from "@ingka/button";
import styled from "styled-components";
import {
  DIVISION_HASH,
  HEADER_COLOR_SCHEMA,
  fullServeOrder,
} from "../constants";
import { RoundCard } from "../Rounds/RoundCard";
import { useParams } from "react-router-dom";
import { useCreateShoppingTool } from "../Hooks/useCreateShoppingTool";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { Receipt } from "./Receipt";
import { checkLastShoppingToolsValues, scrollToTop } from "../helper";
import { useFetchShoppingtoolsSettings } from "../Hooks/useFetchShoppingtoolsSettings";
import { CashlineQuantitySteppers } from "./CashlineQuantitySteppers";
import { SubHeaderCard } from "../Components/SubHeaderCard";
import { useFetchDivisionsCountByRound } from "../Hooks/useFetchDivisionsCountByRound";
import { ShoppingToolsListView } from "../Components/ShoppingToolsListView";
import SSRIcon from "@ingka/ssr-icon";
import SettingsIcon from "@ingka/ssr-icon/paths/gear";
import { AppBarAction } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${({ extaPaddingBottom }: { extaPaddingBottom: boolean }) =>
    extaPaddingBottom ? "padding-bottom: 12rem" : "padding-bottom: 1rem"}
`;

export const Cashline = () => {
  const [hasFullServeOrder, setHasFullServeOrder] =
    React.useState<boolean>(false);
  const [totalSum, setTotalSum] = React.useState<string>("");
  const [totalArticles, setTotalArticles] = React.useState<string>("");
  const [showTotalSumKeyPad, setShowTotalSumKeyPad] =
    React.useState<boolean>(false);
  const [showTotalArticlesKeyPad, setShowTotalArticlesKeyPad] =
    React.useState<boolean>(false);
  let [currentValue, setCurrentValue] = React.useState<number>(0);
  const [lastShoppingTools, setLastShoppingTools] = React.useState({});

  const { id } = useParams();
  const { push } = useWorkspacesAction();
  const storeId = useStoreId();
  const createShoppingToolMutation = useCreateShoppingTool();

  const { data: startValue, isFetching } = useFetchDivisionsCountByRound(
    DIVISION_HASH.CASHLINE?.division ?? "",
    id ?? ""
  );

  const { data: settingsList, isFetching: isSettingsFetching } =
    useFetchShoppingtoolsSettings();

  React.useEffect(() => {
    setCurrentValue(startValue);
  }, [startValue]);

  const handleSubmit = async () => {
    await createShoppingToolMutation.mutateAsync({
      storeId: storeId,
      division: DIVISION_HASH.CASHLINE?.division ?? "",
      roundName: id ?? "",
      lastShoppingTools: lastShoppingTools,
      hasFullServeOrder: hasFullServeOrder,
      totalSum: parseFloat(totalSum),
      totalArticles: parseInt(totalArticles) ?? null,
    });
    setLastShoppingTools({});
    setTotalSum("");
    setTotalArticles("");
    setHasFullServeOrder(false);
    setShowTotalSumKeyPad(false);
    setShowTotalArticlesKeyPad(false);
    currentValue++;
    setCurrentValue(currentValue);
    scrollToTop();
  };

  const appBarActions: AppBarAction[] = [
    {
      name: "close",
      icon: <SSRIcon paths={SettingsIcon} />,
      onClick: () => push("/shoppingtools/settings"),
      position: "right",
    },
  ];

  return (
    <FullScreenPopup
      appBarConfig={{
        title: (
          <>
            <Trans>otherAreaCashlineString</Trans> -{" "}
            <Trans>{`${id}String`}</Trans>
          </>
        ),
        actions: appBarActions,
      }}
      noPadding
    >
      <SubHeaderCard
        title={<Trans>customerCameToolString</Trans>}
        fontSize={16}
        colorSchema={HEADER_COLOR_SCHEMA.GREY}
      />
      <CashlineQuantitySteppers
        lastShoppingTools={lastShoppingTools}
        setLastShoppingTools={setLastShoppingTools}
      />
      {!isSettingsFetching && settingsList?.length && (
        <ShoppingToolsListView
          itemList={fullServeOrder("switch")}
          setChecked={setHasFullServeOrder}
          checked={hasFullServeOrder}
          listViewProps={{
            id: "fullserve",
          }}
          borderColor={HEADER_COLOR_SCHEMA.GREY.background}
        />
      )}
      <Receipt
        totalSum={totalSum}
        setTotalSum={setTotalSum}
        totalArticles={totalArticles}
        setTotalArticles={setTotalArticles}
        showTotalSumKeyPad={showTotalSumKeyPad}
        setShowTotalSumKeyPad={setShowTotalSumKeyPad}
        showTotalArticlesKeyPad={showTotalArticlesKeyPad}
        setShowTotalArticlesKeyPad={setShowTotalArticlesKeyPad}
      />
      <RoundCard
        title={DIVISION_HASH.CASHLINE?.transKey ?? ""}
        currentValue={currentValue}
        showNav={false}
        background="secondary"
        whiteBarBackground
        loading={isFetching && !startValue}
      />
      <ButtonWrapper
        extaPaddingBottom={showTotalSumKeyPad || showTotalArticlesKeyPad}
      >
        <IngkaButton
          type="primary"
          style={{ marginTop: "15px", width: "80%" }}
          text={<Trans>submitNewString</Trans>}
          disabled={
            checkLastShoppingToolsValues(lastShoppingTools) ||
            parseInt(totalSum) === 0 ||
            totalSum === ""
          }
          onClick={handleSubmit}
        />
      </ButtonWrapper>
    </FullScreenPopup>
  );
};
