module.exports = {
  PICK_UP: "PICK_UP",
  PICKED: "PICKED",
  PICKED_AND_REASSIGNED_TEAM: "PICKED_AND_REASSIGNED_TEAM",
  PICKED_AND_REASSIGNED_USER: "PICKED_AND_REASSIGNED_USER",
  UNDO_COMPLETE: "UNDO_COMPLETE",
  UNDO_CLOSE: "UNDO_CLOSE",
  COMPLETE: "COMPLETE",
  CLOSE: "CLOSE",
  DROP: "DROP",
  RELEASE: "RELEASE",
  REASSIGN: "REASSIGN",
  REASSIGN_TEAM: "REASSIGN_TEAM",
  REOPEN: "REOPEN",
  EDIT: "EDIT",
  RELEASE_DUE_TO_USER_DELETED: "RELEASE_DUE_TO_USER_DELETED",
  CREATE_AND_ASSIGN_TO_TEAM: "CREATE_AND_ASSIGN_TO_TEAM",
  CREATE_AND_ASSIGN_TO_USER: "CREATE_AND_ASSIGN_TO_USER",
  CLOSED_DUE_TO_USER_DELETED: "CLOSED_DUE_TO_USER_DELETED",
  CREATE_AND_COMPLETE: "CREATE_AND_COMPLETE",
  REFILL_QUANTITY_EDIT: "REFILL_QUANTITY_EDIT",
};
