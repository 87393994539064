import React from "react";
import { create } from "zustand";
import { extendWithGetters } from "@coworker/reusable/helpers/zustandHelpers";
import { camelProxy } from "@coworker/reusable/helpers/objectAccess";
import { FirebaseProfile } from "@coworker/types";

interface LoggedInUserState {
  countryId: string;
  employeeId: string;
  givenName: string;
  phone: string;
  storeId: string;
  teamId: string;
  updatedAt: number;
  uid: string;
  startedAtVersion: string;
  email: string;
  surname: string;
  roleId: string;
  fixaUid: string;
  userId: string;
  hashedUid: string;
  permissions: any | undefined;
}

const initialLoggedInUserState: LoggedInUserState = {
  countryId: "",
  employeeId: "",
  givenName: "",
  phone: "",
  storeId: "",
  teamId: "",
  updatedAt: 0,
  uid: "",
  startedAtVersion: "",
  email: "",
  surname: "",
  roleId: "",
  fixaUid: "",
  userId: "",
  hashedUid: "",
  permissions: undefined,
};

export const useLoggedInUserStore = extendWithGetters(
  create<LoggedInUserState>()(() => initialLoggedInUserState)
);

export function useLoggedInUser() {
  const setLoggedInUser = React.useCallback(
    (user: FirebaseProfile, useFixaUidInApp?: boolean) => {
      // Protect against too old data from localStorage overwrites and may cause user to
      // get into a loop where they can never log in and refresh data to stay logged in.
      if (user.updated_at > useLoggedInUserStore.getState().updatedAt) {
        const {
          countryId,
          employeeId,
          givenName,
          phone,
          storeId,
          teamId,
          updatedAt,
          uid,
          startedAtVersion,
          email,
          surname,
          roleId,
          fixaUid,
          hashedUid,
          permissions,
        } = camelProxy(user);
        useLoggedInUserStore.setState({
          countryId,
          employeeId,
          givenName,
          phone,
          storeId,
          teamId,
          updatedAt,
          uid,
          startedAtVersion,
          email,
          surname,
          roleId,
          fixaUid,
          userId: useFixaUidInApp ? fixaUid : uid,
          hashedUid,
          permissions,
        });
      }
    },
    []
  );

  const resetLoggedInUser = React.useCallback(() => {
    useLoggedInUserStore.setState(initialLoggedInUserState);
  }, []);

  return {
    ...useLoggedInUserStore.getters,
    setLoggedInUser,
    resetLoggedInUser,
  };
}

export const useCountryId = () => useLoggedInUser().getCountryId();
export const useEmployeeId = () => useLoggedInUser().getEmployeeId();
export const useGivenName = () => useLoggedInUser().getGivenName();
export const usePhone = () => useLoggedInUser().getPhone();
export const useStoreId = () => useLoggedInUser().getStoreId();
export const useTeamId = () => useLoggedInUser().getTeamId();
export const useUID = () => useLoggedInUser().getUid();
export const useStartedAtVersion = () =>
  useLoggedInUser().getStartedAtVersion();
export const useEmail = () => useLoggedInUser().getEmail();
export const useSurname = () => useLoggedInUser().getSurname();
export const useRoleId = () => useLoggedInUser().getRoleId();
export const useFixaUID = () => useLoggedInUser().getFixaUid();
export const useUserId = () => useLoggedInUser().getUserId();
export const useHashedUid = () => useLoggedInUser().getHashedUid();
export const useUserPermissions = () => useLoggedInUser().getPermissions();
export const setLoggedInUserTeamId = (teamId: string) =>
  useLoggedInUserStore.setState({ teamId: teamId });
