import React from "react";
import styled from "styled-components";
import { WidgetRow } from "../PaddedRow";
import { useInputPopup } from "../../../components/InputPopup";
import { ReactComponent as PersonIcon } from "../../../assets/svg/Person.svg";
import { ElidedText } from "@coworker/reusable/components/ElidedText";
import { UserOrGroupName } from "../../../core/components/UserOrGroupName";
import { Trans } from "@coworker/locales";

const TitleWrapper = styled.div`
  margin: 6px 0px;
`;

interface AreaSelectionProps {
  title?: React.ReactNode;
  selectedTeamId?: string | undefined;
  selectedUserId?: string | undefined;
  onConfirm: (assignee: { gid: string; uid: string }) => void;
  onClose?: () => void;
  image?: string | React.ReactNode;
  disabled?: boolean;
  showWarning?: boolean;
  testId?: string;
  disableTeamAssignment?: boolean;
  shortId?: string;
  additionalSubTitle?: JSX.Element | undefined;
}

export function AssigneeSelection({
  title = <Trans>assignString</Trans>,
  selectedTeamId,
  selectedUserId,
  onConfirm,
  onClose,
  image = <PersonIcon />,
  testId,
  shortId,
  disableTeamAssignment = false,
  additionalSubTitle,
}: AreaSelectionProps) {
  const { getInput } = useInputPopup();
  const onClick = React.useCallback(
    () =>
      getInput("selectAssignees", {
        title,
        shortId,
        assignees: { gid: selectedTeamId, uid: selectedUserId },
        disableTeamAssignment,
      }).then((chosen: { gid: string; uid: string } | undefined) =>
        chosen ? onConfirm(chosen) : onClose?.()
      ),
    [
      disableTeamAssignment,
      onClose,
      onConfirm,
      title,
      shortId,
      getInput,
      selectedTeamId,
      selectedUserId,
    ]
  );

  return (
    <>
      <WidgetRow
        className="WidgetView"
        image={image}
        middle={
          <b>
            {selectedTeamId || selectedUserId ? (
              <ElidedText>
                <UserOrGroupName uid={selectedUserId} gid={selectedTeamId} />
              </ElidedText>
            ) : (
              <>
                <TitleWrapper>{title}</TitleWrapper>
                {additionalSubTitle}
              </>
            )}
          </b>
        }
        right={<></>}
        onClick={onClick}
        testId={testId || "assignee-selection-widget"}
        disableRightArrow={true}
      />
    </>
  );
}
