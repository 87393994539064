import { ActivityFilterType } from "../types/filterOptionsTypes";

export const DEFAULT_FILTER_VALUES: ActivityFilterType = {
  assignedTo: false,
  createdBy: "",
  taskType: false,
  sortedBy: "createdAtDesc",
  groupBy: false,
  pickupLocations: false,
  refillLocations: false,
  locations: false,
};
