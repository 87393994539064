import React from "react";
import { TestBuy } from "@coworker/types";
import { RankingOverview } from "../RankingOverview";
import { calculatePassRatio } from "../../../CommonComponents/helpers";
import {
  describeAreaFilter,
  FilterKeys,
} from "../../../../InputPopup/UserFiltersRow/filterChoices";
import { countTestBuysPerHfb, createRankItems } from "./Ranking.helper";
import { COMPLETED_STATUS } from "../../../constants";

interface HfbRanksProps {
  testbuys: TestBuy[];
  allTestbuys: TestBuy[];
  filters: FilterKeys;
  isWholeStoreActive: boolean;
  totalTestbuysDone: number;
}

export const HfbRanks = ({
  testbuys,
  allTestbuys,
  filters,
  isWholeStoreActive,
  totalTestbuysDone,
}: HfbRanksProps) => {
  const [hfb, setHfb] = React.useState<string | JSX.Element>("");
  const [passRatioState, setPassRatio] = React.useState(100);
  const [totalArticles, setTotalArticles] = React.useState(0);
  const [activeHfb, setActiveHfb] = React.useState<number>(0);

  const countedTestBuys = countTestBuysPerHfb(
    allTestbuys.filter((tb) => tb.area !== "whole=whole")
  );

  React.useEffect(() => {
    if (!isWholeStoreActive) {
      setActiveHfb(Number((hfb as string).slice(4)));
    }
  }, [isWholeStoreActive, hfb, activeHfb]);

  React.useEffect(() => {
    if (testbuys && filters) {
      const totalCompletedTestBuys = testbuys.filter(
        (tb) =>
          (tb.finishedAtTs ?? 0) >= 1661990400000 &&
          tb.status === COMPLETED_STATUS
      );
      const totalArticlesCount = totalCompletedTestBuys.reduce(
        (accumulator, tb) => {
          return accumulator + tb.numberOfArticles;
        },
        0
      );

      const passRatio = calculatePassRatio(
        totalCompletedTestBuys,
        totalArticlesCount
      );

      setTotalArticles(totalArticlesCount);
      setHfb(describeAreaFilter(filters));
      setPassRatio(passRatio);
    }
  }, [testbuys, filters]);

  const offsetIntoCounts = countedTestBuys.findIndex(
    ({ area }) => area === filters[1]
  );

  const { ahead, active, behind } = createRankItems(
    countedTestBuys,
    offsetIntoCounts
  );

  return (
    <RankingOverview
      testbuys={testbuys}
      ahead={ahead}
      active={active}
      behind={behind}
      filters={filters[0]}
      passRatio={{
        hfb,
        percent: passRatioState,
        totalTestbuysDone: totalTestbuysDone,
        totalArticles,
      }}
      isWholeStoreActive={isWholeStoreActive}
      offsetIntoCounts={offsetIntoCounts}
    />
  );
};
