import React, { useContext } from "react";
import SSRIcon from "@ingka/ssr-icon";
import hamburgerMenuIcon from "@ingka/ssr-icon/paths/menu";
import { ModalContext } from "../../../../context/ModalContextProvider";
import { ModalType } from "../../../../context/modalType";
import styles from "./hamburger-menu.module.css";

const HamburgerMenu = () => {
  const { toggleModal } = useContext(ModalContext);

  return (
    <div
      className={styles["hamburger-menu"]}
      onClick={() => toggleModal({ type: ModalType.MainNavigation })}
    >
      <SSRIcon paths={hamburgerMenuIcon} />
    </div>
  );
};

export default HamburgerMenu;
