import { RoomSettings } from "@coworker/types/lib/tasks/room_settings";
import {
  ArticleFilterTypes,
  ArticleSortingTypes,
  Reasons,
  TaskListingTypes,
  TaskOwner,
} from "./constants";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import dayjs from "dayjs";
import { Article, Articles, EdsData, SalesStopData } from "./types/article";
import { OngoingTaskLinksResponse, TaskLinks } from "./types/taskLink";
import { Room } from "./types/room";
import { hasOngoingTask, sortArticles } from "./Molecules/RoomArticles.helper";
import { createNote } from "../../services/note.service";
import { updateTask } from "../../services/task.service";

export function getTranslationKeyFromFlags(task: RoomSettings) {
  if (task.is_ptag) {
    return "printPtagInRoomString";
  }
  if (task.is_new_article) {
    return "replaceArticleInRoomString";
  }
  if (task.reason === Reasons.REMOVE_ARTICLE) {
    return "removeProductInString";
  }
  if (task.reason === Reasons.REPLACE_PRODUCT) {
    return "changeProductInString";
  }
  return "replaceArticleInRoomString";
}

export function getTranslationKeyFromReason(reason: string) {
  switch (reason) {
    case Reasons.DAILY_MAINTENANCE:
      return "dailyMaintenanceString";
    case Reasons.REPLACE_PRODUCT:
      return "changeProductString";
    case Reasons.SALES_STOP:
      return "replaceRoomInArticleString";
    case Reasons.REMOVE_ARTICLE:
      return "removeProductString";
    default:
      return "replaceArticleInRoomString";
  }
}

export function getPiecesTranslation(count: number) {
  return count > 1 ? "piecesUnit" : "pieceUnit";
}

export function getRoomsCountTranslation(count: number) {
  return count > 1 ? "roomSettingsCount" : "roomSettingCount";
}

export function fullRoomName(
  main: string | undefined,
  sub1: string | undefined,
  sub2: string | undefined
) {
  let headLine = main || "";

  if (headLine && (sub1 || sub2)) {
    headLine += ": ";
  }

  let subDivider = " / ";
  if (sub1) {
    headLine += sub1;
  } else {
    subDivider = "";
  }
  if (sub2) {
    headLine += subDivider + sub2;
  }
  return headLine;
}

export const isOngoing = (t: RoomSettings) => {
  const ongoingStates = [
    taskStateOptions.ASSIGNED,
    taskStateOptions.UNASSIGNED,
  ];
  return ongoingStates.includes(t.state);
};

export function getTaskFilter(taskListingType: TaskListingTypes) {
  switch (taskListingType) {
    case TaskListingTypes.Ongoing:
      return (t: RoomSettings) => isOngoing(t);
    case TaskListingTypes.NotOngoing:
      return (t: RoomSettings) => !isOngoing(t);
    case TaskListingTypes.All:
    default:
      return (_: RoomSettings) => true;
  }
}

export function getPassedTimeAndTranslationKey(created_at: string) {
  const now = dayjs();
  const taskDate = dayjs(created_at);

  const monthsPassed = now.diff(taskDate, "months");
  if (monthsPassed > 1) {
    return {
      passedTime: monthsPassed,
      translationKey: "monthsAgoString",
    };
  }
  const weeksPassed = now.diff(taskDate, "weeks");
  if (weeksPassed > 1) {
    return {
      passedTime: weeksPassed,
      translationKey: "weeksAgoString",
    };
  }
  const daysPassed = now.diff(taskDate, "days");
  if (daysPassed > 1) {
    return {
      passedTime: daysPassed,
      translationKey: "daysAgoString",
    };
  }
  const hoursPassed = now.diff(taskDate, "hours");
  if (hoursPassed > 24) {
    return {
      passedTime: hoursPassed,
      translationKey: "hoursAgoString",
    };
  }
  return { passedTime: 0, translationKey: "lessThanDayString" };
}

export const makeHeaders = (token: string) => ({
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
});

export const filterRoomSettingsListFunction = (
  tasks: RoomSettings[],
  taskOwner: string,
  fixaUid: string,
  teamId: string
) => {
  switch (taskOwner) {
    case TaskOwner.Mine:
      return tasks.filter(
        (task: RoomSettings) => task.assigned_user_id === fixaUid
      );
    case TaskOwner.Team:
      return tasks.filter(
        (task: RoomSettings) => task.assigned_team_id === teamId
      );
    default:
      return tasks;
  }
};

export const filterOngoingTasksByRoomId = (
  tasks: RoomSettings[],
  roomId: string
) => {
  return tasks
    .filter((task) => isOngoing(task))
    .filter((task) => task.room_id === roomId);
};

export const isArticleFilterType = (value: string) => {
  return Object.values(ArticleFilterTypes).includes(
    value as ArticleFilterTypes
  );
};

export const isArticleSortingType = (value: string) => {
  return Object.values(ArticleSortingTypes).includes(
    value as ArticleSortingTypes
  );
};

export const getArticleFilterTranslationString = (value: string) => {
  if (isArticleFilterType(value)) {
    switch (value) {
      case ArticleFilterTypes.ALL:
        return "allArticlesString";
      case ArticleFilterTypes.HFB:
        return "HFB";
      case ArticleFilterTypes.ONGOING_TASK:
        return "ongoingTasksString";
      case ArticleFilterTypes.OUTGOING_EDS:
        return "outgoingEdsString";
      case ArticleFilterTypes.SALES_STOP:
        return "salesStopString";
      default:
        return "";
    }
  }

  if (isArticleSortingType(value)) {
    switch (value) {
      case ArticleSortingTypes.LATEST_ACTIVITY:
        return "latestActivityString";
      case ArticleSortingTypes.NAME:
        return "byNameString";
      case ArticleSortingTypes.PRIORITY:
        return "byPriorityString";
      default:
        return "";
    }
  }

  return "";
};

export const hasOutgoingEDS = (eds: EdsData[], article: Article) => {
  return eds.some((ed) => ed.productArticleId === article.productArticleId);
};

export const hasSalesStop = (salesStop: SalesStopData[], article: Article) => {
  return salesStop.some(
    (ss) => ss.productArticleId === article.productArticleId
  );
};

export const joinProductArticleIds = (articles: Articles) => {
  return articles
    .map((article) => article.productArticleId)
    .sort((a, b) => a.localeCompare(b))
    .join(",");
};

export function sortAndFilterArticles(
  initialArticles: Articles,
  filter: ArticleFilterTypes,
  sort: ArticleSortingTypes,
  hfb: string,
  taskLinks: TaskLinks,
  edsData: EdsData[],
  salesStopData: SalesStopData[],
  room: Room
) {
  let articles = [...initialArticles];

  // Filtering
  if (filter === ArticleFilterTypes.ALL) {
    articles = initialArticles;
  } else if (filter === ArticleFilterTypes.ONGOING_TASK) {
    articles = articles.filter((article) =>
      hasOngoingTask(article.id, taskLinks)
    );
  } else if (filter === ArticleFilterTypes.OUTGOING_EDS) {
    articles = articles.filter((article) => hasOutgoingEDS(edsData, article));
  } else if (filter === ArticleFilterTypes.SALES_STOP) {
    articles = articles.filter((article) =>
      hasSalesStop(salesStopData, article)
    );
  }

  // HFB Filtering
  if (hfb !== "all") {
    articles = articles.filter((article) => article.hfb === hfb);
  }

  // Sorting
  if (sort === ArticleSortingTypes.NAME) {
    articles = articles.sort((a, b) => a.itemName.localeCompare(b.itemName));
  } else if (sort === ArticleSortingTypes.PRIORITY) {
    articles = sortArticles(
      articles,
      room.main_article_id_1,
      room.main_article_id_2,
      room.backup_article_id_1 ?? "",
      room.backup_article_id_2 ?? "",
      taskLinks,
      edsData,
      salesStopData
    );
  }
  // if (sort === ArticleSortingTypes.LATEST_ACTIVITY) {
  // }

  return articles;
}

export const formatWithCommasAndFilterEmpty = (items: string[] | undefined) =>
  items ? items.filter((i) => !!i.trim()).join(", ") : "";

export const setOngoingTasksToClosed = async (
  roomId: string,
  noteText: string,
  tasks: OngoingTaskLinksResponse,
  uid: string,
  storeId: string
): Promise<void> => {
  if (tasks.count > 0) {
    tasks.tasks.forEach(async (task) => {
      if (isOngoing(task) && task.room_id === roomId) {
        await createNote(
          task.id,
          {
            text: noteText,
            creator_id: uid,
          },
          [],
          storeId
        );

        await updateTask(
          { state: taskStateOptions.CLOSED },
          task.id,
          taskTypeOptions.ROOM_SETTINGS
        );
      }
    });
  }
};

export function getArticlesCountText(
  t: (key: string, options?: any) => string, // NOSONAR
  isSelected: boolean,
  isSearchOrFilter: boolean,
  selectedCount: number,
  articlesCount: number,
  searchCount: number,
  piecesCount: number
) {
  let articlesCountText = "";
  if (isSelected)
    articlesCountText = `${selectedCount}/${articlesCount} ${t(
      "articlesSelectedString"
    )}`;
  else if (isSearchOrFilter)
    articlesCountText = `${t(
      "viewingString"
    )} ${searchCount}/${articlesCount} ${t("articlesString")}`;
  else
    articlesCountText = `${t("countArticlesString_plural", {
      count: articlesCount,
    })}, ${piecesCount} ${t("piecesUnit")}`;
  return articlesCountText;
}
