import React from "react";
import styled, { css } from "styled-components";
import { Pill } from "@coworker/components";
import { ReactComponent as FiltersIcon } from "../../../assets/svg/icon-filters.svg";
import { useInputPopup } from "..";
import {
  areaIsWholeStore,
  defaultCustomPeriod,
  describeDivision,
  describeFilter,
  getAreaFilter,
  getDivisionFilter,
  getPeriodFilter,
  periodIsCustom,
} from "./filterChoices";
import { Trans } from "@coworker/locales";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import useFormatter from "../../../hooks/useFormatter";

const IconWrapper = styled.div`
  margin: auto 0;
  padding-left: 28px;
  padding-right: 16px;
  color: var(--grey900);
`;

const Row = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--grey200);

  ${({ disabled }) =>
    disabled
      ? css`
          color: var(--grey300);
          pointer-events: none;
        `
      : ""}

  overflow-x: auto;
  -webkit-scrollbar-width: none;
  -moz-scrollbar-width: none;
  -ms-scrollbar-width: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
`;

export default function UserFiltersRow({
  filters,
  disabled = false,
  settingsPrefix,
}) {
  if (!filters?.[0].startsWith("period=")) filters?.reverse();
  const { getInput } = useInputPopup();

  return (
    <Row
      data-testid="user-filters-row"
      disabled={disabled}
      onClick={() => {
        if (disabled) return;
        getInput("userFilters", {
          title: <Trans>refineString</Trans>,
          actionText: <Trans>applyFiltersString</Trans>,
          filters,
          settingsPrefix,
          hideShowTasksToggle:
            settingsPrefix !== profilePreferences.MFAQ_PREFIX,
        });
      }}
    >
      <IconWrapper>
        <FiltersIcon />
      </IconWrapper>
      <FilterPills
        filters={filters}
        disabled={disabled}
        settingsPrefix={settingsPrefix}
      />
    </Row>
  );
}

function FilterPills({ filters, disabled, settingsPrefix }) {
  const [showOnlyMFAQsWithTasks] = useUserPreference(
    settingsPrefix + profilePreferences.SHOW_QUESTIONS_WITH_TASKS,
    false
  );
  const describedFilters = useDescribedFilters(filters, settingsPrefix);
  return (
    <React.Fragment>
      {describedFilters.map((filter) => (
        <Pill
          disabled={disabled}
          key={filter?.props?.children || filter}
          text={filter}
        />
      ))}
      {showOnlyMFAQsWithTasks &&
        settingsPrefix === profilePreferences.MFAQ_PREFIX && (
          <Pill
            disabled={disabled}
            text={<Trans>onlyQuestionsTasksString</Trans>}
          />
        )}
    </React.Fragment>
  );
}

function useDescribedFilters(filters, settingsPrefix) {
  const { formatDateRange } = useFormatter();
  const [[startDate, endDate]] = useUserPreference(
    settingsPrefix + profilePreferences.USER_FILTERS_CUSTOM_PERIOD,
    defaultCustomPeriod()
  );
  const periodFilter = getPeriodFilter(filters);
  const areaFilter = getAreaFilter(filters);
  const divisionFilter = getDivisionFilter(filters);
  const divisionName = divisionFilter && describeDivision(divisionFilter);

  return React.useMemo(
    () => [
      periodIsCustom(periodFilter)
        ? formatDateRange(startDate, endDate)
        : describeFilter(periodFilter),
      divisionFilter ? (
        areaIsWholeStore(areaFilter) ? (
          <Trans>{divisionName}</Trans>
        ) : (
          <>
            {" "}
            <Trans>{describeFilter(areaFilter)}</Trans>{" "}
            <Trans>{divisionName}</Trans>
          </>
        )
      ) : (
        describeFilter(areaFilter)
      ),
    ],
    [
      areaFilter,
      endDate,
      formatDateRange,
      periodFilter,
      startDate,
      divisionFilter,
      divisionName,
    ]
  );
}
