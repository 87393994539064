import React from "react";
import { Trans, useTranslation } from "@coworker/locales";
import styled from "styled-components";
import { RankingShowcase } from "./RankingShowcase";
import Card from "@coworker/components/src/components/Card";
import { PassRatioProps } from "./PassRatioProps";
import { RankItemProps } from "./RankItemProps";
import { TestBuy } from "@coworker/types";
import { LineChart } from "./LineChart/LineChart";

const Subtitle = styled.div`
  color: var(--grey718);
  font-size: 14px;
`;

const Content = styled.div`
  background-color: var(--white);
  width: 100%;
`;

const Separator = styled.div`
  margin-top: 5px;
  margin-bottom: 10px;
`;

interface RankingOverviewProps {
  testbuys: TestBuy[];
  active: RankItemProps;
  ahead: RankItemProps;
  behind: RankItemProps;
  passRatio: PassRatioProps;
  isWholeStoreActive: boolean;
  offsetIntoCounts: number;
  filters: string;
}

export function RankingOverview({
  ahead,
  active,
  behind,
  passRatio,
  filters,
  testbuys,
  isWholeStoreActive,
  offsetIntoCounts,
}: RankingOverviewProps) {
  const [showPassRatio, setShowPassRatio] = React.useState(true);

  const { t } = useTranslation();

  const title = (
    <>
      <Trans>{showPassRatio ? "passRatioString" : "rankingString"}</Trans>
      <Subtitle>
        {showPassRatio ? passRatio.hfb : t("withinStoreString")}
      </Subtitle>
    </>
  );

  return (
    <Content>
      <Card
        testId="insightsRefillGraphContainer"
        noContentPadding
        title={title}
        action={
          isWholeStoreActive
            ? false
            : {
                label: t(
                  showPassRatio ? "viewRankingString" : "viewPassRatioString"
                ),
                onClick: () => setShowPassRatio((value) => !value),
              }
        }
        disabled={false}
      >
        <Separator />
        {showPassRatio ? (
          <LineChart
            filters={filters}
            testbuys={testbuys}
            passRatio={passRatio}
          />
        ) : (
          <RankingShowcase
            ahead={ahead}
            active={active}
            behind={behind}
            isWholeStoreActive={isWholeStoreActive}
            offsetIntoCounts={offsetIntoCounts}
            totalTestbuysDone={passRatio.totalTestbuysDone ?? 0}
          />
        )}
      </Card>
    </Content>
  );
}
