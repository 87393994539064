import React from "react";
import { Trans } from "@coworker/locales";
import { useInputPopup } from "../../InputPopup";
import BasicWidget from "@coworker/components/src/components/BasicWidget";
import { ReactComponent as WrenchIcon } from "../../../assets/svg/wrench.svg";
import { updateTask } from "../../../services/task.service";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import trackerHelper from "../../../helpers/tracker";
import { useStoreId } from "../../../core/auth/useLoggedInUser";

export default function TaskDetailMfaqResolution({
  task,
  taskId,
  initialResolution,
  onChange,
}) {
  const { getInput } = useInputPopup();
  const storeId = useStoreId();

  const getResolution = async () => {
    trackerHelper.MFAQ.trackTaskResolutionWidget(
      task.question_text,
      task.question_area
    );
    const resolutionText = await getInput("mfaqResolution", {
      initialResolution,
      onChange,
    });
    onChange(resolutionText);
    const data = { finished_summary: resolutionText };
    updateTask(data, taskId, "", storeId);
  };

  const isTaskClosedOrCompleted =
    task.state === taskStateOptions.COMPLETED ||
    task.state === taskStateOptions.CLOSED;
  const isTaskNotPickedUp = task.state === taskStateOptions.UNASSIGNED;
  const showWidgetSubTitle = isTaskNotPickedUp
    ? ""
    : initialResolution || <Trans>mfaqResolutionWidgetSubTitle</Trans>;

  return (
    <BasicWidget
      {...(!isTaskClosedOrCompleted &&
        !isTaskNotPickedUp && { onClick: getResolution })}
      testId="mfaqResolutionWidget"
      text={showWidgetSubTitle}
      title={<Trans>mfaqResolution</Trans>}
      icon={<WrenchIcon width={22} height={22} />}
      deactivated={false}
    />
  );
}
