import React from "react";
import styled, { css } from "styled-components";
import posed from "react-pose";
import { value, transform, ValueReaction } from "popmotion";

const { interpolate, pipe } = transform;

/**
 * H O W - D O E S - T H I S - W O R K
 * You first have to have 3 images: left, center, right. In initial state left
 * and right images are offscreen and center image has drag enabled on X axis.
 * All three images are connected with values/parentValues from pose to sync
 * drag to left and right image. When drag has ended it checks in which direction
 * to move photo and sets pose to desired direction, so pose transitions it to
 * right direction. After pose has transitioned the currentImage gets incremented
 * or decremented and all the images get remounted with new X value and keys.
 * Bottom indicator is also connected to center image and there are also left,
 * rigit and center versions so you can see edge cases (first to left and last
 * to right). Here follows the visual representation.
 *
 * DEFAULT STATE
 *
 *                           +----------------------------+
 *                           |                            |
 * +----------------------+  |  +-----------------------+ |  +------------------------------+
 * |                      |  |  |                       | |  |                              |
 * |                      |  |  |                       | |  |                              |
 * |                      |  |  |                       | |  |                              |
 * |                      |  |  |                       | |  |                              |
 * |                      |  |  |                       | |  |                              |
 * |                      |  |  |                       | |  |                              |
 * |                      |  |  |                       | |  |                              |
 * |                      |  |  |                       | |  |                              |
 * |                      |  |  |                       | |  |                              |
 * +----------------------+  |  +-----------------------+ |  +------------------------------+
 *                           |                            |
 *                           |  +-----------------------+ |
 *           +---+           |  |----|                  +----+
 *                           |  +-----------------------+ |
 *                           |                            |
 *                           |                            |
 *                           |                            |
 *                           |                            |
 *                           |                            |
 *                           |                            |
 *                           |                            |
 *                           +----------------------------+
 *
 * Scrolling
 *
 *                  +--------------------------+
 *                  |                          |
 *                  |                          |
 *         +--------------------+    +----------------------+    +--------------------+
 *         |        |           |    |         |            |    |                    |
 *         |        |           |    |         |            |    |                    |
 *         |        |           |    |         |            |    |                    |
 *         |        |           |    |         |            |    |                    |
 *         |        |           |    |         |            |    |                    |
 *         |        |           |    |         |            |    |                    |
 *         |        |           |    |         |            |    |                    |
 *         |        |           |    |         |            |    |                    |
 *         |        |           |    |         |            |    |                    |
 *         |        |           |    |         |            |    |                    |
 *         |        |           |    |         |            |    |                    |
 *         |        |           |    |         |            |    |                    |
 *         +--------------------+    +----------------------+    +--------------------+
 *                  |                          |
 *                  | +----------------------+ |
 * +----+           | |  |----|              | |  +----+
 *                  | +----------------------+ |
 *                  |                          |
 *                  |                          |
 *                  |                          |
 *                  |                          |
 *                  |                          |
 *                  +--------------------------+
 *
 * Edge case
 *
 *         +--------------------------+
 *         |                          |
 *         |                          |
 *         |                          |
 * +-----------------+  +------------------+  +--------------------+
 * |       |         |  |             |    |  |                    |
 * |       |         |  |             |    |  |                    |
 * |       |         |  |             |    |  |                    |
 * |       |         |  |             |    |  |                    |
 * |       |         |  |             |    |  |                    |
 * |       |         |  |             |    |  |                    |
 * |       |         |  |             |    |  |                    |
 * |       |         |  |             |    |  |                    |
 * |       |         |  |             |    |  |                    |
 * |       |         |  |             |    |  |                    |
 * |       |         |  |             |    |  |                    |
 * +-----------------+  +------------------+  +--------------------+
 *         |                          |
 *         | +----------------------+ |
 *         +----|                  |----+                +---+
 *         | +----------------------+ |
 *         |                          |
 *         |                          |
 *         |                          |
 *         |                          |
 *         |                          |
 *         |                          |
 *         +--------------------------+
 */

const ItemsContainer = styled.div<{ large: boolean }>`
  height: 284px;
  @media (max-height: 500px) {
    height: 200px;
  }
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  ${({ large }) =>
    large &&
    css`
      padding: 0;
      min-width: 375px;
      min-height: 260px;
    `}
`;
const ScrollableItem = styled.div<{ disabled: boolean }>`
  height: 100%;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  image-orientation: from-image;
  top: 0;
  left: 0;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    image-orientation: from-image;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;
const LeftItem = styled(ScrollableItem)`
  left: -100%;
`;
const RightItem = styled(ScrollableItem)`
  left: -100%;
`;

const basePoseProps = {
  center: { x: "0%", transition: "linear" },
  left: { x: "-100%", transition: "linear" },
  right: { x: "100%", transition: "linear" },
};

// Parses string-formatted values, like "-82.9239%" to -82.9239
const readPosePercent = (val: string) => Number(val.slice(0, -1));
// Formats as a percent string, like 100 => "100%"
const formatPosePercentString = (val: number) => `${val}%`;

const Left = posed(LeftItem)({
  ...basePoseProps,
  passive: {
    x: ["x", (x: number) => x, true],
    scale: [
      "x",
      pipe(readPosePercent, interpolate([-100, 0, 100], [0.6, 0.6, 1])),
      true,
    ],
    opacity: [
      "x",
      pipe(readPosePercent, interpolate([-100, 0, 100], [0.1, 0.1, 1])),
      true,
    ],
  },
});

const Right = posed(RightItem)({
  ...basePoseProps,
  passive: {
    x: [
      "x",
      pipe(readPosePercent, (x: number) => x + 200, formatPosePercentString),
      true,
    ],
    scale: [
      "x",
      pipe(readPosePercent, interpolate([-100, 0, 100], [1, 0.6, 0.6])),
      true,
    ],
    opacity: [
      "x",
      pipe(readPosePercent, interpolate([-100, 0, 100], [1, 0.1, 0.1])),
      true,
    ],
  },
});

// TODO: check if Img needs to use tricks from RotatedImage to have correct rotation

const Draggable = posed(ScrollableItem)({
  ...basePoseProps,
  draggable: "x",
  dragBounds: { left: "-100%", right: "100%" },
  passive: {
    scale: [
      "x",
      pipe(readPosePercent, interpolate([-100, 0, 100], [0.6, 1, 0.6])),
      true,
    ],
    opacity: [
      "x",
      pipe(readPosePercent, interpolate([-100, 0, 100], [0.1, 1, 0.1])),
      true,
    ],
  },
});

const IndicatorsRow = styled.div<{ slider: boolean }>`
  height: 2px;
  margin: 0 28px;
  background: var(--grey100);
  overflow: hidden;
  margin-bottom: 19px;
  position: relative;
  ${(props) =>
    props.slider &&
    css`
      position: absolute;
      margin-bottom: 0;
      margin-top: 156px;
      width: calc(100% - 56px);
    `}
`;
const Counter = styled.div`
  line-height: 25px;
  border-radius: 50px;
  background-color: var(--grey900);
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding: 0px 10px;
  bottom: 20px;
  left: 28px;
  text-align: center;
  width: 2.5rem;
  margin: 0 auto;
`;
const ScrollerContainer = styled.div`
  position: relative;
`;
const ScrollerCounter = styled.div`
  position: absolute;
  line-height: 25px;
  background-color: var(--grey900);
  font-size: 12px;
  font-weight: bold;
  color: white;
  text-align: left;
  margin-top: 120px;
  margin-left: 29px;
  margin-bottom: 20px;
`;

const getIndicator = (offset = 0) => {
  const SelectedIndicator = styled.span.attrs(
    ({ current, total }: { current: number; total: number }) => ({
      style: { left: `${(current * 100) / total + offset * 100}%` },
    })
  )`
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ total }: { total: number }) => 100 / total}%;
    height: 2px;
    transform-origin: 0 50%;
    background: var(--grey800);
  `;

  return posed(SelectedIndicator)({
    passive: {
      x: [
        "x",
        pipe(readPosePercent, (x: number) => -x, formatPosePercentString),
        true,
      ],
    },
  });
};
const MiddleIndicator = getIndicator(0);
const LeftIndicator = getIndicator(-1);
const RightIndicator = getIndicator(1);

function LineIndicators({
  total = 0,
  current = 0,
  parentValues = {} as { x?: ValueReaction },
  label = undefined as JSX.Element | string | undefined,
  slider = false,
}) {
  const passProps = { total, current, parentValues };
  return (
    <ScrollerContainer>
      <ScrollerCounter>{label}</ScrollerCounter>
      <IndicatorsRow slider={slider}>
        {/* @ts-ignore */}
        <LeftIndicator {...passProps} />
        {/* @ts-ignore */}
        <MiddleIndicator {...passProps} />
        {/* @ts-ignore */}
        <RightIndicator {...passProps} />
      </IndicatorsRow>
    </ScrollerContainer>
  );
}

function formatNofM(current = 0, count = 0, ofString = "of") {
  if (!count) return "";
  const n = 1 + (Number(current + count) % count);
  return (
    <>
      <span>{`${n} `}</span>
      <span>{ofString}</span>
      <span>{` ${count} `}</span>
    </>
  );
}

// parse CSS percent like "15%" into a number
const getOffsetNumber = (offset: string) => {
  return Number(offset.slice(0, -1));
};

const isInMovePercentage = (offset: number) => {
  return Math.abs(offset) > 0;
};

export function ImageScroller({
  indicator = "line",
  items = [] as string[],
  images = [] as string[],
  ofString = "of",
  large = false,
  slider = false,
  onClick = (() => null) as Function,
}) {
  const n = items.length || images.length;
  const getItem = (index: number) =>
    items[index] || <img src={images[index]} alt="" />;

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const poseCompleteCallbackRef = React.useRef<Function | null | false>(null);
  const [pose, setPose] = React.useState("center");
  const xRef = React.useRef<ValueReaction>(value("0%"));
  const scrollRef = React.useRef(false);

  const dragEnd = (event: React.MouseEvent) => {
    // @ts-ignore somehow using the private .current is how this was implemented, so let's consider rebuilding this later, and/or maybe reusing a CSS-only implementation -- I have one stashes somewhere //Simon
    const offset = xRef.current.current;
    const off = getOffsetNumber(offset);
    const shouldMove = isInMovePercentage(off);
    if (shouldMove) {
      // we should prevent opening gallery if there was a drag event
      event.stopPropagation();
      xRef.current = value("0%");
      poseCompleteCallbackRef.current = () => {
        xRef.current = value("0%");
        setPose("center");
        setSelectedIndex((selectedIndex + n - off / Math.abs(off)) % n);
      };
      setPose(off < 0 ? "left" : "right");
    } else {
      scrollRef.current = false;
    }
  };

  const onPoseComplete = () => {
    if (poseCompleteCallbackRef.current) {
      poseCompleteCallbackRef.current();
      poseCompleteCallbackRef.current = null;
    }
    scrollRef.current = false;
  };

  const getLeftPose = (pose: string) => (pose === "right" ? "center" : "left");

  const getRightPose = (pose: string) => (pose === "left" ? "center" : "right");

  const getIndexFromOffset = (offset: number) =>
    (selectedIndex + n + offset) % n;

  const leftIndex = getIndexFromOffset(-1);
  const middleIndex = getIndexFromOffset(0);
  const rightIndex = getIndexFromOffset(1);

  const valuesMap = { x: xRef.current };

  if (n === 0) return null;
  if (n === 1) {
    return (
      <ItemsContainer large={large} data-testid="imageScroller">
        {/* @ts-ignore */}
        <Draggable
          key={`center-${selectedIndex}-0}`}
          onDragEnd={dragEnd}
          pose={pose}
          onPoseComplete={onPoseComplete}
          values={valuesMap}
          disabled
          onDragStart={(event) => event.preventDefault()}
        >
          {getItem(0)}
        </Draggable>
      </ItemsContainer>
    );
  }

  return (
    <React.Fragment>
      <ItemsContainer large={large} data-testid="imageScroller">
        {/* @ts-ignore */}
        <Left
          key={`left-${selectedIndex}-${leftIndex}`}
          pose={getLeftPose(pose)}
          parentValues={valuesMap}
        >
          {getItem(leftIndex)}
        </Left>
        {/* @ts-ignore */}
        <Right
          key={`right-${selectedIndex}-${rightIndex}`}
          pose={getRightPose(pose)}
          parentValues={valuesMap}
        >
          {getItem(rightIndex)}
        </Right>
        {/* @ts-ignore */}
        <Draggable
          large={large}
          key={`center-${selectedIndex}-${middleIndex}`}
          onDragEnd={dragEnd}
          pose={pose}
          onClick={() => {
            setTimeout(() => !scrollRef.current && onClick && onClick(), 10);
          }}
          onPoseComplete={onPoseComplete}
          values={valuesMap}
          disabled={pose !== "center"}
          onDragStart={() => {
            scrollRef.current = true;
          }}
        >
          {getItem(middleIndex)}
        </Draggable>
      </ItemsContainer>
      {indicator === "line" && !slider && (
        <LineIndicators
          key={`page-${selectedIndex}`}
          total={n}
          current={selectedIndex}
          parentValues={valuesMap}
          slider={slider}
        />
      )}
      {indicator === "line" && slider && (
        <LineIndicators
          key={`page-${selectedIndex}`}
          total={n}
          current={selectedIndex}
          parentValues={valuesMap}
          slider={slider}
          label={formatNofM(selectedIndex, n, ofString)}
        />
      )}
      {indicator === "number" && n > 1 && (
        <Counter>{formatNofM(selectedIndex, n, ofString)}</Counter>
      )}
    </React.Fragment>
  );
}
