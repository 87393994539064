import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { createGlobalStyle } from "styled-components";
import { MsalProvider } from "@azure/msal-react";
import { msalPublicClientApplication } from "./core/auth/useAuth";
import { fixaColorScheme } from "@coworker/common/config/colorScheme";
import { i18n, I18nextProvider } from "@coworker/locales";
import { BrowserRouter as Router } from "react-router-dom";
import { OopsScreen } from "./components/OopsScreen";
import versionJson from "@coworker/functions/src/version.json";
import * as Sentry from "@sentry/react";
import { shouldReportToSentry } from "@coworker/common/helpers/sentry.helpers";
import { gaService } from "@coworker/common/services/google-analytics.service";
import { isChineseEnvironment } from "@coworker/reusable";

const Global = createGlobalStyle`
  ${() => fixaColorScheme}

  @font-face {
    font-family: 'NotoSansIKEA';
    src: url(/fonts/NotoSansIKEA-Regular.woff2) format('woff2'), url(/fonts/NotoSansIKEA-Regular.woff) format('woff');
    font-display: block;
  }
  @font-face {
    font-family: 'NotoSansIKEA';
    src: url(/fonts/NotoSansIKEA-Bold.woff2) format('woff2'), url(/fonts/NotoSansIKEA-Bold.woff) format('woff');
    font-weight: bold;
    font-display: block;
  }
  @font-face {
    font-family: 'NotoSansIKEA';
    src: url(/fonts/NotoSansIKEA-Italic.woff2) format('woff2'), url(/fonts/NotoSansIKEA-Italic.woff) format('woff');
    font-style: italic;
    font-display: block;
  }
  @font-face {
    font-family: 'NotoSansIKEA';
    src: url(/fonts/NotoSansIKEA-BoldItalic.woff2) format('woff2'), url(/fonts/NotoSansIKEA-BoldItalic.woff) format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: block;
  }
  html {
    background: var(--white);
  }
  body, html, #root {
    height: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    box-shadow: none;
  }
  * {
    -webkit-tap-highlight-color: transparent;
  }
  a  {
  text-decoration: none;
    user-select: none;
    overscroll-behavior-y: contain;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: var(--white);
  }
  ::-webkit-scrollbar-track {
    width: 5px;
    height: 5px;
    background: var(--white);
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0;
  }
  div[data-testid="describeTheProblemWidget"] {
    user-select: auto;
  }
`;

const IS_CHINA = isChineseEnvironment();
function AppContainer() {
  console.log("AppContainer");
  return (
    <MsalProvider instance={msalPublicClientApplication}>
      <I18nextProvider i18n={i18n}>
        <Router>
          <Global />
          <Sentry.ErrorBoundary fallback={(props) => <OopsScreen {...props} />}>
            <App />
          </Sentry.ErrorBoundary>
        </Router>
      </I18nextProvider>
    </MsalProvider>
  );
}

if (
  process.env.REACT_APP_ENV === "prod" &&
  process.env.REACT_APP_PROJECT_ID !== "ikea-coworker-app-prod" // EU prod environment is handled via Firebase Analytics integration
) {
  gaService.init("G-TBK5FH8YF4");
}

window.PHRASEAPP_CONFIG = {
  projectId: "d17e83366cc489540d297fef05accd65",
  prefix: "[[__",
  suffix: "__]]",
  priorityLocales: ["en-GB"],
  fullReparse: true,
  autoLowercase: false,
};
const env = process.env.REACT_APP_ENV;
const sentryDSN = IS_CHINA
  ? "https://5183c3d55f014a8aa06288be2364334c@sentry.ingka-system.cn/101"
  : "https://ccd29981faf3433891c93858c9cded01@o478484.ingest.sentry.io/5407140";
Sentry.init({
  dsn: sentryDSN,
  environment: env,
  maxValueLength: 13337,
  release: versionJson.version,
  enabled: IS_CHINA || shouldReportToSentry(env),
  attachStacktrace: true,
  ignoreErrors: [
    "Network Error",
    "deadline-exceeded",
    "ChunkLoadError",
    "x_content_parse_exception",
    "However, this document does exist",
    "Document already exists",
    "Error: Network Error",
    "Failed to get document because the client is offline.",
    "AbortError: The operation was aborted.",
    "You do not have your profile created.",
    "Uncaught NetworkError",
  ],
});

// Needed so Chrome < 71 (=a few months ago) does not crash when trying to create a task. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/globalThis https://dev.to/lampewebdev/stop-usuing-this-instead-use-globalthis-for-global-variables-2bc1 https://blog.logrocket.com/what-is-globalthis-why-use-it/
if (typeof globalThis === "undefined") window.globalThis = window;

ReactDOM.render(<AppContainer />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

if (JSON.parse(localStorage?.getItem("SERVICEWORKER_ENABLED"))) {
  window.SERVICEWORKER_ENABLED = true;
}

if (
  (process.env.NODE_ENV !== "production" ||
    process.env.REACT_APP_ENV === "ci") &&
  !window.SERVICEWORKER_ENABLED
) {
  serviceWorker.unregister();
} else {
  "serviceWorker" in navigator && serviceWorker.initialize();
}
