import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ListView, { ListViewItem } from "@ingka/list-view";
import tag from "@ingka/ssr-icon/paths/tag";
import sparkles from "@ingka/ssr-icon/paths/clean-sparkles";
import camera from "@ingka/ssr-icon/paths/camera";
import star from "@ingka/ssr-icon/paths/star-filled";
import starOutline from "@ingka/ssr-icon/paths/star";
import paintBrush from "@ingka/ssr-icon/paths/paintbrush";
import flag from "@ingka/ssr-icon/paths/flag";
import stairs from "@ingka/ssr-icon/paths/stairs";
import nursing from "@ingka/ssr-icon/paths/nursing";
import bike from "@ingka/ssr-icon/paths/bike";
import home from "@ingka/ssr-icon/paths/home";
import locationPinSmall from "@ingka/ssr-icon/paths/location-pin-small";
import { useTranslation } from "@coworker/locales";
import { useArticleById } from "../hooks/useArticleById";
import { RoomImagesListItem } from "../Molecules/RoomImagesListItem";
import { Room } from "../types/room";
import { DeleteRoomModal } from "./Modals/DeleteRoomModal";
dayjs.extend(relativeTime);

interface RoomsetSettingsListProps {
  room: Room;
}

export const RoomsetSettingsList = ({ room }: RoomsetSettingsListProps) => {
  const { t } = useTranslation();
  const { data: firstMainProduct } = useArticleById(room.main_article_id_1);
  const { data: secondMainProduct } = useArticleById(room.main_article_id_2);
  const { data: backupArticle1 } = useArticleById(room.backup_article_id_1);
  const { data: backupArticle2 } = useArticleById(room.backup_article_id_2);

  const createdAtString = `${dayjs().to(dayjs(room.created_at))} (${
    room.created_at.split("T")[0] ?? ""
  })`;

  return (
    <>
      <ListView id="room-settings-list" size={"medium"}>
        <ListViewItem
          title={t("mediaNameString")}
          leadingIcon={tag}
          description={room.name}
          emphasised
          inset
        />
        <ListViewItem
          title={t("createdString")}
          leadingIcon={sparkles}
          description={createdAtString}
          emphasised
          inset
        />
        <ListViewItem
          title={t("photosString")}
          leadingIcon={camera}
          emphasised
          inset
        />
        <RoomImagesListItem room={room} />
        <ListViewItem
          title={`${t("mainProductBasicString")} #1`}
          leadingIcon={star}
          description={`${room.main_article_name_1} ${
            firstMainProduct?.itemType ?? ""
          } ${firstMainProduct?.itemColor ?? ""}`}
          emphasised
          inset
        />
        <ListViewItem
          title={`${t("backupForProductString")} #1`}
          leadingIcon={starOutline}
          description={
            backupArticle1
              ? `${room.backup_article_name_1} ${
                  backupArticle1?.itemType ?? ""
                } ${backupArticle1?.itemColor ?? ""}`
              : ""
          }
          emphasised
          inset
        />
        <ListViewItem
          title={`${t("mainProductBasicString")} #2`}
          leadingIcon={star}
          description={`${room.main_article_name_2} ${
            secondMainProduct?.itemType ?? ""
          } ${secondMainProduct?.itemColor ?? ""}`}
          emphasised
          inset
        />
        <ListViewItem
          title={`${t("backupForProductString")} #2`}
          leadingIcon={starOutline}
          description={
            backupArticle2
              ? `${room.backup_article_name_2} ${
                  backupArticle2?.itemType ?? ""
                } ${backupArticle2?.itemColor ?? ""}`
              : ""
          }
          emphasised
          inset
        />
        <ListViewItem
          title={t("mediaStoryString")}
          description={room.media_story ?? ""}
          emphasised
          inset
        />
        <ListViewItem
          title={t("styleGroupString")}
          leadingIcon={paintBrush}
          description={room.style_group}
          emphasised
          inset
        />
        <ListViewItem
          title={t("livingSituationString")}
          leadingIcon={nursing}
          description={room.living_situation ?? ""}
          emphasised
          inset
        />
        <ListViewItem
          title={t("longtermPriorityString")}
          leadingIcon={flag}
          description={room.hf_ltp}
          emphasised
          inset
        />
        <ListViewItem
          title={t("priceLevelString")}
          leadingIcon={stairs}
          description={room.pricing}
          emphasised
          inset
        />
        <ListViewItem
          title={t("lifeHomeActivityString")}
          leadingIcon={bike}
          description={room.laha ?? ""}
          emphasised
          inset
        />
        <ListViewItem
          title={t("livingConditionsString")}
          leadingIcon={home}
          description={room.living_conditions ?? ""}
          emphasised
          inset
        />
        <ListViewItem
          title={t("hfbLocationInStoreString")}
          leadingIcon={locationPinSmall}
          description={room.hfb ?? ""}
          emphasised
          inset
        />
      </ListView>
      <DeleteRoomModal room={room} />
    </>
  );
};
