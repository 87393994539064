import React from "react";
import { Trans } from "@coworker/locales";
import { SkapaPill } from "../../SkapaPill";
import filtersIcon from "@ingka/ssr-icon/paths/filters";
import styled from "styled-components";
import dayjs from "dayjs";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";

const PillWrapper = styled.div`
  display: flex;
  padding: 0.8rem 0rem 0.8rem 0.5rem;
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Filters = ({ showPeriod }: { showPeriod: boolean }) => {
  const { push } = useWorkspacesAction();

  const activePeriod =
    localStorage.getItem("shoppingToolsPeriodFilter") ?? "today";
  const customStartDate = localStorage.getItem(
    "shoppingToolsStartDatePeriodFilter"
  );
  const customEndDate = localStorage.getItem(
    "shoppingToolsEndDatePeriodFilter"
  );
  const activeArea =
    localStorage.getItem("shoppingToolsAreaFilter") ?? "wholeStore";

  return (
    <PillWrapper>
      <SkapaPill
        label={<Trans>filtersString</Trans>}
        ssrIcon={filtersIcon}
        style={{
          background: "white",
          border: "1px solid black",
          boxSizing: "border-box",
          marginRight: "10px",
        }}
        onClick={() => push("/shoppingtools/filters")}
      />
      {showPeriod && (
        <SkapaPill
          style={{
            marginRight: "10px",
          }}
          label={
            activePeriod === "customTitle" ? (
              `${dayjs(customStartDate).format("DD/MM")} - ${dayjs(
                customEndDate
              ).format("DD/MM")}`
            ) : (
              <Trans>{activePeriod}String</Trans>
            )
          }
          onClick={() => push("/shoppingtools/filters")}
        />
      )}
      <SkapaPill
        style={{
          marginRight: "1rem",
        }}
        label={<Trans>{activeArea}String</Trans>}
        onClick={() => push("/shoppingtools/filters")}
      />
    </PillWrapper>
  );
};
