import React, { createRef } from "react";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import Search from "@ingka/search";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/search/dist/style.css";
import "@ingka/focus/dist/style.css";
import { LoaderIcon } from "@coworker/reusable/Loader";
import {
  Title,
  Container as BaseContainer,
  OverflowBackground,
} from "./CommonComponents";
import { SkapaModalHeader } from "../SkapaModalHeader";
import useAmountConfirmation from "./shared/useAmountConfirmation";
import useDuplicateTaskPopup from "./shared/useDuplicateTaskPopup";
import { useAvailableElevatedStock } from "../../hooks/useAvailableElevatedStock";
import { StockInfoPillsWithRelatedTasks } from "./StocknfoPillsWithRelatedTasks";
import { useTasksForProduct } from "../../hooks/useTasksForProduct";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import tracker from "../../helpers/tracker";
import {
  LOCATION_TYPE_PICKUP,
  LOCATION_TYPE_REFILL,
} from "../../constants/locationWidgetTypes";
import {
  useRecentCustomLocations,
  useNonSLMFoodLocation,
  useAllSalesLocationsForArticleQuery,
} from "../../services/locations.service";

import { LocationGroup } from "./LocationPopup/LocationGroup";
import { locationTypes } from "../../constants/addOnLocationTypes";
import AddCustomLocationCTAButton from "./AddCustomLocationCTAButton";
import AddCustomLocation from "./LocationPopup/AddCustomLocation/AddCustomLocation";
import SlmSuggestionLocations from "./LocationPopup/AddCustomLocation/SlmSuggestionLocations";

function isOpenAddon(task: any) {
  return (
    task.task_type === taskTypeOptions.ADDON &&
    ["ASSIGNED", "UNASSIGNED"].includes(task.state)
  );
}

const Container = styled(BaseContainer)`
  height: 85%;
`;
const StockInfoContainer = styled.div`
  margin: 12px 0px 0px 24px;
`;
const SearchContainer = styled.div`
  margin: 24px;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const MainText = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const SubText = styled.div`
  font-size: 14px;
`;

function EmptyLocationsList() {
  const { t } = useTranslation();
  return (
    <LoaderContainer>
      <MainText>{t("noLocationsAvailableString")}</MainText>
      <SubText>{t("noLocationsAvailableHelperString")}</SubText>
    </LoaderContainer>
  );
}

function getHeaderTitleString(addon: any) {
  switch (addon) {
    case LOCATION_TYPE_PICKUP:
      return "pickupLocation";
    case LOCATION_TYPE_REFILL:
      return "refillLocString";
    default:
      return "";
  }
}

function useGroupedFilteredLocations(
  fullId: string,
  query: string,
  isSFM: boolean,
  isFoodServices: boolean,
  isCPG: boolean
) {
  const { data: salesLocations, isLoading: isLocationsLoading } =
    useAllSalesLocationsForArticleQuery(fullId);

  const hasNoSLMLocations = salesLocations?.length <= 0;

  const { sfmLocationList } = useNonSLMFoodLocation(
    isSFM,
    isFoodServices,
    isCPG,
    hasNoSLMLocations
  );

  const isFoodArticle = isFoodServices || isCPG || isSFM;

  const articleLocations =
    hasNoSLMLocations && isFoodArticle ? sfmLocationList : salesLocations;
  const loader = hasNoSLMLocations ? false : isLocationsLoading;

  return React.useMemo(() => {
    const keyword = query.toLowerCase();
    const mainLocations = [];
    const otherLocations = [];
    const sgfLocations = [];
    if (!loader) {
      for (const item of articleLocations) {
        const base = `${item.storeLocation || item.location} ${
          item.locationDescription || item.descriptive
        }`.toLocaleLowerCase(); // checking for both properties, since internal proxy and tasks service return different types
        if (!base.includes(keyword.toLocaleLowerCase())) continue;
        const {
          location,
          quantity,
          locationDescription, // type returned from internalProxy, can be removed later
          descriptive, // type returned by tasks service
          isPrimary,
          isHomeBase,
          isHome,
          isSGF,
        } = item;
        const frontendFriendlyObject = {
          code: location,
          quantity: hasNoSLMLocations ? "" : Number(quantity),
          section: locationDescription || descriptive,
          department: "",
          isPrimary,
          isHomeBase: isHome || isHomeBase,
        };

        if (isPrimary || isHomeBase || isHome) {
          mainLocations.push(frontendFriendlyObject);
        } else if (isSGF) {
          sgfLocations.push(frontendFriendlyObject);
        } else {
          otherLocations.push(frontendFriendlyObject);
        }
      }
    }
    return {
      mainLocations,
      otherLocations,
      sgfLocations,
      isLocationsLoading: loader,
    };
  }, [articleLocations, loader, query, hasNoSLMLocations]);
}

type LocationObject = {
  locationCode: string;
  locationSection: string;
  locationDepartment: string;
  locationQuantity: number;
  relatedOnThisLocation: number;
  locationCustom?: string;
};

interface LocationProps {
  title: string;
  product_article_id: string;
  product_article_type: string;
  requestedAmount: number;
  onSubmit: (locationObject: LocationObject) => void;
  onClose: () => void;
  addon: any;
  locationCode: string;
  isSFM: boolean;
  isFoodServices: boolean;
  isCPG: boolean;
}

export default function Location({
  title,
  product_article_id = "",
  product_article_type = "",
  requestedAmount,
  onSubmit,
  onClose,
  addon,
  locationCode,
  isSFM,
  isFoodServices,
  isCPG,
}: LocationProps) {
  const { t } = useTranslation();
  const { pop } = useWorkspacesAction();
  const amountConfirmation = useAmountConfirmation();
  const productFullId =
    !!product_article_type && !!product_article_id
      ? `${product_article_type}${product_article_id}`
      : "";
  const { available } = useAvailableElevatedStock(productFullId);
  const { getDuplicateTaskConfirmation } = useDuplicateTaskPopup();

  // For other tasks (e.g. product issue), requestedAmount is not sent in, so its type is undefined
  const isAddonTask = typeof requestedAmount === "number";

  const searchInputRef = createRef<HTMLInputElement>();

  const handleClick = () => {
    if (searchInputRef.current) {
      searchInputRef?.current?.focus();
    }
  };

  const [query, setQuery] = React.useState("");

  const customLocations = useRecentCustomLocations(
    query,
    product_article_id,
    false
  );

  const hasCustomLocations = customLocations?.length > 0;

  const customLocationsObjects = hasCustomLocations
    ? customLocations.map((customLocation: string) => {
        return {
          code: customLocation,
        };
      })
    : [];

  const { mainLocations, otherLocations, sgfLocations, isLocationsLoading } =
    useGroupedFilteredLocations(
      productFullId,
      query,
      isSFM,
      isFoodServices,
      isCPG
    );

  const isFoodArticle = isFoodServices || isCPG || isSFM;

  const locationGroupsList = [
    {
      locationSectionTitle: locationTypes.HOME_OR_PRIMARY,
      locationsList: mainLocations,
    },
    {
      locationSectionTitle: locationTypes.OTHER_LOCATION,
      locationsList: otherLocations,
    },
    {
      locationSectionTitle: locationTypes.SGF_LOCATION,
      locationsList: sgfLocations,
    },
    {
      locationSectionTitle: locationTypes.CUSTOM_LOCATION,
      locationsList: customLocationsObjects,
      isCustom: true,
    },
  ].filter(
    (item) => Array.isArray(item.locationsList) && item.locationsList.length > 0
  );
  const {
    list: tasksForProduct,
    formattedCount,
    ofWhichClosed,
  } = useTasksForProduct(product_article_id);

  const relatedAddons = React.useMemo(() => {
    const relatedAddons: any = {};
    if (!tasksForProduct.forEach) return relatedAddons;
    tasksForProduct.forEach((task) => {
      if (isOpenAddon(task)) {
        const key = task.location_grid_code;
        const current = relatedAddons[key] || [];
        current.push(task);
        relatedAddons[key] = current;
      }
    });
    return relatedAddons;
  }, [tasksForProduct]);

  const submitWithConfirmation = React.useCallback(
    async (locationObject) => {
      if (
        requestedAmount &&
        !locationObject?.locationCustom &&
        !(await amountConfirmation(
          requestedAmount,
          locationObject?.locationQuantity,
          available,
          () => {}
        ))
      ) {
        return;
      }

      const homeLocationObj = mainLocations?.find(
        (location: any) => location.isHomeBase
      );
      const primaryLocationObj = mainLocations?.find(
        (location: any) => location.isPrimary
      );

      const homeandPrimary = {
        homeLocation: homeLocationObj?.code as string,
        primaryLocation: primaryLocationObj?.code as string,
      };
      onSubmit({ ...locationObject, ...homeandPrimary });

      const { relatedOnThisLocation, locationCode } = locationObject || {};
      if (isAddonTask && relatedOnThisLocation > 0) {
        tracker.taskEfficiency.trackWarnDuplicate();
        const response = await getDuplicateTaskConfirmation(
          relatedOnThisLocation,
          product_article_id,
          locationCode
        );
        return response === false ? pop() : true;
      }
    },
    // (Ignored main Locations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      available,
      requestedAmount,
      amountConfirmation,
      onSubmit,
      isAddonTask,
      getDuplicateTaskConfirmation,
      product_article_id,
      pop,
      isSFM,
    ]
  );

  const titleString = getHeaderTitleString(addon);
  return (
    <>
      <OverflowBackground
        onClick={() => {
          tracker.trackDismissModal(false);
          onClose();
        }}
      />
      <Container>
        {titleString && (
          <SkapaModalHeader titleString={titleString} onClose={onClose} />
        )}
        <StockInfoContainer>
          <StockInfoPillsWithRelatedTasks
            relatedTasksCount={formattedCount}
            ofWhichClosed={ofWhichClosed}
            productFullId={productFullId}
            productId={product_article_id}
            navigateToTaskForm={false}
            onClose={onClose}
          />
        </StockInfoContainer>
        {title && <Title>{title}</Title>}
        <SearchContainer>
          <Search
            ref={searchInputRef}
            placeholder={t("enterLocation2String")}
            data-testid="gridCodeQuery"
            onChange={(e) => {
              setQuery(e.target.value.toLocaleUpperCase());
            }}
            onClear={() => {
              setQuery("");
            }}
            id="searchLocationPopup"
          />
        </SearchContainer>
        {query && (
          <AddCustomLocation
            submitWithConfirmation={submitWithConfirmation}
            query={query}
          ></AddCustomLocation>
        )}
        {isFoodArticle && !isLocationsLoading
          ? false
          : isLocationsLoading && (
              <LoaderContainer>
                <LoaderIcon />
              </LoaderContainer>
            )}
        {locationGroupsList.map((locationGroup) => {
          return (
            <LocationGroup
              isCustomLocation={!!locationGroup.isCustom}
              key={locationGroup.locationSectionTitle}
              locationGroupTitle={locationGroup.locationSectionTitle}
              locationsList={locationGroup.locationsList}
              relatedAddons={relatedAddons}
              selectedLocationCode={locationCode}
              submitWithConfirmation={submitWithConfirmation}
            />
          );
        })}
        {query.length > 1 && (
          <SlmSuggestionLocations
            submitWithConfirmation={submitWithConfirmation}
            query={query}
          ></SlmSuggestionLocations>
        )}
        {query.length < 1 && (
          <AddCustomLocationCTAButton onClick={handleClick} />
        )}
        {!isLocationsLoading &&
          !customLocations.length &&
          !mainLocations?.length &&
          !otherLocations?.length && <EmptyLocationsList />}
      </Container>
    </>
  );
}
