import type { Store } from "@coworker/types/lib/store";
import { useQuery } from "@tanstack/react-query";
import { coreServiceBaseUrl } from "@coworker/functions/src/triggers/cloud-services.helper";
import { getEnvironmentId, isChineseEnvironment } from "@coworker/reusable";
import { INTERVAL } from "../../constants/clientTime";
import { makeServiceHeaders } from "./fetch.helpers";

const isChina = isChineseEnvironment();

export const CORE_SERVICE_URL = coreServiceBaseUrl(getEnvironmentId(), isChina);

async function fetchAllStores(): Promise<Store[]> {
  console.log("Fetching stores, isChina is ", isChina);
  const response = await fetch(`${CORE_SERVICE_URL}/stores`, {
    headers: await makeServiceHeaders(),
  });
  if (!response.ok) {
    throw new Error("Bad network response when fetching all stores!");
  }
  const storesFromApi = await response.json();

  return storesFromApi.map((store: any) => ({
    id: store.storeId,
    ...store,
  }));
}

export function useAllStoresQuery() {
  return useQuery<Store[]>({
    queryKey: ["allStores"],
    queryFn: fetchAllStores,
    staleTime: 1 * INTERVAL.HOUR, // Since this data does not change frequently, it can stay fresh for a long time
  });
}
