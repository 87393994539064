import React from "react";
import styled from "styled-components";
import { ShoppingToolsQuantityStepper } from "../Components/ShoppingToolsQuantityStepper";
import SSRIcon from "@ingka/ssr-icon";
import { Trans } from "@coworker/locales";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { ShoppingToolType, TOTAL_MAX_VALUE } from "../constants";
import { useCreateShoppingTool } from "../Hooks/useCreateShoppingTool";
import { useDeleteShoppingTool } from "../Hooks/useDeleteShoppingTool";

interface ShowroomQuantityCardProps {
  type: ShoppingToolType;
  round: string;
  division: string;
  startValue: number;
  currentTotalValue: number;
  setCurrentTotalValue: React.Dispatch<React.SetStateAction<number>>;
}

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: 8px solid var(--grey200);
  height: 60px;
  padding-left: 15px;
  &:first-child {
    padding-top: 10px;
  }
  &:last-child {
    padding-bottom: 10px;
  }
`;

export const SubTitle = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

export const ShowroomQuantityCard = ({
  type,
  round,
  division,
  startValue,
  currentTotalValue,
  setCurrentTotalValue,
}: ShowroomQuantityCardProps) => {
  let [currentToolValue, setCurrentToolValue] = React.useState<number>(0);

  const createShoppingToolMutation = useCreateShoppingTool();
  const deleteShoppingToolMutation = useDeleteShoppingTool();
  const storeId = useStoreId();

  React.useEffect(() => {
    setCurrentToolValue(startValue);
  }, [setCurrentToolValue, startValue]);

  return (
    <CardWrapper key={type.label}>
      <ShoppingToolsQuantityStepper
        value={currentToolValue}
        small
        minVal={0}
        maxVal={TOTAL_MAX_VALUE}
        ariaDescribedById="unique-dom-id"
        ariaDescribedBy="Type in a number or use arrow up or arrow down to change the quantity"
        onIncrease={() => {
          createShoppingToolMutation.mutateAsync({
            roundName: round,
            division: division,
            storeId: storeId,
            initialShoppingTools: { [type.name ?? ""]: 1 },
          });
          currentToolValue++;
          setCurrentToolValue(currentToolValue);
          currentTotalValue++;
          setCurrentTotalValue(currentTotalValue);
        }}
        onDecrease={() => {
          deleteShoppingToolMutation.mutateAsync({
            roundName: round,
            division: division,
            storeId: storeId,
            initialShoppingTools: { [type.name ?? ""]: 1 },
          });
          currentToolValue--;
          setCurrentToolValue(currentToolValue);
          currentTotalValue--;
          setCurrentTotalValue(currentTotalValue);
        }}
      />
      <SSRIcon paths={type.icon!} style={{ margin: "0px 10px" }} />
      <SubTitle style={{ margin: !type.icon ? "0px 10px" : 0 }}>
        <Trans>{type.label}</Trans>
      </SubTitle>
    </CardWrapper>
  );
};
