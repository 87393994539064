import React from "react";
import {
  CenterContainer,
  LeftContainer,
  ListItemContainer,
  RightContainer,
} from "../styles/styles";

interface Props {
  headLine: React.ReactNode;
  leftIcon: React.ReactNode;
  subText: React.ReactNode;
  rightIcon?: React.ReactNode;
  onClick?: () => void;
}

export function TaskCard({
  headLine,
  leftIcon,
  subText,
  rightIcon,
  onClick,
}: Props) {
  return (
    <ListItemContainer onClick={onClick}>
      <LeftContainer>{leftIcon}</LeftContainer>
      <CenterContainer $centerText={false}>
        {headLine}
        {subText}
      </CenterContainer>
      <RightContainer>{rightIcon}</RightContainer>
    </ListItemContainer>
  );
}
