import React, { useRef } from "react";
import { useUploadImageToCloud } from "../hooks/useUploadImageToCloud";
import { useAddImageToRoom } from "../hooks/useAddImageToRoom";
import { Image } from "../types/images";
import Button from "@ingka/button";
import plus from "@ingka/ssr-icon/paths/plus";
import { useTranslation } from "@coworker/locales";

interface UploadImageButtonProps {
  roomId: string;
  text?: string;
}

export const UploadImageButton = ({ roomId, text }: UploadImageButtonProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const uploadImageToCloud = useUploadImageToCloud();
  const addImageToRoom = useAddImageToRoom(roomId);
  const { t } = useTranslation();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    for (const fileUploaded of event.target.files) {
      if (!fileUploaded) continue;

      const data = await uploadImageToCloud.mutateAsync(fileUploaded);
      if (!data) continue;

      const imageToAdd: Image = {
        roomId,
        fileName: data.file_name,
        folder: data.folder,
        url: data.signed_url,
        rotation: 0,
      };

      try {
        await addImageToRoom.mutateAsync(imageToAdd);
      } catch (error) {
        alert(t("backendOperationFailedString"));
      }
    }
  };

  const handleButtonClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  return (
    <>
      {text ? (
        <Button
          type={"primary"}
          onClick={handleButtonClick}
          loading={uploadImageToCloud.isLoading}
          text={text ?? ""}
        />
      ) : (
        <Button
          type={"secondary"}
          onClick={handleButtonClick}
          loading={uploadImageToCloud.isLoading}
          ssrIcon={plus}
          iconOnly
        />
      )}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
        multiple
      />
    </>
  );
};
