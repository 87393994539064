import React from "react";
import styled from "styled-components";
import { PositiveNumberKeypad } from "../PositiveNumberKeypad";

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;
  top: 0;
  left: 0;
  background: var(--white);
  margin-top: 50px;
  overflow-y: auto;
`;
const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
  color: var(--grey900);
  margin: 55px 29px;
  margin-bottom: 0px;
`;

const AmountWrapper = styled.input`
  margin: 10px 29px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid var(--grey900);
  font-size: 16px;
`;

const Number = ({ value, bigTitle, instruction, onSubmit }) => {
  const [chosen, setChosen] = React.useState(value || "");
  const containerRef = React.useRef(null);

  return (
    <Container ref={containerRef} data-testid="dateStampModal">
      <Title>{bigTitle}</Title>

      <AmountWrapper value={chosen} readOnly data-testid="dateStampInput" />

      <PositiveNumberKeypad
        initialValue={chosen}
        disabled={!chosen}
        onUpdate={(value) => setChosen(value.slice(0, 11))}
        onComplete={() => {
          onSubmit(chosen);
        }}
        completeTestid="dateStampButton"
      />

      {instruction}
    </Container>
  );
};

export default Number;
