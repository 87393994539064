import React from "react";
import { Trans } from "@coworker/locales";
import { TitleHeader } from "../../TestBuy/CommonComponents/TitleHeader/TitleHeader";
import { NewReview } from "./NewReview";
import { ChartCard } from "../Chart/ChartCard";
import { Filters } from "../Filters/Filters";
import styled from "styled-components";

const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--grey150);
`;

export const ShoppingToolsOverview = () => {
  const activeArea =
    localStorage.getItem("shoppingToolsAreaFilter") ?? "wholeStore";
  return (
    <div>
      <TitleHeader
        title={<Trans>shoppingToolsHeaderString</Trans>}
        navigateToLink={"/shoppingtools/settings"}
        showSettings
      />
      <Filters showPeriod />
      <NewReview />
      {(activeArea === "showroomOTW" || activeArea === "wholeStore") && (
        <ChartCard
          title={<Trans>showroomOTWString</Trans>}
          showProgress
          area={"showroom"}
        />
      )}
      {activeArea === "wholeStore" && <Line />}
      {(activeArea === "markethallOTW" || activeArea === "wholeStore") && (
        <ChartCard
          title={<Trans>markethallOTWString</Trans>}
          showProgress
          area={"markethall"}
        />
      )}
      {activeArea === "wholeStore" && <Line />}
      {(activeArea === "otherAreaCashline" || activeArea === "wholeStore") && (
        <ChartCard
          title={<Trans>otherAreaCashlineString</Trans>}
          area={"cashline"}
        />
      )}
    </div>
  );
};
