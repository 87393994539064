import React from "react";
import Modal, { ModalHeader, ModalFooter, Sheets } from "@ingka/modal";
import { Article } from "../../types/article";
import { ListWrapper } from "../../styles/styles";
import { ArticleListItem } from "../../Molecules/ArticleListItem";
import { useTranslation } from "@coworker/locales";
import { FullWidthButton } from "../../../SkapaButton";
import { ModalTypes } from "../../types/views";
import Toast from "@ingka/toast";
import { logEvent } from "../../../../helpers/tracker";

interface PTagArticleSummaryForClipboardModalProps {
  selectedArticles: Article[];
  modalVisibility: boolean;
  onTriggerNewModal: (modalType: ModalTypes) => void;
}

export const PTagArticleSummaryForClipboardModal = ({
  selectedArticles,
  modalVisibility,
  onTriggerNewModal,
}: PTagArticleSummaryForClipboardModalProps) => {
  const { t } = useTranslation();
  const [toastVisible, setToastVisible] = React.useState(false);
  const [modalTypeForXButton, setModalTypeForXButton] =
    React.useState<ModalTypes>(ModalTypes.KEEP_SELECTION);

  const articlesWithoutPieces = selectedArticles.map((article) => {
    return { ...article, nbrArticles: 0 };
  });

  return (
    <Modal
      visible={modalVisibility}
      handleCloseBtn={() => {
        setToastVisible(false);
        onTriggerNewModal(modalTypeForXButton);
      }}
    >
      <Sheets
        labelledById="selectedArticlesActions"
        size={"small"}
        header={
          <ModalHeader
            title={`${selectedArticles?.length ?? 0} ${t(
              "articlesLowerCaseString"
            )}`}
            ariaCloseTxt="Close"
          />
        }
        footer={
          <ModalFooter>
            <FullWidthButton
              text={t("copyAllArticleNumbersString")}
              type={"primary"}
              onClick={() => {
                navigator.clipboard.writeText(
                  articlesWithoutPieces
                    .map((article) => article.productArticleId)
                    .join("\n")
                );
                logEvent("ce:copyAllArticleNumbers");
                setToastVisible(true);
                setModalTypeForXButton(ModalTypes.DEFAULT_VIEW);
              }}
            />
          </ModalFooter>
        }
      >
        <>
          <ListWrapper>
            {articlesWithoutPieces.map((article) => (
              <ArticleListItem
                key={article.id}
                article={article}
                hideChevron={true}
              />
            ))}
          </ListWrapper>
          {toastVisible && (
            <Toast
              text={t("articleNumbersCopiedToClipboardString")}
              isOpen={toastVisible}
              onCloseRequest={() => {
                setToastVisible(false);
              }}
            />
          )}
        </>
      </Sheets>
    </Modal>
  );
};
