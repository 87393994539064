import React from "react";
import QuantityStepper from "@ingka/quantity-stepper";

import "@ingka/focus/dist/style.css";
import "@ingka/quantity-stepper/dist/style.css";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";

interface QuantityStepperProps {
  value: number;
  small: boolean;
  minVal: number;
  maxVal: number;
  ariaDescribedById: string;
  ariaDescribedBy: string;
  onIncrease: (...args: void[]) => void;
  onDecrease: (...args: void[]) => void;
}

export function ShoppingToolsQuantityStepper({
  value,
  small,
  minVal,
  maxVal,
  ariaDescribedById,
  ariaDescribedBy,
  onIncrease,
  onDecrease,
}: QuantityStepperProps) {
  return (
    <QuantityStepper
      value={value}
      small={small}
      minVal={minVal}
      maxVal={maxVal}
      ariaDescribedById={ariaDescribedById}
      ariaDescribedBy={ariaDescribedBy}
      onIncrease={onIncrease}
      onDecrease={onDecrease}
    />
  );
}
