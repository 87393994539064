import useCallInternal, {
  MFAQ_ALL_QUESTION,
  MFAQ_GET_QUESTION,
  MFAQ_SEARCH_QUESTION,
  MFAQ_CREATE_VOTE,
  callInternal,
  MFAQ_GET_TOPQUESTIONS,
  MFAQ_GET_OTHERQUESTIONS,
  MFAQ_EDIT_QUESTION,
  MFAQ_MERGE_QUESTION,
  MFAQ_CREATE_QUESTION,
  MFAQ_AGGREGATED_VOTES,
  MFAQ_AGGREGATED_TASKS,
} from "../../hooks/API/useCallInternal";
import { areaIsWholeStore } from "../InputPopup/UserFiltersRow/filterChoices";
import tracker from "../../helpers/tracker";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import { useStoreId } from "../../core/auth/useLoggedInUser";

const { SHOW_QUESTIONS_WITH_TASKS, MFAQ_PREFIX } = profilePreferences;
export function useMFAQs(since, until) {
  const store_id = useStoreId();

  return useCallInternal(MFAQ_ALL_QUESTION, { store_id, since, until });
}

export function useGetOtherQuestions(since, until, area, from) {
  const [onlyQuestionsWithTasks] = useUserPreference(
    MFAQ_PREFIX + SHOW_QUESTIONS_WITH_TASKS
  );
  const store_id = useStoreId();
  return useCallInternal(MFAQ_GET_OTHERQUESTIONS, {
    store_id,
    since,
    until,
    area,
    from,
    onlyQuestionsWithTasks,
  });
}

export function useGetTopQuestions(since, until, area) {
  const [onlyQuestionsWithTasks] = useUserPreference(
    MFAQ_PREFIX + SHOW_QUESTIONS_WITH_TASKS
  );

  const store_id = useStoreId();
  return useCallInternal(MFAQ_GET_TOPQUESTIONS, {
    store_id,
    since,
    until,
    area,
    onlyQuestionsWithTasks,
  });
}

export function useMFAQ(question_id, since, until) {
  const response = useCallInternal(
    MFAQ_GET_QUESTION,
    { question_id, since, until },
    { blockRequest: !question_id || !since || !until }
  );
  return response.data;
}

/**
 * @param {string} searchTerm
 * @param {string?} questionType
 * @param {string?} area
 * @returns {Promise<any[]>}
 */
export async function searchMFAQ(
  searchTerm,
  questionType,
  area,
  store_id,
  from
) {
  const results = await callInternal(MFAQ_SEARCH_QUESTION, {
    searchTerm,
    questionType,
    area: area && areaIsWholeStore([area]) ? undefined : area,
    store_id,
    from,
  });
  return results.data;
}

export async function searchMFAQWithSearchTerm(
  searchTerm,
  questionType,
  area,
  store_id,
  from
) {
  const matchingQuestions = await searchMFAQ(
    searchTerm,
    questionType,
    area,
    store_id,
    from
  );
  return { matchingQuestions, searchTermSearched: searchTerm };
}

export async function createVote(question_id, value, vote_location, store_id) {
  const result = await callInternal(MFAQ_CREATE_VOTE, {
    vote: { question_id, value, vote_location, store_id, resolved: false },
  });
  tracker.MFAQ.trackVote(question_id, store_id, value, vote_location);
  return result.data;
}

export async function updateQuestionRequest(questionId, questionText, subject) {
  const payload = {
    question_id: questionId,
    question_text: questionText,
    subject: subject,
  };
  await callInternal(MFAQ_EDIT_QUESTION, payload).catch((error) =>
    console.error("MFAQ edit question", error)
  );
}

export async function mergeQuestionRequest(questionId, secondQuestionId) {
  const { data: newQuestionId } = await callInternal(MFAQ_MERGE_QUESTION, {
    first: questionId,
    second: secondQuestionId,
  });
  return newQuestionId;
}

export async function createQuestion(storeId, area, questionText) {
  await callInternal(MFAQ_CREATE_QUESTION, {
    question: {
      store_id: storeId,
      type: "",
      area: area,
      question_text: questionText,
      subject: questionText,
    },
  });
}

export function useGetAggregatedVotes(
  question_id,
  interval,
  startDate,
  endDate,
  area,
  isWholeStore
) {
  const { loading, data } = useCallInternal(MFAQ_AGGREGATED_VOTES, {
    question_id,
    interval,
    since: startDate,
    until: endDate,
    area: !isWholeStore ? area : undefined,
  });

  return { loading, data };
}

export function useGetTasksOnQuestion(
  question_id,
  interval,
  startDate,
  endDate,
  area,
  isWholeStore
) {
  const { loading, data: tasksOnQuestion } = useCallInternal(
    MFAQ_AGGREGATED_TASKS,
    {
      question_id,
      interval,
      since: startDate,
      until: endDate,
      area: !isWholeStore ? area : undefined,
    }
  );

  return { loading, data: tasksOnQuestion };
}
