import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivacyStatement from "./components/Privacy/PrivacyStatement";
import { SplashView } from "./features/login/SplashView";
import Main from "./components/Main";
import { useAuth } from "./core/auth/useAuth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PRIVACY_PATH } from "./paths";
// Keeping it outside of componnet, since App initializes multiple times which makes
// few queries being lost and not updating properly(mainly with task filters)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export function App() {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? (
    <QueryClientProvider client={queryClient}>
      <Main />
    </QueryClientProvider>
  ) : (
    <Routes>
      <Route path={PRIVACY_PATH} element={<PrivacyStatement />} />
      <Route path="*" element={<SplashView />} />
    </Routes>
  );
}
