import React from "react";
import { Trans } from "@coworker/locales";
import { Icon } from "@coworker/components";
import { useWorkspacesAction } from "./useWorkspaces";
import { useUserPreference } from "./useProfilePreferencesQuery";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import { useCurtainNotification } from "../components/CurtainNotification";

const useNotificationInstructions = () => {
  const { push } = useWorkspacesAction();
  const [
    instructionsAnnouncementRead,
    setInstructionsAnnouncementRead,
    isLoading,
  ] = useUserPreference(profilePreferences.INSTRUCTIONS_ANNOUNCEMENT, false);
  const { showCurtain, closeCurtain } = useCurtainNotification();
  const isShowing = React.useRef(false);

  React.useEffect(() => {
    if (isLoading || instructionsAnnouncementRead || isShowing.current) {
      return;
    }

    showCurtain(
      <Trans>readInstructionsString</Trans>,
      <Icon family="actions" name="close-small" size="20" />,
      () => {
        setInstructionsAnnouncementRead(true);
        closeCurtain();
      },
      () => {
        push("/tutorials");
        setInstructionsAnnouncementRead(true);
        closeCurtain();
      },
      { duration: false, tag: "tutorial" }
    );

    isShowing.current = true;
  }, [
    push,
    instructionsAnnouncementRead,
    isLoading,
    setInstructionsAnnouncementRead,
    showCurtain,
    closeCurtain,
  ]);
};

export default useNotificationInstructions;
