import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createShoppingTool } from "../ShoppingToolsAPI";

export const useCreateShoppingTool = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createShoppingTool,
    onSuccess: () => {
      queryClient.invalidateQueries(["shopping-tools-progress-by-round"]);
      queryClient.invalidateQueries(["shopping-tools-chart-data"]);
      queryClient.invalidateQueries(["shopping-tools-todays-progress"]);
    },
  });
};
