import React from "react";
import useFlag from "./useFlag";
import { getCachedInternalApi } from "../hooks/API/useCallInternal";
import { useStoreId } from "../core/auth/useLoggedInUser";

export function useAllImages(taskId: string) {
  const storeId = useStoreId();
  const [result, setResult] = React.useState([]);
  const mounted = React.useRef(true);
  React.useEffect(() => {
    if (taskId) {
      getCachedInternalApi(
        `task/all_images?id=${taskId}`,
        ({ data }: any) => mounted.current && setResult(data?.list || []),
        120_000,
        false,
        "",
        { store_id: storeId }
      );
    }
  }, [taskId, storeId]);
  React.useEffect(() => {
    // Cleanup to avoid "Can't perform a React state update on an unmounted component."
    return () => void (mounted.current = false);
  }, []);
  return result;
}

export function useTaskHasImages(taskId: string) {
  const enabled = useFlag("ENABLE_TASKCARD_ICONS");
  return !!useAllImages(enabled ? taskId : "").length;
}

export function useTaskImages(taskId: string) {
  return useAllImages(taskId).filter(
    (data: { noteId?: string }) => !data?.noteId
  );
}
