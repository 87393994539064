import React from "react";
import { ListViewItem } from "@ingka/list-view";
import deliveryTruck from "@ingka/ssr-icon/paths/delivery-truck";
import { Trans, useTranslation } from "@coworker/locales";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences.json";
import Instructions from "../../Instructions";
import { useInputPopup } from "../../InputPopup";
import { ReactComponent as ExpeditTag } from "../../../assets/svg/EXPEDIT-tag.svg";
import { ReactComponent as SupplierNumberMarker } from "../../../assets/svg/date-stamp-marker.svg";
import { updateArticle } from "../services/articles.service";
import { Article, ArticleSupplierNumberResponse } from "../types/article";
import { Text } from "../Atoms/Text";
import { Overlay } from "../styles/styles";

const { DISABLE_POPUPS_SUPPLIER_NUMBER } = profilePreferences;

interface SupplierNumberProps {
  article: Article;
  value: string;
  onUpdateArticle: () => void;
}

export const SupplierNumber = ({
  article,
  value,
  onUpdateArticle,
}: SupplierNumberProps) => {
  const { t } = useTranslation();
  const { getInput } = useInputPopup();

  const getSupplierNumber = async () => {
    const response: ArticleSupplierNumberResponse = await getInput(
      "supplierNumber",
      {
        value,
        bigTitle: <Trans>supplierNumberString</Trans>,
        instruction: (
          <Instructions
            title={<Trans>whereCanIfindSupplierString</Trans>}
            text={
              <Overlay minHeight="133px">
                <ExpeditTag />
                <SupplierNumberMarker />
              </Overlay>
            }
            type={DISABLE_POPUPS_SUPPLIER_NUMBER}
          />
        ),
      }
    );
    if (response && response.supplier_number_unknown === false) {
      try {
        await updateArticle(article.id, {
          supplierNumber: response.supplier_number,
        });
        onUpdateArticle();
      } catch (error) {
        alert(t("backendOperationFailedString"));
      }
    }
  };

  const addOnContent = value ? <Text text={value} grey /> : "";

  return (
    <ListViewItem
      title={t("supplierNumberString")}
      leadingIcon={deliveryTruck}
      addOn={addOnContent}
      control={"navigational"}
      controlIcon={"chevronRightSmall"}
      onClick={getSupplierNumber}
      emphasised
      inset
    />
  );
};
