import React from "react";
import { useToastNotification } from "@coworker/components";
import useUndoneNotification from "../../components/useUndoneNotification";
import useCloseTaskReasonPopupMenu from "../../components/InputPopup/shared/useCloseTaskReason";
import { updateTask } from "../../services/task.service";
import {
  callInternal,
  UPDATE_TASK,
  UPDATE_REPEATABLE_TASK,
} from "../../hooks/API/useCallInternal";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import activityTypes from "@coworker/functions/src/enums/activityTypes";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import trackerHelper from "../../helpers/tracker";
import { Trans } from "@coworker/locales";
import { useTranslation } from "@coworker/locales";
import useFlag, { FLAGS } from "../useFlag";
import { callInternalApi } from "../../hooks/API/useCallInternal";
import { useStoreId, useUserId } from "../../core/auth/useLoggedInUser";
import { useTaskRefresher } from "../useWatchedTasks";

export const useUndoCloseTask = ({ task, afterUndo }) => {
  const undoCurtain = useUndoneNotification();
  const uid = useUserId();
  const refreshTask = useTaskRefresher();

  const undoTask = React.useCallback(
    async (actionId) => {
      let data = {
        last_editor_id: uid,
        state: task.state,
        close_type: "",
        close_description: "",
        undo_action_id: actionId || "",
        completed_note: "",
        activity_type: activityTypes.UNDO_CLOSE,
      };

      if (task.task_type === taskTypeOptions.ADDON) {
        data = {
          ...data,
          task_type_detail: task.picked ? "REFILL" : "PICK_AND_REFILL",
        };
      }

      await undoCurtain(data, task.id);
      refreshTask(task.id, data, task);
      afterUndo();
    },
    [afterUndo, task, uid, undoCurtain, refreshTask]
  );

  return {
    call: undoTask,
  };
};

export const useCloseTask = (task, taskId, config = {}) => {
  const { undo, afterUndo = () => {} } = config;
  const uid = useUserId();
  const storeId = useStoreId();
  const { t } = useTranslation();
  const { showToast, showToastWithUndo } = useToastNotification();
  const { getCloseTaskPopup } = useCloseTaskReasonPopupMenu();
  const { call: undoTask } = useUndoCloseTask({
    task: { ...task, id: taskId },
    afterUndo,
  });
  const disableCloseFunction = !useFlag(FLAGS.ENABLE_CLOSE_FUNCTION);
  const refreshTask = useTaskRefresher();

  const closeTask = React.useCallback(async () => {
    const actionId = `close-${new Date().getTime()}`;
    const response = await getCloseTaskPopup();

    if (!response) return;

    let updateData = {
      last_editor_id: uid,
      state: taskStateOptions.CLOSED,
      close_type: response.issue,
      close_description: response.description,
      action_id: actionId,
      activity_type: activityTypes.CLOSE,
      // The onTaskUpdate trigger also deletes undo_action_id, but in the case that this backend change has not propagated to the frontend we must make sure to also signal this from the frontend!
      undo_action_id: "",
      ...(task.task_type === taskTypeOptions.ADDON
        ? { refilled_quantity: 0, refilled_type: task.refilled_type }
        : {}),
      // onUpdate trigger takes care of deleting undo_action_id
    };

    if (task.task_type_detail === "PICK_AND_REFILL") {
      updateData = {
        ...updateData,
        task_type_detail: "REFILL",
      };
    }

    if (disableCloseFunction) {
      await updateTask(updateData, taskId, task.task_type, storeId);
    } else {
      await callInternal(
        task.task_type === taskTypeOptions.PLANNED
          ? UPDATE_REPEATABLE_TASK
          : UPDATE_TASK,
        { taskId, data: updateData }
      )
        .catch(() => showToast(t("closeErrorMessage")))
        .then((response) => {
          if (!response?.data?.stored) {
            return updateTask(updateData, taskId, task.task_type);
          }
        });
    }
    refreshTask(taskId, updateData, task);

    trackerHelper.trackCloseTask(task, response.issue);

    if (!undo) return;

    showToastWithUndo(<Trans>youClosedString</Trans>, () => undoTask(actionId));

    return true;
  }, [
    refreshTask,
    taskId,
    undoTask,
    undo,
    uid,
    task,
    showToast,
    showToastWithUndo,
    t,
    disableCloseFunction,
    getCloseTaskPopup,
    storeId,
  ]);

  return {
    call: closeTask,
  };
};

export const useRejectPQReport = (task, taskId) => {
  const uid = useUserId();
  const storeId = useStoreId();
  const { showToast } = useToastNotification();
  const refreshTask = useTaskRefresher();
  const closeTask = React.useCallback(
    async (completed_note) => {
      const updateData = {
        last_editor_id: uid,
        state: taskStateOptions.CLOSED,
        action_id: `close-${new Date().getTime()}`,
        activity_type: activityTypes.CLOSE,
        // The onTaskUpdate trigger also deletes undo_action_id, but in the case that this backend
        // change has not propagated to the frontend we must make sure to also signal this from
        // the frontend!
        undo_action_id: "",
        ...(task.task_type === taskTypeOptions.ADDON
          ? { refilled_quantity: 0 }
          : {}),
        completed_note,
      };

      const result = await callInternalApi(`update/task`, {
        taskId,
        updateData,
        store_id: storeId,
      });
      refreshTask(taskId, updateData, task);
      if (!result.data.error) {
        showToast(<Trans>youRejectedTheTask</Trans>);
        return true;
      } else {
        showToast(<Trans>searchErrorString</Trans>); // "Oops! Something went wrong." -- most likely only showing up if device is offline but didn't yet switch to the offline view in Fixa.
        return false;
      }
    },
    [taskId, uid, task, showToast, refreshTask, storeId]
  );

  return closeTask;
};
