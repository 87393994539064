import React, { useContext } from "react";
import { ProfileHeader } from "./Header/ProfileHeader";
import { ProfileSections } from "./Sections/ProfileSections";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/modal/dist/style.css";
import "@ingka/focus/dist/style.css";
import Modal, { Sheets, ModalHeader } from "@ingka/modal";
import styles from "./profile.module.css";
import { useTranslation } from "@coworker/locales";
import { ModalContext } from "../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../layout/context/modalType";

export const Profile: React.FC = () => {
  const { t } = useTranslation();
  const { modalState, toggleModal } = useContext(ModalContext);
  return (
    <Modal
      escapable={true}
      visible={modalState.isProfileModalOpen}
      handleCloseBtn={() => {
        toggleModal({ type: ModalType.Profile });
      }}
    >
      <Sheets
        labelledById="Profile"
        size="small"
        className={styles["profile-sheets"] as string}
        header={
          <ModalHeader
            className={styles["profile-header-title"] as string}
            title={t("profileString")}
            backBtnClick={() => {
              toggleModal({ type: ModalType.Profile });
            }}
          ></ModalHeader>
        }
        footer={null}
      >
        <ProfileHeader></ProfileHeader>
        <ProfileSections></ProfileSections>
      </Sheets>
    </Modal>
  );
};
