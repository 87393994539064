import React from "react";
import styled from "styled-components";

const Skeleton = styled.div`
  height: 50vw;
  background-color: var(--grey200);
`;

// To avoid transpiling we instead lazy load chart js component
export const useLazyChartJs = () => {
  // Note that useState evaluates initial value if it's a function
  const [line, setLine] = React.useState<Function>(() => () => <Skeleton />);
  React.useEffect(() => {
    async function load() {
      const {
        Chart: ChartJS,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        TimeScale,
      } = await import("chart.js");
      const { Line } = await import("react-chartjs-2");
      ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        TimeScale,
        Title,
        Tooltip
      );
      setLine(Line);
    }
    load();
  }, []);

  return line;
};
