import React from "react";
import { TestBuyArticleCard } from "../CommonComponents/ArticleDetailsCard/TestBuyArticleCard";
import { TestBuyProductWithCriteria } from "@coworker/types";
export interface ArticleListProps {
  testbuyId: string;
  articles: TestBuyProductWithCriteria[];
  allArticleNumbers?: string[];
  showAsCompleted?: boolean;
  testId?: string;
  onChangeArticle?(): Promise<void>;
}

export function ArticleList(props: ArticleListProps) {
  return (
    <div className="article-list" data-testid={props.testId}>
      {props.articles.map((article) => (
        <TestBuyArticleCard
          key={article.id}
          testbuyId={props.testbuyId}
          article={article}
          allArticleNumbers={props.allArticleNumbers ?? []}
          showNavigationIcon={true}
          showAsCompleted={props.showAsCompleted || false}
          isInteractive={true}
          onChangeArticle={props.onChangeArticle}
        />
      ))}
    </div>
  );
}
