import React, { ReactElement } from "react";
import { Trans } from "@coworker/locales";
import shoppingBagIcon from "@ingka/ssr-icon/paths/shopping-bag";
import shoppingBasketIcon from "@ingka/ssr-icon/paths/shopping-basket";
import babyStrollerIcon from "@ingka/ssr-icon/paths/baby-stroller";
import shoppingBagTrolleyIcon from "@ingka/ssr-icon/paths/shopping-bag-trolley";
import shoppingTrolleyIcon from "@ingka/ssr-icon/paths/shopping-trolley";
import furnitureTrolleyIcon from "@ingka/ssr-icon/paths/furniture-trolley";
import documentFinancialIcon from "@ingka/ssr-icon/paths/document-financial";
import { SVGProps } from "react";
import { ListViewItemControl } from "@ingka/list-view/ListViewItem";

interface SVGElementProps extends SVGProps<SVGElement> {
  prefix?: string;
}

type SVGElementArray = (prefix?: string | undefined) => SVGElementProps[];

export interface ShoppingToolType {
  control: ListViewItemControl;
  label: string;
  name: string;
  icon?: SVGElementArray;
}

export interface FilterOpstions {
  id: string;
  name: string;
  label: ReactElement;
  checked: boolean;
  onChange: () => string;
}

export const TOTAL_MAX_COUNTED_VALUE = 200;
export const TOTAL_ROUND_VALUE = 600;
export const TOTAL_MAX_VALUE = 1800;

export function typesOfShoppingToolsList(
  control?: ListViewItemControl
): ShoppingToolType[] {
  return [
    {
      control: control ?? "default",
      label: "noToolString",
      name: "no_tool",
    },
    {
      control: control ?? "default",
      label: "yellowBagString",
      name: "yellow_bag",
      icon: shoppingBagIcon,
    },
    {
      control: control ?? "default",
      label: "blueBagToolString",
      name: "blue_bag",
      icon: shoppingBagIcon,
    },
    {
      control: control ?? "default",
      label: "yellowBasketString",
      name: "yellow_basket",
      icon: shoppingBasketIcon,
    },
    {
      control: control ?? "default",
      label: "strollerString",
      name: "stroller",
      icon: babyStrollerIcon,
    },
    {
      control: control ?? "default",
      label: "bagTrolleyString",
      name: "bag_trolley",
      icon: shoppingBagTrolleyIcon,
    },
    {
      control: control ?? "default",
      label: "trolleyString",
      name: "trolley",
      icon: shoppingTrolleyIcon,
    },
    {
      control: control ?? "default",
      label: "furnitureTrolleyString",
      name: "furniture_trolley",
      icon: furnitureTrolleyIcon,
    },
  ];
}

export function fullServeOrder(
  control: ListViewItemControl
): ShoppingToolType[] {
  return [
    {
      control: control ?? "default",
      label: "hasFullServerOrderString",
      name: "has_full_serve_order",
      icon: documentFinancialIcon,
    },
  ];
}

export function periodOptionsList(activePeriod: string): FilterOpstions[] {
  return [
    {
      id: "today",
      name: "today",
      label: <Trans>todayString</Trans>,
      checked: activePeriod === "today" ? true : false,
      onChange: () => "",
    },
    {
      id: "thisTertial",
      name: "thisTertial",
      label: <Trans>thisTertialString</Trans>,
      checked: activePeriod === "thisTertial" ? true : false,
      onChange: () => "",
    },
    {
      id: "lastTertial",
      name: "lastTertial",
      label: <Trans>lastTertialString</Trans>,
      checked: activePeriod === "lastTertial" ? true : false,
      onChange: () => "",
    },
    {
      id: "customTitle",
      name: "custom",
      label: <Trans>customTitleString</Trans>,
      checked: activePeriod === "customTitle" ? true : false,
      onChange: () => "",
    },
  ];
}

export function areaOptionsList(activeArea: string): FilterOpstions[] {
  return [
    {
      id: "wholeStore",
      name: "wholeStore",
      label: <Trans>wholeStoreString</Trans>,
      checked: activeArea === "wholeStore" ? true : false,
      onChange: () => "",
    },
    {
      id: "showroomOTW",
      name: "showroomOTW",
      label: <Trans>showroomOTWString</Trans>,
      checked: activeArea === "showroomOTW" ? true : false,
      onChange: () => "",
    },
    {
      id: "markethallOTW",
      name: "markethallOTW",
      label: <Trans>markethallOTWString</Trans>,
      checked: activeArea === "markethallOTW" ? true : false,
      onChange: () => "",
    },
    {
      id: "otherAreaCashline",
      name: "cashline",
      label: <Trans>otherAreaCashlineString</Trans>,
      checked: activeArea === "otherAreaCashline" ? true : false,
      onChange: () => "",
    },
  ];
}

export type Division = { division: Divisions; transKey: string };
type Divisions = "showroom" | "markethall" | "cashline";

export const DIVISIONS: Division[] = [
  { division: "showroom", transKey: "showroomOTWString" },
  { division: "markethall", transKey: "markethallOTWString" },
  { division: "cashline", transKey: "otherAreaCashlineString" },
];

export const DIVISION_HASH = {
  SHOWROOM: DIVISIONS[0],
  MARKETHALL: DIVISIONS[1],
  CASHLINE: DIVISIONS[2],
};

export type Round = { round: Rounds; transKey: string };
type Rounds = "morning" | "midday" | "afternoon";

export const ROUNDS: Round[] = [
  { round: "morning", transKey: "morningRoundString" },
  { round: "midday", transKey: "middayRoundString" },
  { round: "afternoon", transKey: "afternoonRoundString" },
];

export const ROUND_HASH = {
  MORNING: ROUNDS[0],
  MIDDAY: ROUNDS[1],
  AFTERNOON: ROUNDS[2],
};

export interface Tertial {
  tertialNumber: number | undefined;
  startDate: string;
  endDate: string;
}
export interface Timestamps {
  startDate: number | undefined;
  endDate: number | undefined;
}

export const TERTIALS: Tertial[] = [
  {
    tertialNumber: 1,
    startDate: "09-01",
    endDate: "12-31",
  },
  {
    tertialNumber: 2,
    startDate: "01-01",
    endDate: "04-30",
  },
  {
    tertialNumber: 3,
    startDate: "05-01",
    endDate: "08-31",
  },
];

const FILTER_PERIODS: string[] = ["today", "thisTertial", "lastTertial"];

export const FILTER_PERIOD_HASH = {
  TODAY: FILTER_PERIODS[0],
  THIS_TERTIAL: FILTER_PERIODS[1],
  LAST_TERTIAL: FILTER_PERIODS[2],
};

export interface ColorSchema {
  background: string;
  color: string;
}

export const HEADER_COLOR_SCHEMA = {
  BLUE: {
    background: "var(--blue-medium)",
    color: "var(--white)",
  },
  WHITE: {
    background: "var(--white)",
    color: "var(--black)",
  },
  GREY: {
    background: "var(--grey200)",
    color: "var(--black)",
  },
};
