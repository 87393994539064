import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { Button } from "@coworker/components";
import { useInputPopup } from "./InputPopup";
import { useStoreTeams } from "../core/hooks/useStoreTeams";
import { useTeamId } from "../core/auth/useLoggedInUser";

// The design for this specific screen seems to not match with previously designed screens
// To reuse the shared component Button from storybook seems to require this ugly hack
const Button5pxWider = styled(Button)`
  width: calc(100vw - 19px - 19px);
  margin-left: -10px;
`;

const PopoverBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--black);
  opacity: 0.7;
  z-index: var(--z-oneup);
`;

const Subtext = styled.div`
  margin-bottom: 30px;
  color: var(--grey718);
`;

const MustSelectTeam = styled.div`
  background-color: var(--white);
  font-size: 14px;
  position: absolute;
  bottom: 0px;
  padding: 30px 28px;
  width: calc(100% - 56px);
  z-index: var(--z-oneup);
`;

const TeamSetPopover = ({ getTeamChange, teams, onClose }) => (
  <MustSelectTeam>
    <b>
      <Trans>needToChooseTeamString</Trans>
    </b>
    <Subtext>
      <Trans>yourPrevTeamDeactivatedString</Trans>
    </Subtext>
    <Button5pxWider
      customMargin
      primary
      dark
      fullWidth
      noMargin
      text={<Trans>chooseTeamString</Trans>}
      onClick={() => {
        onClose();
        getTeamChange();
      }}
    />
  </MustSelectTeam>
);

export function TeamSetter({ shown, onClose }) {
  const teams = useStoreTeams();
  const teamId = useTeamId();
  const { getInput } = useInputPopup();

  return (
    <React.Fragment>
      {shown && <PopoverBackdrop key="backdrop" onClick={() => onClose()} />}
      {shown && (
        <TeamSetPopover
          getTeamChange={() =>
            getInput("teamChange", {
              title: <Trans>yourString</Trans>,
              teams,
              teamId,
            })
          }
          teams={teams}
          onClose={onClose}
          key="mustselectdialog"
        />
      )}
    </React.Fragment>
  );
}
