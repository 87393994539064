import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as TitleIcon } from "../assets/svg/custom-icon.svg";

const Container = styled.div`
  background: white;
  padding: 0 29px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey150);
  height: 100%;
  ${(props) =>
    css`
      color: var(--${props.deactivated ? "grey600" : "grey900"});
    `};
`;
const CompactContainer = styled.div`
  min-height: 90px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  width: 100%;
`;
const CompactTitle = styled.h2`
  font-size: 14px;
  font-weight: ${(props) => (props.deactivated ? "normal" : "bold")};
  padding-top: 35px;
  line-height: 1.5;
  margin-left: 21.5px;
  padding-bottom: 2px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 700;
`;
const CompactIconWrapper = styled.div`
  padding-top: 32px;
  padding-left: 2px;
  svg {
    opacity: ${(props) => (props.deactivated ? ".5" : "1")};
  }
`;
const EllipsisParagraph = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AddTitle = ({ deactivated, titleString, value }) => (
  <Container deactivated={deactivated}>
    <CompactContainer>
      <CompactIconWrapper deactivated={deactivated}>
        <TitleIcon />
      </CompactIconWrapper>
      <CompactTitle deactivated={deactivated}>
        <EllipsisParagraph>{value || titleString}</EllipsisParagraph>
      </CompactTitle>
    </CompactContainer>
  </Container>
);

export default AddTitle;
