import { matchPath, Route, Routes, useLocation } from "react-router-dom";
import React from "react";
import { useInputPopup } from "../InputPopup";
import { useOnline } from "../../core/hooks/useOnline";
import { Wrapper } from "./styles";
import OfflineBar from "./OfflineBar";
import BrowserSupportSuggestion from "./BrowserSupportSuggestion";
import trackerHelper from "../../helpers/tracker";
import { useWorkspacesState } from "../../hooks/useWorkspaces";
import usePushMessage from "../../hooks/usePushMessage";
import useNotificationDisabledMessage from "../../hooks/useNotificationDisabledMessage";
import { useAutoReloadOnNewDeploy } from "../../hooks/useAutoReloadOnNewDeploy";
import useNotificationInstructions from "../../hooks/useNotificationInstructions";
import useContentsquare from "../../hooks/useContentsquare";
import { BottomNavigation } from "@coworker/apprestructured/src/layout/components/BottomNavigation/BottomNavigation";
import MainContentScrollDirectionContextProvider from "@coworker/apprestructured/src/layout/context/MainContentScrollDirectionContextProvider";
import ModalContextProvider from "@coworker/apprestructured/src/layout/context/ModalContextProvider";
import MainNavigation from "@coworker/apprestructured/src/layout/components/MainNavigation/MainNavigation";
import MainHeader from "@coworker/apprestructured/src/layout/components/MainHeader/MainHeader";
import MainContent from "@coworker/apprestructured/src/layout/components/MainContent/MainContent";
import TaskDetailSheet from "@coworker/apprestructured/src/home/components/TasksPreview/TasksDetailSheet";

const Layout = ({ routes }) => {
  const location = useLocation();
  const { popupOpen } = useInputPopup();
  const { trackPageView: trackContentSquarePageView } = useContentsquare();

  useNotificationDisabledMessage();
  useAutoReloadOnNewDeploy();
  useNotificationInstructions();
  const { navigationState } = useWorkspacesState();
  usePushMessage();
  const { isOnline, isReconnecting } = useOnline();

  const activeRoute = React.useMemo(
    () => routes.find((item) => matchPath(item.path, location.pathname)) || {},
    [routes, location.pathname]
  );

  React.useEffect(() => {
    trackerHelper.trackPageView(location.pathname + location.search);
    trackContentSquarePageView();
  }, [location.pathname, location.search, trackContentSquarePageView]);

  if (!activeRoute) {
    throw Error("404 - Route not found");
  }

  return (
    <Wrapper disabled={popupOpen}>
      <ModalContextProvider>
        <MainContentScrollDirectionContextProvider>
          <MainHeader />
          <Routes location={location}>
            {routes.map((route) => {
              const { content: Content } = route;

              return (
                <Route
                  exact
                  key={route.path}
                  path={route.path}
                  element={
                    <MainContent>
                      <Content key={navigationState._key} isOnline={isOnline} />
                    </MainContent>
                  }
                ></Route>
              );
            })}
          </Routes>

          <BottomNavigation />
          {!isOnline && <OfflineBar isReconnecting={isReconnecting} />}
          <BrowserSupportSuggestion />
          <MainNavigation />
        </MainContentScrollDirectionContextProvider>
        {!isOnline && <OfflineBar isReconnecting={isReconnecting} />}
        <BrowserSupportSuggestion />
        <MainNavigation />
        <TaskDetailSheet />
      </ModalContextProvider>
    </Wrapper>
  );
};

export default Layout;

// Recreate this:
// When user goes into detail view from list of tasks, we want to keep the scroll position for when
// they come back to large view. In order to do that we save the position on naviation event and scroll
// them when they click back (either back button in browser or back arrow in app).
