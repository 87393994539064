import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { ReactComponent as OpenIcon } from "../../assets/svg/arrow-small-forward.svg";
import { Link } from "../../components/Link";
import { AddonTask } from "@coworker/types/lib/tasks/addon";
import { TestBuyFollowUp } from "@coworker/types/lib/tasks/testbuy_followup";
import { TaskCard } from "@coworker/apprestructured/src/tasks/components/TaskCard/TaskCard";
import { Task } from "@coworker/types/lib/tasks/base";

const Image = styled.img`
  width: 73px;
  height: 73px;
  min-width: 73px;
  min-height: 73px;
  margin-right: 20px;
`;

interface ContentProps {
  to: string;
}

const Content: React.FC<ContentProps> = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--black);
  background: var(--white);
  height: 110px;
  padding: 0px 36px 0px 28px;
  border-top: 1px solid var(--grey150);
`;

const TextRows = styled.div`
  min-height: 43px;
`;

const MainContent = styled.div`
  display: flex;
  align-items: center;
`;

const WrappedOpenIcon = styled(OpenIcon)`
  margin-right: -8px;
`;

interface PreviewLinkProps {
  image?: string;
  children: React.ReactNode;
  to: string;
}

export function PreviewLink({ image, children, to }: PreviewLinkProps) {
  return (
    <Content to={to}>
      <MainContent data-testid="productInformation">
        {image && <Image src={image} />}
        <TextRows>{children}</TextRows>
      </MainContent>
      <WrappedOpenIcon />
    </Content>
  );
}

const ContentHeader: React.FC = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--black);
  background: var(--white);
  height: 110px;
  padding: 0px 36px 0px 28px;
  border-top: 1px solid var(--grey150);
`;

interface PreviewHeaderProps {
  image?: string;
  children: React.ReactNode;
}

export function PreviewHeader({ image, children }: PreviewHeaderProps) {
  return (
    <ContentHeader>
      <MainContent data-testid="productInformation">
        {image && <Image src={image} />}
        <TextRows>{children}</TextRows>
      </MainContent>
    </ContentHeader>
  );
}

const PreviewContainer = styled.div`
  overflow: visible;
`;

const SectionBar = styled.div`
  min-height: 60px;
  background: var(--grey100);
  font-size: 18px;
  color: var(--grey900);
  line-height: 60px;
  padding: 16px 24px 0 24px;
  font-weight: 700;
`;

const SectionBottom = styled.div`
  min-height: 24px;
  background: var(--grey100);
`;

interface PreviewSectionProps {
  sectionTitle: React.ReactNode;
  children: React.ReactNode;
}

export function PreviewSection({
  sectionTitle,
  children,
}: PreviewSectionProps) {
  return (
    <>
      <SectionBar>{sectionTitle}</SectionBar>
      <PreviewContainer>{children}</PreviewContainer>
      <SectionBottom />
    </>
  );
}

type PreviewableTask = AddonTask | TestBuyFollowUp;

// This wrapper will be removed once coworker-app/src/components/TaskForm/widgets/index.js is refactored.
export function TaskPreviewFormWidget({ value }: any) {
  const task = value as PreviewableTask;
  return <TaskPreview task={task} />;
}

export function TaskPreview({ task }: { task: PreviewableTask | Task }) {
  return (
    <PreviewSection sectionTitle={<Trans>previewThisTask</Trans>}>
      <TaskCard task={task as Task} />
    </PreviewSection>
  );
}
