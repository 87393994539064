import React from "react";
import defaultPhotoUrl from "../../assets/svg/generic-profile.svg";
import { useQuery } from "@tanstack/react-query";
import { INTERVAL } from "../../constants/clientTime";
import { fetchUserAvatar } from "./core.service";

const verifiedUrls = new Set();

function tryLoadingImage(url: string, callback: (url: string) => any) {
  if (!url) return;
  if (verifiedUrls.has(url)) return callback(url);
  const img = new Image();

  function handler(event: any) {
    // Cleanup when first triggered.
    // This is especially needed since some browsers can trigger multiple error events.
    img.removeEventListener("load", handler);
    img.removeEventListener("error", handler);
    if (event.type === "load" && img.width) {
      verifiedUrls.add(url);
      callback(url);
    } else {
      callback("");
    }
  }

  img.addEventListener("load", handler);
  img.addEventListener("error", handler);
  img.src = url;
}

// Initially tries to show existing image, and checks if image can be loaded.
export function useAvatarImage(fixaUid: string) {
  const [url, setUrl] = React.useState("");
  const mounted = React.useRef(true);
  const avatarImage = useUserAvatarQuery(fixaUid);

  React.useEffect(() => {
    // We always check if imageUrl is working.
    if (avatarImage) {
      tryLoadingImage(URL.createObjectURL(avatarImage), (url: string) => {
        if (mounted.current) setUrl(url);
      });
    }
  }, [fixaUid, avatarImage]);

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  return url || defaultPhotoUrl;
}

export function useUserAvatarQuery(fixaUid: string) {
  const result = useQuery({
    queryKey: ["userAvatar", fixaUid],
    queryFn: async () => {
      return await fetchUserAvatar(fixaUid);
    },
    staleTime: 1 * INTERVAL.HOUR, // Since this data does not change frequently, it can stay fresh for a long time
  });
  return result?.data;
}
