import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { Options, Option } from "@coworker/components";
import { ReactComponent as Illustration } from "../../../assets/svg/tutorials-cover-illustration.svg";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import { widgets } from "./instructions_data";
import { ReactComponent as Clock } from "../../../assets/svg/time.svg";
import { ReactComponent as Contact } from "../../../assets/svg/reason1-contact-us.svg";
import { ReactComponent as Trolley } from "../../../assets/svg/reason2-furniture-trolley-loaded.svg";
import { ReactComponent as Medal } from "../../../assets/svg/reason3-reward.svg";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";

const StyledIllustration = styled(Illustration)`
  margin-left: 95px;
  margin-bottom: 35px;
`;
const Title = styled.div`
  margin: 0 28px;
  color: var(--black);
  font-size: 25px;
  text-align: left;
  line-height: 34px;
  letter-spacing: -0.0042em;
`;
const Subtitle = styled.div`
  color: var(--black);
  margin: 19px 28px;
  font-size: 14px;
  font-weight: normal;
`;
const SeparatorTitle = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 40px;
  padding: 24px 15px;
  background: var(--grey200);
  color: var(--grey900);
  font-size: 14px;
  line-height: 20px;
`;
const Reason = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 35px;
  font-weight: normal;
  color: var(--blue-medium);
`;
const ReasonIcon = styled.div`
  width: 30px;
  margin-top: 5px;
`;
const ReasonText = styled.div`
  margin-left: 12px;
  font-size: 14px;
  color: var(--grey900);
  line-height: 21px;
  letter-spacing: 0px;
`;
const Completed = styled.div`
  margin-top: 5px;
  color: var(--success);
  font-size: 12px;
  line-height: 16px;
`;

const InstructionsRoot = () => {
  const { push } = useWorkspacesAction();
  const [instructionsCompleted] = useUserPreference(
    profilePreferences.INSTRUCTIONS_COMPLETED,
    []
  );
  const { t } = useTranslation();

  return (
    <FullScreenPopup
      noPadding
      appBarConfig={{
        title: t("userGuideString"),
        actions: [],
      }}
    >
      <Options>
        <StyledIllustration />
        <Title>
          <Trans>userBenefitsTitleString</Trans>
        </Title>
        <Subtitle>
          <Trans>userBenefitsSubtitleString</Trans>
        </Subtitle>
        <Reason>
          <ReasonIcon>
            <Clock />
          </ReasonIcon>
          <ReasonText>
            <Trans>reasonEnsureString</Trans>
          </ReasonText>
        </Reason>
        <Reason>
          <ReasonIcon>
            <Contact />
          </ReasonIcon>
          <ReasonText>
            <Trans>reasonSaveTimeString</Trans>
          </ReasonText>
        </Reason>
        <Reason>
          <ReasonIcon>
            <Trolley />
          </ReasonIcon>
          <ReasonText>
            <Trans>reasonLessWorkString</Trans>
          </ReasonText>
        </Reason>
        <Reason>
          <ReasonIcon>
            <Medal />
          </ReasonIcon>
          <ReasonText>
            <Trans>reasonImproveAddonSalesString</Trans>
          </ReasonText>
        </Reason>
        <SeparatorTitle>
          <Trans>lookAtTutorialsString</Trans>
        </SeparatorTitle>
        {widgets.map((option, idx) => (
          <Option
            data={option}
            key={idx}
            hasArrow
            labels={
              instructionsCompleted.includes(option.type) && (
                <Completed>
                  <Trans>completedInstructionString</Trans>
                </Completed>
              )
            }
            onClick={() => push(`tutorials/${option.type}`)}
            color="var(--grey900)"
          />
        ))}
      </Options>
    </FullScreenPopup>
  );
};

export default InstructionsRoot;
