import React from "react";
import styled from "styled-components";
import { Input } from "@coworker/components";
import { Trans } from "@coworker/locales";
import Question from "./Question";
import { useSearchQuestions } from "./useSearchQuestions";
import {
  useWorkspacesAction,
  useWorkspacesState,
} from "../../hooks/useWorkspaces";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import Questions from "./Questions";
import { capitalizeFirst } from "../../hooks/useFormatter";
import { SmallX } from "../../features/searching/HeaderSearchInput";

const InputWithSpacing = styled(Input)`
  margin-bottom: 22px;
`;

const QuestionPreviewTitle = styled.div`
  padding-top: 28px;
  font-size: 18px;
  font-weight: bold;
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
`;

function AddQuestionInput({
  questionDataRef,
  onInputTextChange,
  areaIsWholeStore,
  selectedArea,
}) {
  const { setNavigationState } = useWorkspacesAction();
  const { navigationState } = useWorkspacesState();

  /**
   * @type {React.MutableRefObject<HTMLInputElement>}
   */
  const inputElementRef = React.useRef();
  const [inputText, setInputText] = React.useState(
    navigationState?.newMFAQInputText ?? ""
  );

  const [whereFrom, setWhereFrom] = React.useState(0);

  const updateInputText = (newText) => {
    newText = removeQuestionMarks(newText);
    setNavigationState({ newMFAQInputText: newText });
    setInputText(newText);
    onInputTextChange && onInputTextChange(newText);
  };

  questionDataRef.current = {
    ...questionDataRef.current,
    question_text: getTranslatedQuestionText(inputText),
    subject: inputText,
  };
  const store_id = useStoreId();

  const { similarQuestions, shouldShowLoadMore } = useSearchQuestions(
    inputText,
    setWhereFrom,
    whereFrom,
    questionDataRef,
    store_id
  );
  async function updateFrom() {
    setWhereFrom(whereFrom + similarQuestions.length);
  }

  React.useEffect(() => {
    if (
      similarQuestions.length > 0 &&
      inputElementRef.current &&
      whereFrom === 0
    ) {
      inputElementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [similarQuestions.length, inputElementRef, whereFrom]);

  return (
    <>
      <InputWrapper>
        <InputWithSpacing
          testId="questionTextInput"
          ref={inputElementRef}
          autoFocus={!areaIsWholeStore}
          autoComplete={"off"}
          onChange={updateInputText}
          value={inputText}
        />
        {inputText.length > 2 && (
          <SmallX
            data-testid="searchClear"
            onClick={() => updateInputText("")}
          />
        )}
      </InputWrapper>
      {inputText.length > 2 && (
        <Questions
          questions={similarQuestions}
          canUpvote={!areaIsWholeStore}
          showUpvote={!areaIsWholeStore}
          updateFrom={updateFrom}
          shouldShowLoadMore={shouldShowLoadMore}
          selectedArea={selectedArea}
          dataTestId="similarQuestionsContainer"
        />
      )}
      <QuestionPreviewTitle>
        <Trans>previewString</Trans>
      </QuestionPreviewTitle>
      <Question
        testId="questionPreview"
        noLink
        useCustomTextStyle
        questionText={<strong>{getFormattedQuestionText(inputText)}</strong>}
        upvotesCount={1} // Newly created questions always get one upvote immediately
        canUpvote={false}
        lastAskedAt={Date.now()}
      />
    </>
  );
}

/**
 * Translates the question text template for sinking to the database.
 *
 * Uses a different translation key than the UI translation, so we dont have style info in the DB.
 * @param {string} text
 * @returns {string}
 */
export function getTranslatedQuestionText(text = "") {
  return `${capitalizeFirst(removeQuestionMarks(text).trim())}?`;
}

/**
 * Formats question text for the UI, with styles if needed.
 * @param {string} text
 */
export function getFormattedQuestionText(text = "...") {
  return `${capitalizeFirst(removeQuestionMarks(text).trim())}?`;
}

export function removeQuestionMarks(string) {
  return string.replace(/\?/g, "");
}

export default AddQuestionInput;
