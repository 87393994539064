import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { ReactComponent as Arrow } from "../../assets/svg/arrow-right.svg";
import { LoaderIcon } from "@coworker/reusable/Loader";
import Card from "@coworker/components/src/components/Card";
import useInsightsAddonLists from "../../hooks/useInsightsAddonLists";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import useFormatter from "../../hooks/useFormatter";
import tracker from "../../helpers/tracker";
import { InsightsProduct } from "./InsightsProduct";
import { useTranslation } from "@coworker/locales";
const _ = require("lodash");

// type
const TURNOVER = "turnover";
const REFILL = "refill";

// order
const MOST = "most";
const LEAST = "least";

const CardSubtitle = styled.div`
  color: var(--grey900);
  font-size: 11px;
  font-weight: normal;
`;

const Nothing = styled.div`
  max-height: 84px;
  display: flex;
  align-items: center;
  color: var(--grey718);
  font-size: 11px;
  width: 100%;
  margin-left: 29px;
  height: 65px;
`;

const Products = styled.div`
  display: flex;
  min-height: 176px;
  overflow-x: auto;
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Preview = styled.div`
  font-size: 12px;
  font-weight: bold;
  min-width: 76px;
  max-width: 76px;
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 13px;
  margin: 24px 0;
`;

const Text = styled.div`
  margin-top: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledArrow = styled(Arrow)`
  margin-top: 10px;
`;

const StyledLoader = styled(LoaderIcon)`
  height: 40px;
  width: 100%;
  margin-top: 65px;
`;

const titles = {
  [TURNOVER]: {
    [MOST]: <Trans>highestEstTurnover</Trans>,
    [LEAST]: <Trans>lowestEstTurnover</Trans>,
  },
  [REFILL]: {
    [MOST]: <Trans>mostPieces</Trans>,
    [LEAST]: <Trans>leastPieces</Trans>,
  },
};

const Title = ({ text }) => (
  <>
    <p>{text}</p>
    <CardSubtitle>
      <Trans>calculatedString</Trans>
    </CardSubtitle>
  </>
);

const SeeAll = ({ onChangeInsightsParams, type, order, testId }) => {
  const { push } = useWorkspacesAction();
  return (
    <Preview
      data-testid={testId}
      onClick={() => {
        tracker.insights.store.trackSeeAll(order, type);
        const updatedInsightsFilter = {};
        _.set(updatedInsightsFilter, ["deptFilters", "hfb"], "ALL");
        _.set(updatedInsightsFilter, ["deptFilters", "orderBy"], type);
        _.set(
          updatedInsightsFilter,
          ["deptFilters", "orderByAsc"],
          order === LEAST
        );
        onChangeInsightsParams(updatedInsightsFilter);
        push(`/insights/department/ALL`);
      }}
    >
      <Text>
        <Trans>seeAllString</Trans>
      </Text>
      <StyledArrow />
    </Preview>
  );
};

const ProductScroller = ({
  order,
  limit,
  testId,
  dataFetchParams,
  onChangeInsightsParams,
}) => {
  const store_id = useStoreId();
  const { formatCurrency } = useFormatter();
  const { t } = useTranslation();

  const isMost = order === MOST;

  const type = isMost
    ? dataFetchParams?.productPreview?.most || TURNOVER
    : dataFetchParams?.productPreview?.least || TURNOVER;
  const isTurnover = type === TURNOVER;

  const toggleType = () => {
    const newType = isTurnover ? REFILL : TURNOVER;
    tracker.insights.store.trackToggle(`preview-${order}`, newType);
    const updatedInsightsFilter = {};
    if (isMost)
      _.set(updatedInsightsFilter, ["productPreview", "most"], newType);
    else _.set(updatedInsightsFilter, ["productPreview", "least"], newType);
    onChangeInsightsParams(updatedInsightsFilter);
  };

  const { data, loading } = useInsightsAddonLists(false, {
    ...dataFetchParams,
    store_id,
    deptFilters: {
      orderBy: type,
      orderByAsc: order === LEAST,
    },
    limit,
  });

  const nothingToShow = !data.length && !loading;

  return (
    <div key={order} data-testid={testId}>
      <Card
        noContentPadding
        title={<Title text={<Trans>{titles[type][order]}</Trans>} />}
        subtitle={<Trans>insightsTurnoverString</Trans>}
        disabled={loading || !data.length}
        action={{
          label:
            type === REFILL ? (
              <Trans>viewEstTurnoverString</Trans>
            ) : (
              <Trans>viewPiecesString</Trans>
            ),
          onClick: toggleType,
        }}
      >
        {!nothingToShow && (
          <Products data-testid={`product_scroller_products_${order}`}>
            {loading ? (
              <StyledLoader />
            ) : (
              <>
                {data.map((product) => {
                  const value = isTurnover
                    ? parseFloat(product.turnover) === 0
                      ? t("priceNotSet")
                      : formatCurrency(product.turnover, product.currency)
                    : `${product.refilled} ${t("pcsString")}`;
                  return (
                    <InsightsProduct
                      key={product.id}
                      fullId={product.id}
                      title={product.title}
                      image={product.smallImage || product.image}
                      value={value}
                      onChangeInsightsParams={onChangeInsightsParams}
                      type={type}
                      order={order}
                    />
                  );
                })}
                {!loading && !!data.length && (
                  <SeeAll
                    testId={`product_scroller_products_${order}_see_all`}
                    onChangeInsightsParams={onChangeInsightsParams}
                    type={type}
                    order={order}
                  />
                )}
              </>
            )}
          </Products>
        )}
        {nothingToShow && (
          <Nothing>
            <Trans>nothingToShowString</Trans>
          </Nothing>
        )}
      </Card>
    </div>
  );
};

export default ProductScroller;
