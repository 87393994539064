import React from "react";
import { useTranslation } from "@coworker/locales";
import { useInputPopup } from "../../InputPopup";
import { TaskCard } from "./TaskCard";
import { Text } from "../Atoms/Text";
import { RightArrow } from "../Atoms/Icons/RightArrow";
import { SpeechBubble } from "../Atoms/Icons/SpeechBubble";
import styled from "styled-components";

interface DescriptionProps {
  value: string | undefined;
  editDescription: (desc: string) => void;
}

const Container = styled.div`
  border-bottom: 1px solid var(--grey150);
`;

const AddDescription = ({ value, editDescription }: DescriptionProps) => {
  const { t } = useTranslation();
  const { getInput } = useInputPopup();

  const getDescription = async () => {
    const desc = await getInput("description", {
      initialValue: value,
      title: "",
      descriptionTitle: t("description4String"),
    });
    if (desc) {
      editDescription(desc);
    }
  };

  return (
    <Container>
      <TaskCard
        headLine={<Text text={t("description4String")} bold />}
        leftIcon={<SpeechBubble />}
        subText={<Text text={value ?? ""} grey />}
        rightIcon={<RightArrow />}
        onClick={getDescription}
      />
    </Container>
  );
};

export default AddDescription;
