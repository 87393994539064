import React from "react";
import {
  TestbuyArticleCardProps,
  TestBuyArticleCard,
} from "../CommonComponents/ArticleDetailsCard/TestBuyArticleCard";
import { Button } from "@coworker/components";
import { useTranslation } from "@coworker/locales";
import { CriteriaList } from "./CriterionComponent/CriteriaList";
import { updateCriterionReducer } from "./CriterionComponent/updateCriterionReducer";
import {
  allCriteriaAnswered,
  allCriteriaOk,
} from "../CommonComponents/article.helpers";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import {
  useTestbuyTasks as useTestbuyTasksFS,
  useTestbuy as useTestbuyFS,
} from "../CommonComponents/Repositories/testbuy.repository";
import {
  useTestbuyTasks as useTestbuyTasksPG,
  useTestbuy as useTestbuyPG,
} from "../CommonComponents/Repositories/useTestbuyService";
import { formatProductIdWithDots } from "@coworker/reusable";
import { isDateWithinLastWeek } from "../CommonComponents/helpers";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";

export function DisplayArticleDetails(props: TestbuyArticleCardProps) {
  const { article, testbuyId } = props;
  const { t } = useTranslation();
  const { pop, push } = useWorkspacesAction();
  // @ts-ignore
  const avoidFirebase = window.callTestbuyService;
  const [criteriaState, dispatch] = React.useReducer(
    updateCriterionReducer,
    article.criteria
  );

  const useTestbuy = avoidFirebase ? useTestbuyPG : useTestbuyFS;
  const useTestbuyTasks = avoidFirebase ? useTestbuyTasksPG : useTestbuyTasksFS;

  const [loadButton, setLoadButton] = React.useState<boolean>(false);
  props.article.criteria = criteriaState;

  const { testbuy, loading } = useTestbuy(testbuyId);
  const headerTitleText = `${article.name} ${formatProductIdWithDots(
    article.shortId
  )}`;
  const isTaskCreated = !!article.followUpTaskId;
  const task = useTestbuyTasks(isTaskCreated ? testbuyId : "")?.find?.(
    (taskData) => taskData.articleId === article.shortId
  );

  const isTaskResolved =
    task?.state === "COMPLETED" || task?.state === "CLOSED";
  const isTaskFinishedWithinLastWeek =
    isTaskResolved && isDateWithinLastWeek(task?.finishedAt);
  const isTaskViewable =
    !!task &&
    isTaskCreated &&
    (!isTaskResolved || (isTaskResolved && isTaskFinishedWithinLastWeek));

  return (
    <FullScreenPopup
      appBarConfig={{
        title: headerTitleText,
        actions: [],
      }}
      noPadding
      actionBarContent={
        <Button
          fullWidth
          primary
          dark
          text={t(isTaskCreated ? "showTaskString" : "doneString")}
          data-testid="article-done"
          onClick={async () => {
            setLoadButton(true);
            if (allCriteriaOk(article.criteria)) {
              pop();
            } else if (isTaskViewable) {
              push(`/task/${article.followUpTaskId}`);
            } else {
              push(`/testbuy/${testbuyId}/article/${article.id}/createTask`);
            }
          }}
          showLoader={loadButton}
          disabled={
            !allCriteriaAnswered(article.criteria) ||
            (isTaskCreated && !isTaskViewable)
          }
        />
      }
    >
      <TestBuyArticleCard {...props} />
      <CriteriaList
        testbuyId={testbuyId}
        articleId={article.id || ""}
        criteria={criteriaState}
        dispatch={dispatch}
        isLocked={loading || testbuy?.status !== "IN_PROGRESS" || isTaskCreated}
        location={article.location}
        price={article.price}
      />
    </FullScreenPopup>
  );
}
