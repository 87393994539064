import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background: var(--white);
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: var(--z-oneup);
  height: 70px;
  box-sizing: border-box;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  height: 70px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Button = styled.div`
  display: inline;
  background: ${(props) =>
    props.disabled ? "var(--grey200)" : "var(--black)"};
  color: ${(props) => (props.disabled ? "var(--black)" : "var(--white)")};
  pointer-events: ${(props) => props.disabled && "none"};
  padding: 13px;
  margin: 15px 5px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  max-height: 40px;
  white-space: nowrap;
`;
const Name = styled.p`
  font-size: 12px;
  color: #484848;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
`;

const Filter = ({
  onClick,
  productName,
  time,
  quantity,
  hfb,
  level,
  value,
  share,
  notAll,
  home_sort,
  home_sort_value,
  show_home_sort_value,
}) => {
  const click = (option) => {
    if (option === "time") {
      return onClick;
    }
    if (option === "value") {
      return onClick;
    }
    if (option === "home_sort") {
      return onClick;
    }
    if (option === "home_sort_value") {
      return onClick;
    }
    if (option === "hfb" && level === 2) {
      return onClick;
    }
    if (option === "share" && level === 2) {
      return onClick;
    }
    if (option !== "time" && level > 3) {
      return null;
    }
    if (option === "quantity") {
      return onClick;
    }
  };

  const notStore = !window.location.href.split("/").includes("store");

  return (
    <Container>
      {productName && <Name>{productName.toUpperCase()}</Name>}
      <Content>
        <Button onClick={click("time")} data-testid="insights_filter_time">
          {time}
        </Button>
        {level === 1 && notStore && (
          <>
            <Button
              onClick={click("value")}
              data-testid="insights_filter_value"
            >
              {value}
            </Button>
            <Button
              onClick={click("home_sort")}
              data-testid="insights_filter_sort"
            >
              {home_sort}
            </Button>
            {show_home_sort_value && (
              <Button
                onClick={click("home_sort_value")}
                data-testid="insights_filter_home_sort_value"
              >
                {home_sort_value}
              </Button>
            )}
          </>
        )}
        {level > 1 && (
          <Button
            onClick={click("quantity")}
            disabled={level > 2}
            data-testid="insights_filter_quantity"
          >
            {quantity}
          </Button>
        )}
        {level > 1 && notAll && (
          <Button
            onClick={click("share")}
            disabled={level > 2}
            data-testid="insights_filter_share"
          >
            {share}
          </Button>
        )}
        {level > 1 && (
          <Button
            onClick={click("hfb")}
            disabled={level > 2}
            data-testid="insights_filter_hfb"
          >
            {hfb}
          </Button>
        )}
      </Content>
    </Container>
  );
};

export default Filter;
