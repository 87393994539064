import React from "react";
import { Trans } from "@coworker/locales";
import { useCurtainNotification } from "../components/CurtainNotification";
import { eventTypes } from "@coworker/functions/src/enums/eventTypes";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import { useUserPreference } from "./useProfilePreferencesQuery";
import { useUserId } from "../core/auth/useLoggedInUser";
import { isRDTDevice } from "@coworker/reusable";

const useNotificationDisabledMessage = () => {
  const isRunningAsTest = window.Cypress;
  const isRDT = isRDTDevice();
  const { showCurtain, closeCurtain } = useCurtainNotification();
  const uid = useUserId();
  const [userFilters] = useUserPreference(
    profilePreferences.NOTIFICATION_CHOICES,
    []
  );
  const filtersLength = userFilters?.length || 0;

  const key = `notification.disabled.message.${uid}`;
  const inCurrentSession = sessionStorage?.getItem(key); // sessionStorage is null sometimes (iOS)

  React.useEffect(() => {
    if (
      (!("Notification" in window) || Notification.permission === "denied") &&
      filtersLength < Object.keys(eventTypes).length &&
      !inCurrentSession &&
      !isRunningAsTest &&
      !isRDT
    ) {
      showCurtain(
        <Trans>notificationPermissionDeniedString</Trans>,
        <Trans>dismissString</Trans>,
        () => {
          closeCurtain();
          sessionStorage.setItem(key, "1");
        },
        null,
        { tag: "notification.disabled.message" }
      );
    }
  }, [
    closeCurtain,
    showCurtain,
    userFilters,
    inCurrentSession,
    key,
    filtersLength,
    isRunningAsTest,
    isRDT,
  ]);
};

export default useNotificationDisabledMessage;
