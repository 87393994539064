import React from "react";
import { TaskCard } from "../../../tasks/components/TaskCard/TaskCard";
import styles from "./activity-column.module.css";
import { Task } from "@coworker/types/lib/tasks/base";
import { ActivityFilterType } from "../../types/filterOptionsTypes";
import { DEFAULT_FILTER_VALUES } from "../../constants/defaultFilterValues";
import Button from "@ingka/button";
import { Trans } from "@coworker/locales";
import Skeleton from "@ingka/skeleton";

interface ActivityColumnProps {
  title: string;
  tasks: Task[];
  filteredCount: number;
  totalCount: number;
  loadNextPage: () => void;
  tab: string;
  activeTab: string;
  appliedFiltersCount: number;
  setFilters: React.Dispatch<React.SetStateAction<ActivityFilterType>>;
  isLoading: boolean;
}

export const ActivityColumn = ({
  title,
  tasks,
  filteredCount,
  totalCount,
  loadNextPage,
  tab,
  activeTab,
  appliedFiltersCount,
  setFilters,
  isLoading,
}: ActivityColumnProps) => {
  const loadMoreTasks = () => {
    loadNextPage();
  };

  return (
    <div
      className={`${styles["column"]} ${activeTab === tab && styles["active"]}`}
    >
      <div className={styles["title"]}>
        <Trans>{title}</Trans>{" "}
        {!isLoading && filteredCount < totalCount && (
          <span className={styles["count"]}>
            ({filteredCount}/{totalCount}
            {totalCount >= 1000 && "+"})
          </span>
        )}
        {!isLoading && filteredCount === totalCount && (
          <span className={styles["count"]}>
            {totalCount}
            {totalCount >= 1000 && "+"}
          </span>
        )}
      </div>
      <div className={styles["wrapper"]}>
        {tasks.map((task) => (
          <TaskCard task={task} key={task.id} taskModal />
        ))}
        {!isLoading && !tasks.length && (
          <div className={styles["no-results"]}>
            <div className={styles["no-results-title"]}>
              <Trans>noTaskResultsString</Trans>
            </div>
            {!tasks.length && appliedFiltersCount > 0 ? (
              <>
                <div className={styles["no-results-subtitle"]}>
                  <Trans>noTasksWithAppliedFiltersString</Trans>
                </div>
                <Button
                  onClick={() => setFilters(DEFAULT_FILTER_VALUES)}
                  text={<Trans>clearFiltersString</Trans>}
                  small
                  type="secondary"
                />
              </>
            ) : (
              <div className={styles["no-results-subtitle"]}>
                <Trans>noTasksFoundString</Trans>
              </div>
            )}
          </div>
        )}
        {isLoading && (
          <div className={styles["loading"]}>
            <Skeleton style={{ flex: 1, height: "100%" }} />
          </div>
        )}
        {tasks.length < filteredCount && !isLoading && (
          <div className={styles["load-more-button"]}>
            <Button
              onClick={loadMoreTasks}
              text={<Trans>loadMore</Trans>}
              type="primary"
              small
            />
          </div>
        )}
      </div>
    </div>
  );
};
