import React from "react";

import styles from "./pagination-controls.module.css";

interface PaginationControlsProps {
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  totalItems: number;
  pageSize: number;
}

export const PaginationControls = ({
  currentPage,
  setCurrentPage,
  totalItems,
  pageSize,
}: PaginationControlsProps) => {
  return (
    <div className={styles["pagination-controls"]}>
      <button
        className={styles["button"]}
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {"<"}
      </button>
      {/* show button to the 1st page */}
      {currentPage > 2 ? (
        <>
          <button
            className={styles["button"]}
            onClick={() => setCurrentPage(1)}
          >
            1
          </button>
          <span className={styles["three-dots"]}>...</span>
        </>
      ) : (
        <>
          <div className={styles["button-placeholder"]}></div>
          <div className={styles["three-dots-placeholder"]}>...</div>
        </>
      )}

      {/* show up to X buttons before current page */}
      {currentPage > 1 ? (
        <button
          className={styles["button"]}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          {currentPage - 1}
        </button>
      ) : (
        <div className={styles["button-placeholder"]}></div>
      )}
      <span className={styles["current-page"]}>{currentPage}</span>
      {/* show up to X buttons after current page */}
      {currentPage < Math.ceil(totalItems / pageSize) ? (
        <button
          className={styles["button"]}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          {currentPage + 1}
        </button>
      ) : (
        <div className={styles["button-placeholder"]}></div>
      )}

      {/* show button to the last page */}
      {currentPage < Math.ceil(totalItems / pageSize) - 2 ? (
        <>
          <span className={styles["three-dots"]}>...</span>
          <button
            className={styles["button"]}
            onClick={() => setCurrentPage(Math.ceil(totalItems / pageSize))}
          >
            {Math.ceil(totalItems / pageSize)}
          </button>
        </>
      ) : (
        <>
          <div className={styles["three-dots-placeholder"]}>...</div>
          <div className={styles["button-placeholder"]}></div>
        </>
      )}
      <button
        className={styles["button"]}
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage === Math.ceil(totalItems / pageSize)}
      >
        {">"}
      </button>
    </div>
  );
};
