import React from "react";
import { Trans } from "@coworker/locales";

import appBasics1 from "../../../assets/tutorial/appBasics/7.png";
import appBasics2 from "../../../assets/tutorial/appBasics/2.png";
import appBasics3 from "../../../assets/tutorial/appBasics/3.png";
import appBasics4 from "../../../assets/tutorial/appBasics/8.png";
import appBasics5 from "../../../assets/tutorial/appBasics/5.png";
import appBasics6 from "../../../assets/tutorial/appBasics/6.png";

import activity1 from "../../../assets/tutorial/activity/1.png";
import activity2 from "../../../assets/tutorial/activity/2.png";

import openTasks1 from "../../../assets/tutorial/openTasks/1.png";
import openTasks2 from "../../../assets/tutorial/openTasks/2.png";
import openTasks3 from "../../../assets/tutorial/openTasks/3.png";

import myTasks1 from "../../../assets/tutorial/myTasks/1.png";
import myTasks2 from "../../../assets/tutorial/myTasks/2.png";
import myTasks3 from "../../../assets/tutorial/myTasks/3.png";
import myTasks4 from "../../../assets/tutorial/myTasks/4.png";

import creatingTasks1 from "../../../assets/tutorial/creatingTasks/1.png";
import creatingTasks2 from "../../../assets/tutorial/creatingTasks/2.png";
import creatingTasks3 from "../../../assets/tutorial/creatingTasks/3.png";
import creatingTasks4 from "../../../assets/tutorial/creatingTasks/4.png";
import creatingTasks5 from "../../../assets/tutorial/creatingTasks/5.png";
import creatingTasks6 from "../../../assets/tutorial/creatingTasks/6.png";
import creatingTasks7 from "../../../assets/tutorial/creatingTasks/7.png";
import creatingTasks8 from "../../../assets/tutorial/creatingTasks/8.png";
import creatingTasks9 from "../../../assets/tutorial/creatingTasks/9.png";
import creatingTasks9b from "../../../assets/tutorial/creatingTasks/9b.png";
import creatingTasks10 from "../../../assets/tutorial/creatingTasks/10.png";

import insights1 from "../../../assets/tutorial/insights/1.png";
import insights2 from "../../../assets/tutorial/insights/2.png";
import insights3 from "../../../assets/tutorial/insights/3.png";
import insights4 from "../../../assets/tutorial/insights/4.png";
import insights5 from "../../../assets/tutorial/insights/5.png";
import insights6 from "../../../assets/tutorial/insights/6.png";

import submitPQ1 from "../../../assets/tutorial/submitPQ/1.png";
import submitPQ2 from "../../../assets/tutorial/submitPQ/2.png";
import submitPQ3 from "../../../assets/tutorial/submitPQ/3.png";
import submitPQ4 from "../../../assets/tutorial/submitPQ/4.png";
import submitPQ4b from "../../../assets/tutorial/submitPQ/4-suppliernr.png";
import submitPQ5 from "../../../assets/tutorial/submitPQ/5.png";
import submitPQ6 from "../../../assets/tutorial/submitPQ/6-discover.png";
import submitPQ7a from "../../../assets/tutorial/submitPQ/7-select.png";
import submitPQ7b from "../../../assets/tutorial/submitPQ/7b-describe.png";
import submitPQ8a from "../../../assets/tutorial/submitPQ/8a-where-defect.png";
import submitPQ8b from "../../../assets/tutorial/submitPQ/8b-location.png";
import submitPQ8c from "../../../assets/tutorial/submitPQ/8c-custom-loc.png";
import submitPQ9what from "../../../assets/tutorial/submitPQ/9-what-other.png";
import submitPQ9 from "../../../assets/tutorial/submitPQ/9.png";
import submitPQ10 from "../../../assets/tutorial/submitPQ/10.png";

import pickupPQ1 from "../../../assets/tutorial/pickupPQ/1.png";
import pickupPQ2b from "../../../assets/tutorial/pickupPQ/2b.png";
import pickupPQ3 from "../../../assets/tutorial/pickupPQ/3.png";
import pickupPQ4 from "../../../assets/tutorial/pickupPQ/4.png";
import pickupPQ5 from "../../../assets/tutorial/pickupPQ/5.png";
import pickupPQ6 from "../../../assets/tutorial/pickupPQ/6.png";

import mfaq01 from "../../../assets/tutorial/mfaq/mfaq01.png";
import mfaq02 from "../../../assets/tutorial/mfaq/mfaq02.png";
import mfaq03 from "../../../assets/tutorial/mfaq/mfaq03.png";
import mfaq04 from "../../../assets/tutorial/mfaq/mfaq04.png";
import mfaq05 from "../../../assets/tutorial/mfaq/mfaq05.png";
import mfaq06 from "../../../assets/tutorial/mfaq/mfaq06.png";
import mfaq07 from "../../../assets/tutorial/mfaq/mfaq07.png";
import mfaq08 from "../../../assets/tutorial/mfaq/mfaq08.png";
import mfaq09 from "../../../assets/tutorial/mfaq/mfaq09.png";
import mfaq10 from "../../../assets/tutorial/mfaq/mfaq10.png";
import mfaq11 from "../../../assets/tutorial/mfaq/mfaq11.png";

export const widgets = [
  {
    type: "app-basics",
    title: <Trans>appBasicsString</Trans>,
    content: <Trans>appBasicsContentString</Trans>,
    link: true,
  },
  {
    type: "creating-tasks",
    title: <Trans>creatingTasksString</Trans>,
    content: <Trans>creatingTasksContentString</Trans>,
    link: true,
  },
  {
    type: "my-tasks",
    title: <Trans>myString</Trans>,
    content: <Trans>myTasksContentString</Trans>,
    link: true,
  },
  {
    type: "open-tasks",
    title: <Trans>openTasksString</Trans>,
    content: <Trans>openTasksContentString</Trans>,
    link: true,
  },
  {
    type: "activity",
    title: <Trans>activityString</Trans>,
    content: <Trans>activityContentString</Trans>,
    ingress: <Trans>activityIngressString</Trans>,
    link: true,
  },
  {
    type: "insights",
    title: <Trans>insightsString</Trans>,
    content: <Trans>insightsContentString</Trans>,
    ingress: <Trans>insightsIngress2String</Trans>,
    link: true,
  },
  {
    type: "pq-submit",
    title: <Trans>submitProductQualityReportString</Trans>,
    content: <Trans>submitProductQualityReportContentString</Trans>,
    ingress: <Trans>submitProductQualityReportIngress2String</Trans>,
    link: true,
  },
  {
    type: "pq-pickup",
    title: <Trans>pickupProductQualityReportString</Trans>,
    content: <Trans>pickupProductQualityReportContentString</Trans>,
    ingress: <Trans>pickupProductQualityReportIngress2String</Trans>,
    link: true,
  },
  {
    type: "mfaq",
    title: <Trans>mfaqGuideString</Trans>,
    content: <Trans>mfaqGuideIntroString</Trans>,
    ingress: <Trans>mfaqGuideIntroString</Trans>,
    link: true,
  },
];

export const mediaTypeEnums = {
  image: Symbol(),
  video: Symbol(),
};

export const allSteps = {
  activity: [
    {
      type: mediaTypeEnums.image,
      text: <Trans>activity1String</Trans>,
      media: activity1,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>activity2String</Trans>,
      media: activity2,
    },
  ],
  "app-basics": [
    {
      type: mediaTypeEnums.image,
      text: <Trans>appBasics1String_new</Trans>,
      media: appBasics1,
    },
    {
      separator: <Trans>notificationSettingsString</Trans>,
      num: 1,
      type: mediaTypeEnums.image,
      text: <Trans>appBasics2String</Trans>,
      media: appBasics2,
    },
    {
      num: 2,
      type: mediaTypeEnums.image,
      text: <Trans>appBasics3String</Trans>,
      media: appBasics3,
    },
    {
      separator: <Trans>navigateAndCreateTasksString</Trans>,
      num: 1,
      type: mediaTypeEnums.image,
      text: <Trans>appBasics4String_new</Trans>,
      media: appBasics4,
    },
    {
      num: 2,
      type: mediaTypeEnums.image,
      text: <Trans>appBasics5String</Trans>,
      media: appBasics5,
    },
    {
      num: 3,
      type: mediaTypeEnums.image,
      text: <Trans>appBasics6String</Trans>,
      media: appBasics6,
    },
  ],
  "creating-tasks": [
    {
      type: mediaTypeEnums.image,
      text: <Trans>creatingTasks01String</Trans>,
      media: creatingTasks1,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>creatingTasks02String</Trans>,
      media: creatingTasks2,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>creatingTasks03String</Trans>,
      media: creatingTasks3,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>creatingTasks04String</Trans>,
      media: creatingTasks4,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>creatingTasks05String</Trans>,
      media: creatingTasks5,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>creatingTasks06String</Trans>,
      media: creatingTasks6,
    },
    {
      separator: <Trans>createAProductIssueString</Trans>,
      num: 1,
      type: mediaTypeEnums.image,
      text: <Trans>creatingTasks07String</Trans>,
      media: creatingTasks7,
    },
    {
      num: 2,
      type: mediaTypeEnums.image,
      text: <Trans>creatingTasks08String</Trans>,
      media: creatingTasks8,
    },
    {
      separator: <Trans>createACustomString</Trans>,
      num: 1,
      type: mediaTypeEnums.image,
      text: <Trans>creatingTasks09String</Trans>,
      media: creatingTasks9,
    },
    {
      num: 2,
      type: mediaTypeEnums.image,
      text: <Trans>creatingTasks09bString</Trans>,
      media: creatingTasks9b,
    },
    {
      num: 3,
      type: mediaTypeEnums.image,
      text: <Trans>creatingTasks10String</Trans>,
      media: creatingTasks10,
    },
  ],
  insights: [
    {
      separator: <Trans>insightsStoreViewString</Trans>,
      type: mediaTypeEnums.image,
      text: <Trans>insights2Performance1String</Trans>,
      media: insights1,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>insights2Performance2String</Trans>,
      media: insights2,
    },
    {
      separator: <Trans>landingPageHFBdepsString</Trans>,
      num: 1,
      type: mediaTypeEnums.image,
      text: <Trans>insights2Performance3String</Trans>,
      media: insights3,
    },
    {
      num: 2,
      type: mediaTypeEnums.image,
      text: <Trans>insights2Performance4String</Trans>,
      media: insights4,
    },
    {
      num: 3,
      type: mediaTypeEnums.image,
      text: <Trans>insights2Performance5String</Trans>,
      media: insights5,
    },
    {
      num: 4,
      type: mediaTypeEnums.image,
      text: <Trans>insights2Performance6String</Trans>,
      media: insights6,
    },
  ],
  "my-tasks": [
    {
      type: mediaTypeEnums.image,
      text: <Trans>myTasks1String</Trans>,
      media: myTasks1,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>myTasks2String</Trans>,
      media: myTasks2,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>myTasks3String</Trans>,
      media: myTasks3,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>myTasks4String</Trans>,
      media: myTasks4,
    },
  ],
  "open-tasks": [
    {
      type: mediaTypeEnums.image,
      text: <Trans>openTasks1String</Trans>,
      media: openTasks1,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>openTasks2String</Trans>,
      media: openTasks2,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>openTasks3String</Trans>,
      media: openTasks3,
    },
  ],
  "pq-submit": [
    {
      separator: <Trans>submitProductQualityReportShortString</Trans>,
      type: mediaTypeEnums.image,
      text: <Trans>submitPQ01String</Trans>,
      media: submitPQ1,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>submitPQ02String</Trans>,
      media: submitPQ2,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>submitPQ03String</Trans>,
      media: submitPQ3,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>submitPQ04String</Trans>,
      media: submitPQ4,
    },
    {
      num: " ",
      type: mediaTypeEnums.image,
      text: <Trans>submitPQ04bString</Trans>,
      media: submitPQ4b,
    },
    {
      num: "5",
      type: mediaTypeEnums.image,
      text: <Trans>submitPQ05String</Trans>,
      media: submitPQ5,
    },

    {
      num: "6",
      type: mediaTypeEnums.image,
      text: <Trans>submitPQ06aString</Trans>,
      media: submitPQ6,
    },
    {
      num: "7",
      type: mediaTypeEnums.image,
      text: <Trans>submitPQ07aString</Trans>,
      media: submitPQ7a,
    },
    {
      num: " ",
      type: mediaTypeEnums.image,
      text: <Trans>submitPQ07bString</Trans>,
      media: submitPQ7b,
    },

    {
      num: "8",
      type: mediaTypeEnums.image,
      text: <Trans>submitPQ08aString</Trans>,
      media: submitPQ8a,
    },
    {
      num: " ",
      type: mediaTypeEnums.image,
      text: <Trans>submitPQ08bString</Trans>,
      media: submitPQ8b,
    },

    {
      num: " ",
      type: mediaTypeEnums.image,
      text: <Trans>submitPQ08cString</Trans>,
      media: submitPQ8c,
    },

    {
      num: "9",
      type: mediaTypeEnums.image,
      text: <Trans>submitPQ09whatString</Trans>,
      media: submitPQ9what,
    },

    {
      num: "10",
      type: mediaTypeEnums.image,
      text: <Trans>submitPQ09String</Trans>,
      media: submitPQ9,
    },
    {
      num: "11",
      type: mediaTypeEnums.image,
      text: <Trans>submitPQ10String</Trans>,
      media: submitPQ10,
    },
  ],
  "pq-pickup": [
    {
      separator: <Trans>pickupProductQualityReportShortString</Trans>,
      type: mediaTypeEnums.image,
      text: <Trans>pickupPQ1String</Trans>,
      media: pickupPQ1,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>pickupPQ2String</Trans>,
      media: pickupPQ2b,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>pickupPQ3String</Trans>,
      media: pickupPQ3,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>pickupPQ4String</Trans>,
      media: pickupPQ4,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>pickupPQ5String</Trans>,
      media: pickupPQ5,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>pickupPQ6String</Trans>,
      media: pickupPQ6,
    },
  ],
  mfaq: [
    {
      type: mediaTypeEnums.image,
      text: <Trans>mfaq01String</Trans>,
      media: mfaq01,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>mfaq02String</Trans>,
      media: mfaq02,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>mfaq03String</Trans>,
      media: mfaq03,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>mfaq04String</Trans>,
      media: mfaq04,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>mfaq05String</Trans>,
      media: mfaq05,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>mfaq06String</Trans>,
      media: mfaq06,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>mfaq07String</Trans>,
      media: mfaq07,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>mfaq08String</Trans>,
      media: mfaq08,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>mfaq09String</Trans>,
      media: mfaq09,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>mfaq10String</Trans>,
      media: mfaq10,
    },
    {
      type: mediaTypeEnums.image,
      text: <Trans>mfaq11String</Trans>,
      media: mfaq11,
    },
  ],
};
