import React from "react";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import ArrowLeftIcon from "@ingka/ssr-icon/paths/arrow-left";

import styles from "./app-bar.module.css";
import SSRIcon from "@ingka/ssr-icon";

export type AppBarAction = {
  name: string;
  icon: React.JSX.Element;
  onClick: () => void;
  position: "left" | "right";
  disabled?: boolean;
  testId?: string;
};

export interface AppBarProps {
  title: string | React.ReactNode;
  subtitle?: string;
  actions: AppBarAction[];
  backButton?: boolean;
  onBack?: () => void;
}

export const AppBar = ({
  title,
  subtitle,
  actions,
  backButton = true,
  onBack,
}: AppBarProps) => {
  const { pop } = useWorkspacesAction();

  const handleBackButtonClick = () => {
    if (onBack) {
      onBack();
    } else {
      pop();
    }
  };

  return (
    <div
      className={`${styles["app-bar"]} ${
        subtitle ? styles["with-subtitle"] : ""
      }`}
    >
      <div className={styles["actions-left"]}>
        {backButton && (
          <div
            className={styles["icon-wrapper"]}
            onClick={handleBackButtonClick}
          >
            <SSRIcon paths={ArrowLeftIcon} />
          </div>
        )}

        {actions
          .filter((action) => action.position === "left")
          .map((action, index) => (
            <div
              key={index}
              className={styles["icon-wrapper"]}
              onClick={action.onClick}
            >
              {action.icon}
            </div>
          ))}
      </div>
      <div className={styles["center"]}>
        <div className={styles["title"]}>{title}</div>
        {subtitle && <div className={styles["subtitle"]}>{subtitle}</div>}
      </div>
      <div className={styles["actions-right"]}>
        {actions
          .filter((action) => action.position === "right")
          .map((action, index) => (
            <div
              key={index}
              className={styles["icon-wrapper"]}
              onClick={action.onClick}
            >
              {action.icon}
            </div>
          ))}
      </div>
    </div>
  );
};
