import React from "react";
import { useTranslation } from "@coworker/locales";
import { TodaysProgress } from "./TodaysProgress";
import { Rounds } from "../Rounds/Rounds";
import { Filters } from "../Filters/Filters";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import SSRIcon from "@ingka/ssr-icon";
import SettingsIcon from "@ingka/ssr-icon/paths/gear";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { AppBarAction } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";

export const ProgressOverview = () => {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();

  const appBarActions: AppBarAction[] = [
    {
      name: "close",
      icon: <SSRIcon paths={SettingsIcon} />,
      onClick: () => push("/shoppingtools/settings"),
      position: "right",
    },
  ];
  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("shoppingToolsHeaderString"),
        actions: appBarActions,
      }}
      noPadding
    >
      <Filters showPeriod={false} />
      <TodaysProgress />
      <Rounds />
    </FullScreenPopup>
  );
};
