import React from "react";
import Text from "@ingka/text";
import { Trans } from "@coworker/locales";
import { useGivenName } from "@coworker/app/src/core/auth/useLoggedInUser";
import { EnvironmentTag } from "@coworker/app/src/components/EnvironmentTag";
import { QuickActions } from "../QuickActions/QuickActions";
import { TasksPreview } from "../TasksPreview/TasksPreview";
import { Tutorials } from "../Tutorials/Tutorials";
import styles from "./home-page.module.css";

export const HomePage = () => {
  const firstname = useGivenName();
  return (
    <div className={styles["home-outer-container"]}>
      <div className={styles["home-container"]}>
        <div className={styles["vertical-center"]}>
          <Text headingSize="m">
            {<Trans>hejString</Trans>}, {firstname}
          </Text>
          <EnvironmentTag />
        </div>
        <div className={styles["quick-actions-container"]}>
          <QuickActions id="quickActionsHome" />
        </div>
        <TasksPreview />
        <Tutorials />
      </div>
    </div>
  );
};
