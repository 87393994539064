import { getTokenAsync } from "../../../hooks/API/getAuthToken";
import { makeHeaders } from "../tools";
import {
  Article,
  Articles,
  PartialArticle,
  EdsData,
  SalesStopData,
} from "../types/article";
import { ROOM_SETTINGS_SERVICE_URL } from "./service.helper";
import { Ids } from "../types/images";

const BASE_URL = ROOM_SETTINGS_SERVICE_URL + "/articles";

export const getArticleById = async (articleId: string) => {
  try {
    const token = await getTokenAsync(true);
    const response = await fetch(`${BASE_URL}/${articleId}`, {
      method: "GET",
      headers: makeHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const article = (await response.json()) as Article;
    return article;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return null;
};

export const updateArticle = async (
  articleId: string,
  article: PartialArticle
) => {
  const token = await getTokenAsync(true);

  const url = `${BASE_URL}/${articleId}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: makeHeaders(token),
    body: JSON.stringify(article),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  return;
};

export const addArticle = async (article: Article) => {
  const token = await getTokenAsync(true);

  const response = await fetch(`${BASE_URL}/single`, {
    method: "POST",
    headers: makeHeaders(token),
    body: JSON.stringify(article),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const data = (await response.json()) as Ids;
  return data;
};

export const addArticlesBulk = async (articles: Articles, groupId: string) => {
  const token = await getTokenAsync(true);

  const response = await fetch(
    `${BASE_URL}${!!groupId ? `?groupId=${groupId}` : ""}`,
    {
      method: "POST",
      headers: makeHeaders(token),
      body: JSON.stringify(articles),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const data = (await response.json()) as Articles;
  return data;
};

export const deleteArticleById = async (articleId: string) => {
  const token = await getTokenAsync(true);

  const response = await fetch(`${BASE_URL}/${articleId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  return;
};

export const deleteArticleByIds = async (articleIds: string[]) => {
  const token = await getTokenAsync(true);

  const response = await fetch(`${BASE_URL}?ids=${articleIds.join(",")}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  return;
};

export const getEDSData = async (countryId: string, articleNumbers: string) => {
  try {
    const token = await getTokenAsync(true);
    const url = `${BASE_URL}/eds/${encodeURIComponent(
      countryId
    )}?articleNumbers=${articleNumbers}`;
    const response = await fetch(url, {
      method: "GET",
      headers: makeHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Response was not ok.");
    }

    // Unhide EDS by removing the return of an empty array and uncommenting the line below
    return [] as EdsData[]; // (await response.json()) as EdsData[];
  } catch (error) {
    console.error("Fetch error:", error);
  }

  return null;
};

export const getSalesStopData = async (
  storeId: string,
  articleNumbers: string
) => {
  try {
    const token = await getTokenAsync(true);
    const url = `${BASE_URL}/salesstop/${encodeURIComponent(
      storeId
    )}?articleNumbers=${articleNumbers}`;
    const response = await fetch(url, {
      method: "GET",
      headers: makeHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Response was not ok.");
    }

    return (await response.json()) as SalesStopData[];
  } catch (error) {
    console.error("Fetch error:", error);
  }

  return [];
};
