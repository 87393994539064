import React from "react";
import { Trans } from "@coworker/locales";
import { PassRatioProps } from "./PassRatioProps";
import { ActivityIndicator } from "@coworker/components/src/components/Card";
import DonutChart from "@coworker/components/src/components/DonutChart";
import styled from "styled-components";
import { GLOBAL_PASS_THRESHOLD } from "../../../../constants/global";

export const ItemRowWrapper = styled.div`
  margin-top: 15px;
`;

export const ItemRow = styled.div`
  display: flex;
  align-items: space-between;
  font-size: 12px;
  line-height: 18px;
  padding: 3px 15px;
  & :last-child {
    margin-left: auto;
  }
`;

const GrayText = styled.div`
  display: flex;
  padding: 3px 15px;
  font-size: 12px;
  line-height: 18px;
  color: var(--grey700);
`;

const Spacer = styled.div`
  height: 20px;
  padding: 0px;
`;

export function PassRatioDonutOverview({
  percent,
  totalArticles,
  totalTestbuysDone,
}: PassRatioProps) {
  const loading = false;
  const globalPassThreshold = GLOBAL_PASS_THRESHOLD;

  return (
    <>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <DonutChart
            enabled={true}
            percent={percent}
            title=""
            failurePercent={globalPassThreshold}
            width={200}
            topMargin={0}
          />
          <ItemRowWrapper>
            <ItemRow>
              <div>
                <Trans>testBuysDoneString</Trans>
              </div>
              <b>{totalTestbuysDone}</b>
            </ItemRow>
            <ItemRow>
              <div>
                <Trans>totalArticlesBoughtString</Trans>
              </div>
              <b>{totalArticles}</b>
            </ItemRow>
          </ItemRowWrapper>
          {percent < globalPassThreshold && (
            <GrayText>
              <Trans values={{ globalPassThreshold }}>
                globalPassThresholdInformation
              </Trans>
            </GrayText>
          )}
          <Spacer />
        </>
      )}
    </>
  );
}
