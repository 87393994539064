import CommercialMessage from "@ingka/commercial-message";
import Image from "@ingka/image";
import styled, { css } from "styled-components";

export const ListWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
`;

export const GreyListWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  background-color: var(--grey100);
`;

export const MarginBottom = styled.div`
  margin-bottom: 70px;
`;

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 24px;
`;

export const LeftContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CenterContainer = styled.div<{ $centerText: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({ $centerText }) => ($centerText ? "center" : "left")};
  text-align: ${({ $centerText }) => ($centerText ? "center" : "left")};
`;

export const CenterContainerWithGap = styled.div<{ $centerText: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({ $centerText }) => ($centerText ? "center" : "left")};
  text-align: ${({ $centerText }) => ($centerText ? "center" : "left")};
  gap: 10px;
`;

export const RightContainer = styled.div`
  flex-grow: 0;
`;

export const SizedText = styled.div<{ $fontSize: number; $bold?: boolean }>`
  font-size: ${({ $fontSize }) => $fontSize}px;
  font-weight: ${({ $bold }) => ($bold ? "bold" : "normal")};
`;

export const Overlay = styled.div`
  position: relative;
  ${({ minHeight }: { minHeight: string }) =>
    css`
      min-height: ${minHeight};
    `}
  max-width: 100%;
  svg {
    position: absolute;
    max-width: 100%;
    top: 0;
    left: 0;
  }
`;

export const ListItemWrapper = styled.div<{ $hasBanner: boolean }>`
  display: flex;
  flex-direction: column;
  box-shadow: 1px 0px 0px 1px var(--grey200);
  padding: 24px;
  gap: ${({ $hasBanner }) => ($hasBanner ? "5px" : "0px")};
`;

export const FlexibleRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const ListItemBanner = styled.div`
  display: flex;
  gap: 4px;
`;

export const MainProductMessage = styled(CommercialMessage)`
  font-size: 12px;
  border-radius: 2px;
  background-color: var(--blue-medium);
`;

export const BackupProductMessage = styled(CommercialMessage)`
  font-size: 12px;
  border-radius: 2px;
  background-color: var(--blue-lighter);
  color: var(--blue-medium);
`;

export const GreyBox = styled.div`
  width: 144px;
  height: 120px;
  background-color: var(--grey100);
  border-radius: 8;
  margin: 16px 24px;
  padding: 20px;
`;

export const SearchBarContainer = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  .search__clear {
    position: absolute;
  }
`;

export const OngoingTaskMessage = styled(CommercialMessage)`
  font-size: 12px;
  border-radius: 2px;
  color: var(--green);
  background-color: var(--green-lighter);
`;

export const EDSMessage = styled(CommercialMessage)`
  font-size: 12px;
  border-radius: 2px;
  color: var(--black);
  background-color: var(--grey100);
`;

export const SalesStopMessage = styled(CommercialMessage)`
  font-size: 12px;
  border-radius: 2px;
  color: #cb3553;
  background-color: #f7e7ed;
`;

export const CarouselImage = styled(Image)<{ $rotation: number }>`
  width: 327px;
  height: 362px;
  object-fit: cover;
  transform: rotate(${({ $rotation }) => $rotation}deg);
`;

export const PaddedContainer = styled.div<{ $py: string; $px: string }>`
  padding: ${({ $py }) => $py} ${({ $px }) => $px};
`;

export const ListItemImageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

export const ListItemImage = styled(Image)<{ $rotation: number }>`
  width: 73px;
  height: 73px;
  border-radius: 5px;
  object-fit: cover;
  transform: rotate(${({ $rotation }) => $rotation}deg);
`;

export const SimpleContainer = styled.div<{ $direction: "column" | "row" }>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 24px;
`;

export const FlexContainer = styled.div<{ $direction: "column" | "row" }>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  width: 100%;
  gap: 8px;
  padding: 0px 24px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const fontSizeMap = {
  small: "12px",
  medium: "14px",
  large: "16px",
  "x-large": "18px",
};

export const TextStyle = styled.div<{
  $bold: boolean;
  $italic: boolean;
  $color: string;
  $size: keyof typeof fontSizeMap;
}>`
  font-size: ${({ $size }) => fontSizeMap[$size]};
  font-weight: ${({ $bold }) => ($bold ? "700" : "400")};
  font-style: ${({ $italic }) => ($italic ? "italic" : "normal")};
  color: var(--${({ $color }) => $color});
`;

// Roomsettings InputPopup styles
export const InputPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  background-color: var(--white);
  padding: 0 24px;
`;

export const InputPopupContentBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StickyButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--white);
  gap: 4px;
  padding-bottom: 8px;
`;

export const ZoomImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const ZoomStaticButtonContainer = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: 10;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
