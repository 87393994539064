import React, { useState } from "react";
import Button from "@ingka/button";
import filtersIcon from "@ingka/ssr-icon/paths/filters";
import { DropdownPill } from "./pills/DropdownPill";
import styles from "./activity-filters.module.css";
import { CheckBoxPill } from "./pills/CheckBoxPill";
import { dropdownPills, checkBoxPills } from "../../constants/filterPills";
import { ActivityFiltersPanel } from "./panel/ActivityFiltersPanel";
import { Trans } from "@coworker/locales";
import { ActivityFilterType } from "../../types/filterOptionsTypes";
import trackerHelper from "@coworker/app/src/helpers/tracker";
import { Task } from "@coworker/types/lib/tasks/base";
import {
  getGrouppedFilterOptions,
  FilterOptionType,
} from "../../constants/filterOptions";
import { useStoreTeams } from "@coworker/app/src/core/hooks/useStoreTeams";
import { useActivityLocations } from "../../hooks/useActivityData";
import { useFixaUID } from "@coworker/app/src/core/auth/useLoggedInUser";

interface ActivityFiltersProps {
  filters: ActivityFilterType;
  setFilters: React.Dispatch<React.SetStateAction<ActivityFilterType>>;
  appliedFiltersCount: number;
  allTasks: Task[];
}

export const ActivityFilters = ({
  filters,
  setFilters,
  appliedFiltersCount,
  allTasks,
}: ActivityFiltersProps) => {
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
  const [activeDropdownPill, setActiveDropdownPill] = useState<String>("");

  const usersFixaUid = useFixaUID();

  const handleFiltersSet = (newFilters: ActivityFilterType) => {
    setActiveDropdownPill("");
    if (newFilters.assignedTo === "me") {
      newFilters.assignedTo = usersFixaUid;
    }

    if (newFilters.createdBy === "me") {
      newFilters.createdBy = usersFixaUid;
    }

    setFilters(newFilters);
  };

  const teams = useStoreTeams();
  //TODO: Should we keep the non empty teams?
  // const nonEmptyTeams = teams.filter((team) => team.user_count > 0);
  const { data: taskLocations, isLoading } = useActivityLocations(
    filters.taskType
  );

  const filtersAbsentInDropdownPills = ["locations"];

  return (
    <div className={styles["filters-container"]}>
      <Button
        type="primary"
        ssrIcon={filtersIcon}
        text={
          <>
            <Trans>allFiltersString</Trans>{" "}
            {appliedFiltersCount > 0 && `(${appliedFiltersCount})`}
          </>
        }
        small
        onClick={() => {
          trackerHelper.trackActivityAllFiltersButtonClick();
          setIsPanelOpen(true);
        }}
      />
      <ActivityFiltersPanel
        isPanelOpen={isPanelOpen}
        setIsPanelOpen={setIsPanelOpen}
        filters={filters}
        setFilters={handleFiltersSet}
        allTasks={allTasks}
      />

      {dropdownPills.map((pill) => {
        if (filtersAbsentInDropdownPills.includes(pill.id)) {
          return null;
        }
        const options: FilterOptionType[] = [];
        const additionalPillAttributes: Record<string, unknown> = {}; // this is for spreading attributes for the Skapa Pill which not every Pill uses (eg. loding for locations)
        if (pill.id === "assignedTo" || pill.id === "createdBy") {
          options.push(...teams);
        } else if (pill.id === "locations") {
          if (taskLocations) {
            options.push(...taskLocations);
          }
          additionalPillAttributes["disabled"] = isLoading;
        } else {
          options.push(...getGrouppedFilterOptions()[pill.id]);
        }

        if (options.length === 0) {
          additionalPillAttributes["disabled"] = true;
        }

        if (!filters.taskType && pill.id === "locations") {
          additionalPillAttributes["disabled"] = true;
        }

        return (
          <DropdownPill
            key={pill.id}
            id={pill.id}
            label={pill.transKey}
            isOpen={activeDropdownPill === pill.transKey}
            onClick={() =>
              setActiveDropdownPill(
                activeDropdownPill !== pill.transKey ? pill.transKey : ""
              )
            }
            filters={filters}
            setFilters={handleFiltersSet}
            setIsPanelOpen={setIsPanelOpen}
            closeDropdown={() => setActiveDropdownPill("")}
            options={getGrouppedFilterOptions(options)[pill.id]}
            additionalPillAttributes={additionalPillAttributes}
          />
        );
      })}
      {checkBoxPills.map((pill) => (
        <CheckBoxPill
          key={pill.id}
          id={pill.id}
          label={pill.transKey}
          filters={filters}
          setFilters={handleFiltersSet}
        />
      ))}
    </div>
  );
};
