import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences.json";
import { Trans } from "@coworker/locales";
import { useFeatureFlagHooks } from "./useMFAQs.featureToggle";
import UserFiltersRow from "../InputPopup/UserFiltersRow/UserFiltersRow";
import {
  areaIsWholeStore,
  defaultFilters,
  describeAreaFilter,
  getAreaFilter,
  getPeriodFilter,
} from "../InputPopup/UserFiltersRow/filterChoices";
import { usePeriodTimestamps } from "../InputPopup/UserFiltersRow/usePeriodTimestamps";
import { VotePill } from "./VotePill";
import Question from "./Question";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import VotesGraphCard from "./VotesGraphCard";
import { useGroupType } from "./VotesGraphCard";
import taskState from "@coworker/functions/src/enums/taskState.json";
import TaskHistory from "./TaskHistory";
import { useMfaqOptionPopupMenu } from "../../features/MFAQ/useMfaqOptionPopupMenu";
import { LoaderIcon } from "@coworker/reusable/Loader";
import tracker from "../../helpers/tracker";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import FullscreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import Button from "@ingka/button";
import SSRIcon from "@ingka/ssr-icon";
import EllipsisIcon from "@ingka/ssr-icon/paths/ellipses-horizontal";

const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const VoteBox = styled.div`
  box-sizing: border-box;
  margin: 24px;
  margin-bottom: 32px;

  p {
    color: var(--grey900);
    letter-spacing: -0.0042em;
    font-weight: bold;
    font-size: 25px;
    line-height: 34px;
    margin-bottom: 24px;
  }
`;

const OtherAreas = styled.div`
  margin-bottom: 8px;

  & > p {
    padding: 17px 24px;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
  }
`;

const BottomMargin = styled.div`
  margin-bottom: 90px;
`;

const Loader = styled(LoaderIcon)`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 45%;
  z-index: 1;
`;

export default function CustomerMFAQDetail() {
  const { useMFAQ, createVote, useGetTasksOnQuestion } = useFeatureFlagHooks();
  const { id } = useParams();
  const [filters, setFilters] = useLocalFilters();
  const [startDate, endDate] = usePeriodTimestamps(filters);
  const question = useMFAQ(id, startDate, endDate);
  const [initialQuestion, setInitialQuestion] = React.useState(null);
  const [votesFudgeFactorsPerArea, setVotesFudgeFactors] = React.useState({});
  let isWholeStore = areaIsWholeStore(filters);
  const store_id = useStoreId();
  const { push } = useWorkspacesAction();
  const interval = useGroupType(filters);

  /**
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const pageContainerRef = React.useRef();

  const otherAreasQuestions = useQuestionsInOtherAreas(
    votesFudgeFactorsPerArea,
    question?.question_text,
    question?.upvotes_by_area,
    filters,
    setFilters,
    pageContainerRef
  );

  const [allVotesCountInArea, setAllVotesCountInArea] = React.useState(0);
  const [unresolvedVotesCountInArea, setUnresolvedVotesCountInArea] =
    React.useState(0);

  const { updatedQuestion, getMfaqOptionsMenu, loading } =
    useMfaqOptionPopupMenu(id, initialQuestion?.subject);

  React.useEffect(() => {
    if (isWholeStore) {
      const voteCountForStore = question?.upvotes || 0;

      setAllVotesCountInArea(voteCountForStore);
      setUnresolvedVotesCountInArea(voteCountForStore);
    } else {
      const voteCountForArea = question?.upvotes_by_area?.find(
        (votes) => votes.area === getAreaFilter(filters)
      );

      setAllVotesCountInArea(voteCountForArea?.votes || 0);
      setUnresolvedVotesCountInArea(voteCountForArea?.votes_unresolved || 0);
    }
  }, [filters, isWholeStore, question]);

  React.useEffect(() => {
    if (question) {
      setInitialQuestion(question);
    }
  }, [question]);

  React.useEffect(() => {
    if (updatedQuestion) {
      setInitialQuestion(updatedQuestion);
    }
  }, [updatedQuestion]);

  const area = getAreaFilter(filters);

  const { data: tasksOnQuestion } = useGetTasksOnQuestion(
    id,
    interval,
    startDate,
    endDate,
    area,
    isWholeStore
  );

  const hasOngoingTasks = tasksOnQuestion?.filter(function (task) {
    return (
      task.state !== taskState.COMPLETED && task.state !== taskState.CLOSED
    );
  });

  const completedTasks =
    question &&
    question?.tasks?.filter(function (task) {
      return (
        task.state === taskState.COMPLETED &&
        task.area === getAreaFilter(filters)
      );
    });

  const questionActions = question && question?.actions;
  const taskId =
    hasOngoingTasks && !!hasOngoingTasks.length && hasOngoingTasks[0].task_id;

  const taskString = taskId ? "showTaskString" : "createTaskString";

  const createMFAQTask = () => {
    push(`/task/new/mfaq_followup`, {
      question: { ...initialQuestion, question_current_filters: filters },
    });
  };
  const showMFAQTask = () => {
    push(`/task/${taskId}`);
  };
  const editQuestionMenu = () => {
    getMfaqOptionsMenu();
    tracker.MFAQ.trackMenuClick3Dots();
  };
  isWholeStore = false;
  return (
    <FullscreenPopup
      noPadding
      actionBarContent={
        !isWholeStore && (
          <Button
            data-testid="createMFAQTask"
            type="primary"
            text={<Trans>{taskString}</Trans>}
            onClick={taskId ? showMFAQTask : createMFAQTask}
          />
        )
      }
      appBarConfig={{
        title: <Trans>questionStringMFAQ</Trans>,
        actions: [
          {
            name: "testingName",
            icon: <SSRIcon paths={EllipsisIcon} />,
            position: "right",
            onClick: editQuestionMenu,
          },
        ],
      }}
    >
      <div ref={pageContainerRef}>
        <UserFiltersRow
          filters={filters}
          settingsPrefix={profilePreferences.MFAQ_PREFIX}
        />
        {loading && <Loader />}
        {initialQuestion?.first_asked_at && (
          <>
            <VoteBox>
              <p>{initialQuestion.question_text}</p>
              {!isWholeStore && (
                <Center>
                  <VotePill
                    addVote={(question_id, value) => {
                      // Updates fudge factor for current area
                      const newFudges = updateFudges(
                        votesFudgeFactorsPerArea,
                        getAreaFilter(filters),
                        value
                      );
                      setVotesFudgeFactors(newFudges);
                      createVote(
                        question_id,
                        value,
                        filters[1],
                        store_id
                      ).catch(() =>
                        setVotesFudgeFactors(votesFudgeFactorsPerArea)
                      );
                    }}
                    question_id={id}
                    initialCount={
                      unresolvedVotesCountInArea +
                      getFudgeFactor(votesFudgeFactorsPerArea, filters)
                    }
                  />
                </Center>
              )}
            </VoteBox>
            <VotesGraphCard
              question_id={id}
              totalUpvotes={allVotesCountInArea}
              votesFudgeFactor={getFudgeFactor(
                votesFudgeFactorsPerArea,
                filters
              )}
              filters={filters}
              isWholeStore={isWholeStore}
              displayTasksOnGraph={true}
              tasksOnQuestion={tasksOnQuestion}
            />
            <TaskHistory tasks={completedTasks} actions={questionActions} />
          </>
        )}
        {otherAreasQuestions.length > 0 && (
          <>
            <OtherAreas>
              <p>
                <Trans>
                  {isWholeStore ? "allAreasString" : "otherAreasString"}
                </Trans>
              </p>
              {otherAreasQuestions}
              {isWholeStore && <BottomMargin />}
            </OtherAreas>
          </>
        )}
        {!isWholeStore && (
          <VotesGraphCard
            question_id={id}
            totalUpvotes={question?.upvotes}
            votesFudgeFactor={sumFudgesInAllAreas(votesFudgeFactorsPerArea)}
            filters={filters}
            isWholeStore
            tasksOnQuestion={tasksOnQuestion}
          />
        )}
      </div>
    </FullscreenPopup>
  );
}

function getFudgeFactor(factors, filters) {
  return factors[getAreaFilter(filters)] || 0;
}

function sumFudgesInAllAreas(votesFudgeFactors) {
  return Object.values(votesFudgeFactors).reduce((acc, val) => acc + val, 0);
}

/**
 * Updates the fudge factors object with the new factor for provided area
 * @param {{[area:string]: number}} votesFudgeFactorsPerArea
 * @param {string} area
 * @param {1|-1} value
 */
function updateFudges(votesFudgeFactorsPerArea, area, value) {
  const newFudges = {
    ...votesFudgeFactorsPerArea,
  };
  if (newFudges[area]) {
    newFudges[area] += value;
  } else {
    newFudges[area] = value;
  }
  return newFudges;
}

function useQuestionsInOtherAreas(
  votesFudgeFactorsPerArea,
  questionText = "",
  upvotesByArea = [],
  filters,
  setFilters,
  pageContainerRef
) {
  return React.useMemo(() => {
    // Create object that contains upvotes by area, merging fudged and actual
    // This is done in case user upvoted in a new area, so that we show that
    // as part of other areas too.
    const fudgedUpvotes = [
      ...Object.entries(votesFudgeFactorsPerArea).map(([area, votes]) => ({
        area,
        votes,
      })),
      ...upvotesByArea,
    ].reduce((acc, { area, votes }) => {
      if (acc[area] != null) {
        acc[area] += votes;
      } else {
        acc[area] = votes;
      }
      return acc;
    }, {});

    // Map all merged areas and upvotes into Question[] to display
    // as questions in other areas
    const otherAreasQuestions = Object.entries(fudgedUpvotes)
      .filter(([area]) => area !== getAreaFilter(filters))
      .map(([area, votes]) => {
        return (
          <Question
            onClick={() => {
              setFilters([getPeriodFilter(filters), area]);
              pageContainerRef.current &&
                pageContainerRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
            }}
            key={area}
            questionText={describeAreaFilter([area], true)}
            customSubtitle={questionText}
            upvotesCount={votes}
            noBottomBorder
            showUpvote={false}
            canUpvote={false}
            noLink
          />
        );
      });

    return otherAreasQuestions;
  }, [
    filters,
    pageContainerRef,
    questionText,
    setFilters,
    upvotesByArea,
    votesFudgeFactorsPerArea,
  ]);
}

/**
 * @returns {[string[], React.Dispatch<React.SetStateAction<string[]>>]}
 */
function useLocalFilters() {
  /**
   * @type {string[][]}
   */
  const [profileFilters] = useUserPreference(
    profilePreferences.MFAQ_PREFIX + profilePreferences.USER_FILTERS,
    defaultFilters
  );
  const [filters, setCurrentFilters] = React.useState(profileFilters);

  React.useEffect(() => {
    setCurrentFilters(profileFilters);
  }, [profileFilters]);

  return [filters, setCurrentFilters];
}
