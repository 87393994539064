import { sign } from "@coworker/reusable";
import { Criterion, TestBuy } from "@coworker/types";
import dayjs from "dayjs";
import { getSalesWeekFromDate } from "../../../../../Insights/helpers";
import { LineChartProps } from "../LineChartProps";
import { COMPLETED_STATUS } from "../../../../constants";

export function lastLabel(dates: LineChartProps[]) {
  return dates[dates.length - 1]?.label;
}

export function getPassRatio(testbuys: TestBuy[]) {
  const articles = testbuys.map((tb) => tb.articles).flat();

  const articlesPassing = articles.filter((a) =>
    a?.criteria.every((c: Criterion) => c.state === "OK")
  ).length;

  return (articlesPassing / articles.length) * 100;
}

export function getWeekLabel(chosenTestBuys: TestBuy[], date: Date) {
  const weekNumber = getSalesWeekFromDate(dayjs(date));

  const list = chosenTestBuys.filter(
    (tb) =>
      getSalesWeekFromDate(dayjs(new Date(tb.finishedAtTs!))) === weekNumber
  );

  return {
    label: `w${weekNumber}`,
    list: getPassRatio(list),
    totalTestBuysDone: list.length,
  };
}

export function getMonthLabel(chosenTestBuys: TestBuy[], date: Date) {
  const monthNumber = dayjs(date).format("MM");

  const list = chosenTestBuys.filter(
    (tb) => dayjs(new Date(tb.finishedAtTs!)).format("MM") === monthNumber
  );

  const label = dayjs(date).format("MMMM").slice(0, 3);

  return { label, list: getPassRatio(list), totalTestBuysDone: list.length };
}

export function getYearLabel(chosenTestBuys: TestBuy[], date: Date) {
  const year = dayjs(date).year().toString();

  const list = chosenTestBuys.filter(
    (tb) => dayjs(new Date(tb.finishedAtTs!)).format("YYYY") === year
  );
  return {
    label: year,
    list: getPassRatio(list),
    totalTestBuysDone: list.length,
  };
}

export function collectData(
  chosenTestBuys: TestBuy[],
  getLabel: Function,
  startDate: Date,
  endDate: Date
) {
  const date: Date = new Date(startDate.getTime());
  const dates: LineChartProps[] = [];

  while (date <= endDate) {
    const { label, list, totalTestBuysDone } = getLabel(chosenTestBuys, date);
    if (lastLabel(dates) !== label) {
      dates.push({ label, passRatio: list, totalTestBuysDone });
    }

    date.setDate(date.getDate() + 1);
  }

  return dates;
}

export function getChosenTestBuys(
  totalFinishedTestbuys: TestBuy[],
  sinceTs: number,
  untilTs: number
) {
  return totalFinishedTestbuys
    .filter(
      (tb) =>
        // Following dashboards way to just get data from 1 sep, 2022. Also because finishedAt does not exist on old data.
        // TODO: Find exact formula used by dashboard
        tb.finishedAtTs! >= 1661990400000 &&
        tb.finishedAtTs! >= sinceTs &&
        tb.finishedAtTs! <= untilTs &&
        tb.status === COMPLETED_STATUS
      // !dayjs(tb.finishedAtTs).isBefore(dayjs("2022-09-01"))
    )
    .sort((a: TestBuy, b: TestBuy) =>
      sign((a.finishedAtTs || 0) - (b.finishedAtTs || 0))
    );
}

export const getChartData = (
  totalFinishedTestbuys: TestBuy[],
  sinceTs: number,
  untilTs: number,
  period: string
) => {
  function getDaysLabel(chosenTestBuys: TestBuy[], date: Date) {
    const list = chosenTestBuys.filter(
      (t) =>
        dayjs(new Date(t.finishedAtTs!)).format("YYYY-MM-DD") ===
        dayjs(date).format("YYYY-MM-DD")
    );

    return {
      label:
        period === "period=7days"
          ? `${new Date(date).toString().split(" ")[0]?.toLowerCase()}String`
          : dayjs(date).format("DD/MM"),
      list: getPassRatio(list),
      totalTestBuysDone: list.length,
    };
  }

  function getDatesInRange(startDate: Date, endDate: Date) {
    const daysBetweenDates = dayjs(new Date(untilTs || 0)).diff(
      new Date(sinceTs || 0),
      "day"
    );

    const chosenTestBuys = getChosenTestBuys(
      totalFinishedTestbuys,
      sinceTs,
      untilTs
    );

    if (daysBetweenDates <= 7) {
      //Days
      return collectData(chosenTestBuys, getDaysLabel, startDate, endDate);
    } else if (daysBetweenDates > 7 && daysBetweenDates <= 70) {
      //Weeks
      return collectData(chosenTestBuys, getWeekLabel, startDate, endDate);
    } else if (daysBetweenDates >= 70 && daysBetweenDates < 365) {
      //Months
      return collectData(chosenTestBuys, getMonthLabel, startDate, endDate);
    } else {
      //Years
      return collectData(chosenTestBuys, getYearLabel, startDate, endDate);
    }
  }

  return getDatesInRange(new Date(sinceTs), new Date(untilTs));
};
