import React from "react";
import styled from "styled-components";
import { Button, Header } from "@coworker/components";
import { Trans, useTranslation } from "@coworker/locales";
import { ReactComponent as OneOfThreeSteps } from "../../assets/svg/pq-one-of-three.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background: var(--white);
  overflow: hidden;
  overflow-y: auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--grey900);
`;

const Content = styled.div`
  margin-top: 80px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 30px;
`;

const Subtitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 24px;
  margin-left: 28px;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 26px;
  height: 88px;
`;

const StyledButton = styled(Button)`
  width: 90%;
  margin: 0 auto;
`;

const Process = styled.div`
  display: flex;
  flex-direction: row;

  svg {
    margin-left: 30px;
    margin-right: 20px;
  }
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function PQReportSubmitted({ onClose }) {
  const { t } = useTranslation();
  return (
    <Container>
      <Header onClose={onClose} showRightClose />
      <Title>
        <Trans>pqReportTitleString</Trans> 🎉
      </Title>

      <Content>
        <Subtitle>
          <Trans>whatHappensNextString</Trans>
        </Subtitle>
        <Process>
          <OneOfThreeSteps />
          <Stack>
            <Text>
              <Trans>pqReportBeingSentString</Trans>
            </Text>
            <Text>
              <Trans>pqReportPickedUpString</Trans>
            </Text>
            <Text>
              <Trans>pqReportConcludedString</Trans>
            </Text>
          </Stack>
        </Process>
      </Content>
      <StyledButton
        customMargin
        secondary
        dark
        text={t("iUnderstandString")}
        onClick={onClose}
        data-testid="PQiUnderstand"
      />
    </Container>
  );
}
