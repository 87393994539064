import React from "react";
import QuantityStepper from "@ingka/quantity-stepper";

import "@ingka/focus/dist/style.css";
import "@ingka/quantity-stepper/dist/style.css";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";

export interface SkapaQuantityStepperProps {
  ariaDescribedBy: string;
  ariaDescribedById: string;
  minVal: number;
  maxVal: number;
  defaultValue: number;
  onQuantityChange: (value: number) => void;
}

export const SkapaQuantityStepper: React.FC<SkapaQuantityStepperProps> = ({
  ariaDescribedBy,
  ariaDescribedById,
  minVal,
  maxVal,
  defaultValue,
  onQuantityChange,
}) => {
  return (
    <QuantityStepper
      small={true}
      ariaDescribedBy={ariaDescribedBy}
      ariaDescribedById={ariaDescribedById}
      minVal={minVal}
      maxVal={maxVal}
      defaultValue={defaultValue}
      onQuantityChange={onQuantityChange}
    />
  );
};
