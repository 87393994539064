import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { isRDTDevice } from "@coworker/reusable";
import { ReactComponent as QuestionIcon } from "../assets/svg/questionmark-icon.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// $isRdt is a transient prop that styled-components does not pass down to the DOM
// https://styled-components.com/docs/api#transient-props (avoids error in tests)
const QuestionMarkIcon = styled(QuestionIcon)<{ $isRdt: boolean }>`
  margin-top: ${({ $isRdt }) => ($isRdt ? "2rem" : "6rem")};
  margin-bottom: ${({ $isRdt }) => ($isRdt ? "1rem" : "2rem")};
`;

const Text = styled.p`
  padding: 0.5rem;
  text-align: center;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

interface PageProps {
  titleKey: string;
  subtitleKey: string;
}

function EmptyPage(props: PageProps) {
  return (
    <Container>
      <QuestionMarkIcon $isRdt={isRDTDevice()} />
      <Text>
        <Trans>{props.titleKey}</Trans>
      </Text>
      <BoldText>
        <Trans>{props.subtitleKey}</Trans>
      </BoldText>
    </Container>
  );
}

export default React.memo(EmptyPage);
