import React from "react";
import styled from "styled-components";
import { useFetchedBasicProduct } from "../../hooks/useBasicProductsMap";
import { formatFullIdWithDots } from "@coworker/reusable";
import { PreviewLink } from "../../core/components/Previews";

const Title = styled.p`
  color: var(--black);
  font-weight: bold;
  font-size: 14px;
`;

const Description = styled.p`
  color: var(--grey718);
  font-size: 14px;
`;

export function InsightProductItem({ fullId }) {
  const { basicProduct } = useFetchedBasicProduct(fullId);
  const { smallImage, name, descriptives } = basicProduct;
  return (
    <PreviewLink to={`/product/${fullId}`} image={smallImage}>
      <Title>{name}</Title>
      <Description>{descriptives[0]}</Description>
      <Description>{formatFullIdWithDots(fullId)}</Description>
    </PreviewLink>
  );
}
