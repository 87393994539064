import { Article, Articles, PartialArticle } from "../../types/article";
import {
  deleteArticleById,
  updateArticle,
} from "../../services/articles.service";
import { addArticle } from "../../services/articles.service";

export async function handleArticleUpdateWithNewArticleNumber(
  article: Article,
  newGroupId: string,
  articles: Articles,
  invalidateQueries: () => Promise<void>
) {
  const newNbrArticles = article?.newNbrArticles ?? article.nbrArticles;

  const existingTargetArticle = articles.find(
    (art) =>
      art.productArticleId === article.productArticleId &&
      newGroupId === art.groupId
  );

  if (!!existingTargetArticle) {
    existingTargetArticle.nbrArticles += newNbrArticles;
    if (newNbrArticles >= article.nbrArticles) {
      // All articles in the group are moved to another group
      await updateArticle(existingTargetArticle.id, existingTargetArticle);
      await deleteArticleById(article.id);
    } else {
      // Partial articles in are moved to another group
      article.nbrArticles -= newNbrArticles;
      await updateArticle(article.id, article);
      await updateArticle(existingTargetArticle.id, existingTargetArticle);
    }
  } else {
    if (newNbrArticles >= article.nbrArticles) {
      // All articles in the group are moved to another group
      article.nbrArticles = newNbrArticles;
      article.groupId = newGroupId;
      await updateArticle(article.id, article);
    } else {
      // Partial articles in are moved to another group
      article.nbrArticles = article.nbrArticles - newNbrArticles;
      await updateArticle(article.id, article);
      const newArticleInSelectedGroup = {
        ...article,
        nbrArticles: newNbrArticles,
        groupId: newGroupId,
      } as PartialArticle;
      delete newArticleInSelectedGroup.id;
      await addArticle(newArticleInSelectedGroup as Article);
    }
  }
  await invalidateQueries();
}
