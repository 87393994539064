import styled from "styled-components";
import { Button } from "@coworker/components";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(100% - 90px);
  font-size: 14px;
  overflow-y: scroll;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const WideButton = styled(Button)`
  width: 80%;
  margin: 24px;
`;
