export enum BackendServices {
  MFAQ = "mfaq-service",
  SHOPPING_TOOL = "shoppingtool-service",
}

export enum MfaqApiEndpoints {
  GET_BY_ID = "questions/getQuestionById",
  CREATE_QUESTION = "questions/createQuestion",
  GET_TOP_QUESTIONS = "questions/getTopQuestions",
  DELETE_QUESTION = "questions/deleteQuestion",
  SEARCH_QUESTIONS = "questions/searchQuestions",
  MERGE_QUESTIONS = "questions/mergeQuestions",
  UPDATE_QUESTION_TEXT = "questions/updateQuestionText",
  CREATE_QUESTION_TASK = "questions/createQuestionTask",
  UPDATE_QUESTION_TASK = "questions/updateQuestionTask",
  CREATE_VOTE = "votes/createVoteToQuestion",
  GET_VOTES = "votes/getVotes",
  GET_VOTES_BY_QUESTION = "votes/getVotesByQuestionId",
  EXCEL_EXPORT = "/questions/exportToExcel",
}
//EXAMPLE: need to add real url here
export enum ShoppingToolApiEndpoints {
  GET_BY_ID = "shoppingtool/getByid",
}

export type ApiEndpoints = MfaqApiEndpoints | ShoppingToolApiEndpoints;
