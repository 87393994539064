import { Trans } from "@coworker/locales";
import React from "react";
import styled from "styled-components";
import { Container, OverflowBackground } from "../CommonComponents";
import { HFBList } from "./HFBList";
import { SearchInput } from "./SearchInput";
import { DivisionComponent } from "../UserFiltersRow/Divisions/DivisionComponent";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import useFlag from "../../../hooks/useFlag";
//@ts-ignore
import { FLAGS } from "@coworker/functions/src/enums/featureFlags";

const RoundedContainer = styled(Container)`
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 24px;
`;

const SearchContainer = styled.div`
  margin: 24px;
`;

interface PopupProps {
  onClose: () => void;
  onSubmit: (area: string) => void;
  initialArea?: string;
}

export function AreaSearchListPopup({ onClose, onSubmit }: PopupProps) {
  const [searchValue, setSearchValue] = React.useState<string>();
  const enableDivisionsArea = useFlag(FLAGS.DIVISIONS_IN_FILTER);
  const store_id = useStoreId();

  const onSelectedArea = React.useCallback(
    (area: any) => {
      onSubmit(area);
    },
    [onSubmit]
  );

  return (
    <>
      <OverflowBackground data-testid="background" onClick={onClose} />
      <RoundedContainer data-testid="number-of-articles-container">
        <Title>
          <Trans>areaString</Trans>
        </Title>
        {enableDivisionsArea ? (
          <DivisionComponent storeId={store_id} setChecked={onSelectedArea} />
        ) : (
          <>
            {" "}
            <SearchContainer>
              <SearchInput value={searchValue} setValue={setSearchValue} />
            </SearchContainer>
            <HFBList filter={searchValue} onSelect={onSelectedArea} />
          </>
        )}
      </RoundedContainer>
    </>
  );
}
