import React from "react";
import { Trans } from "@coworker/locales";
import { useInputPopup } from "../InputPopup";
import { updateTaskWithImages } from "../../services/task.service";
import { useToastNotification } from "@coworker/components";
import {
  useWorkspacesState,
  useWorkspacesAction,
} from "../../hooks/useWorkspaces";
import { useCreateTask } from "../../hooks/TaskActions/useCreateTask";
import { useCompleteWithChanges } from "../../hooks/TaskActions/useCompleteWithChanges";
import { usePrepareForm } from "../../hooks/TaskActions/usePrepareForm";
import trackerHelper from "../../helpers/tracker";
import { useStoreId } from "../../core/auth/useLoggedInUser";

type FormMode = "create" | "edit" | "complete";

interface TaskFormProps {
  config: any;
  task_id: string;
  formType: string;
  formMode: FormMode;
  baseTask: any;
}

export function useTaskForm({
  config,
  task_id,
  formType,
  formMode,
  baseTask,
}: TaskFormProps) {
  const { push, pop, toCheckPoint } = useWorkspacesAction();
  const { navigationState } = useWorkspacesState();
  const useBeforeCreate = config.useBeforeCreate || (() => () => true);
  const usePostCreate = config.usePostCreate || (() => () => true);
  const [creating, setCreating] = React.useState(false);
  const { getInput, closePopup } = useInputPopup();
  const { showToast } = useToastNotification();
  const useReactToChanges = config.useReactToChanges || (() => null);
  const isRepeatableTask = formType === "planned";
  const dbCollection = isRepeatableTask ? "repeatable_tasks" : "tasks";
  const taskPath = `${dbCollection}/${task_id}`;
  const isCreate = formMode === "create";
  const isEdit = formMode === "edit";
  const isComplete = formMode === "complete";
  const storeId = useStoreId();
  const {
    preparedForm,
    images,
    isFormValid,
    editFields,
    oldImages,
    task,
    form,
  } = usePrepareForm({
    baseTask,
    task_id,
    config,
    formType,
    isCreate,
  });
  useReactToChanges(task_id, preparedForm, editFields);

  const beforeCreate = useBeforeCreate({ form: preparedForm });
  const postCreate = usePostCreate({ form: preparedForm });

  const { call: createTask } = useCreateTask();
  const createTaskAction = async () => {
    setCreating(true);

    if (!(await beforeCreate())) {
      return;
    }
    const newTaskId = await createTask(preparedForm, images);

    showToast(
      config.createCurtainMessage || <Trans>curtainAddedString</Trans>,
      <Trans>curtainViewString</Trans>,
      () => {
        push(`/task/${newTaskId}`);
        closePopup();
      }
    );

    if (postCreate()) {
      pop(1, true);
      if (formType === "product_quality") {
        await getInput("pqReportSubmitted");
      }
    }
  };

  const formFinished = React.useCallback(() => {
    if (navigationState.back) {
      push(navigationState.back);
    } else {
      if (isEdit || isComplete) {
        toCheckPoint();
      } else {
        pop();
      }
    }
  }, [pop, push, navigationState, isEdit, isComplete, toCheckPoint]);

  const updateTaskAction = React.useCallback(async () => {
    await updateTaskWithImages(
      preparedForm,
      task_id,
      oldImages,
      images,
      storeId
    );

    showToast(
      <Trans>curtainUpdateString</Trans>,
      <Trans>curtainViewString</Trans>,
      () => push(`/task/${task_id}`)
    );

    formFinished();
  }, [
    images,
    task_id,
    oldImages,
    preparedForm,
    push,
    showToast,
    formFinished,
    storeId,
  ]);

  const { call: completeWithChanges } = useCompleteWithChanges(
    preparedForm,
    task_id
  );
  const completeWithChangesAction = React.useCallback(async () => {
    const data = await completeWithChanges();
    if (!data) return;
    formFinished();
  }, [completeWithChanges, formFinished]);

  const closeForm = React.useCallback(async () => {
    const fieldsFilled = config.fieldsFilledCount(form);
    if (fieldsFilled) {
      const response = await getInput("confirmation", {
        question: <Trans>questionString</Trans>,
        description: <Trans>descriptionString</Trans>,
        positiveText: <Trans>positiveString</Trans>,
        negativeText: <Trans>negativeString</Trans>,
      });

      trackerHelper.trackCreateTaskAbandon(
        form.task_type || formType,
        response ? "YES" : "NO",
        fieldsFilled
      );

      if (response) {
        if (navigationState.back) {
          push(navigationState.back);
        } else {
          pop();
        }
      }
    } else {
      if (navigationState.back) {
        push(navigationState.back);
      } else {
        pop();
      }
    }
  }, [push, pop, getInput, navigationState, form, formType, config]);

  return {
    closeForm,
    isFormValid,
    creating,
    createTaskAction,
    updateTaskAction,
    completeWithChangesAction,
    preparedForm,
    form,
    editFields,
    taskPath,
    formMode,
    task,
    images,
  };
}
