import { getTokenAsync } from "../../../hooks/API/getAuthToken";
import { makeHeaders } from "../tools";
import { TaskLink, TaskLinks } from "../types/taskLink";
import { ROOM_SETTINGS_SERVICE_URL } from "./service.helper";

const BASE_URL = ROOM_SETTINGS_SERVICE_URL + "/tasks";

export const insertTaskLink = async (taskLink: TaskLink) => {
  try {
    const token = await getTokenAsync(true);
    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: makeHeaders(token),
      body: JSON.stringify(taskLink),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data = (await response.json()) as { id: string };
    return data.id;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return null;
};

export const getTaskLinksByRoom = async (roomId: string) => {
  try {
    const token = await getTokenAsync(true);
    const response = await fetch(`${BASE_URL}/rooms/${roomId}`, {
      method: "GET",
      headers: makeHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data = (await response.json()) as TaskLinks;
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return null;
};

export const getTaskLinksByStore = async (storeId: string) => {
  try {
    const token = await getTokenAsync(true);

    const response = await fetch(
      `${BASE_URL}/stores/${encodeURIComponent(storeId)}`,
      {
        method: "GET",
        headers: makeHeaders(token),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data = (await response.json()) as TaskLinks;
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return null;
};

export const getTaskLinksByArticle = async (
  storeId: string,
  productArticleId: string
) => {
  const token = await getTokenAsync(true);
  try {
    const response = await fetch(
      `${BASE_URL}/stores/${encodeURIComponent(
        storeId
      )}/articles/${productArticleId}`,
      {
        method: "GET",
        headers: makeHeaders(token),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data = (await response.json()) as TaskLinks;
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return null;
};

export const deleteTaskLinks = async (taskIds: string[]) => {
  try {
    const token = await getTokenAsync(true);
    const response = await fetch(`${BASE_URL}?ids=${taskIds.join(",")}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data = (await response.json()) as TaskLinks;
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return null;
};
