import React from "react";
import styled, { css } from "styled-components";
import { TextArea, Button, Selector } from "@coworker/components";
import { Trans, useTranslation } from "@coworker/locales";
import { useTextAreaPopup } from "./_common";
import useNullToggler from "../../hooks/useNullToggler";

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
`;
const ScrollContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll;
`;
const ButtonWrapper = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  flex-shrink: 0;
`;
const StyledButton = styled(Button)`
  width: 90%;
`;
const StyledArea = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-shrink: 0;
  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
    `}
`;
const Optional = styled.div`
  font-size: 14px;
  color: var(--grey500);
  position: absolute;
  top: 36px;
  right: 29px;
`;
const Required = styled.div`
  font-size: 14px;
  color: var(--grey900);
  position: absolute;
  top: 36px;
  right: 29px;
  font-weight: bold;
`;

const SelectIssue = (props) => {
  const selector = useNullToggler(props.issue);
  const [description, setDescription] = React.useState(props.description);
  const containerRef = React.useRef(null);
  const { textArea, onFocus, onBlur, inFocus } = useTextAreaPopup(
    containerRef,
    description,
    { focusOnMount: false, scrollToBottomOnInit: false }
  );
  const { t } = useTranslation();

  const checked = props.content.find((c) => c.type === selector.checked) || {};
  const placeholder = () =>
    checked.descriptionRequired && inFocus
      ? t("addDescriptionRequiredString")
      : t("addDescriptionPlainString");

  React.useEffect(() => {
    if (checked?.descriptionRequired) {
      textArea.current.focus();
    }
  }, [checked.descriptionRequired, textArea]);

  return (
    <Container data-testid="selectIssuePopup" ref={containerRef}>
      <ScrollContainer>
        <Selector
          {...selector}
          issue={selector.issue}
          content={props.content}
          numberOfColumns={2}
        />
        <StyledArea>
          <TextArea
            data-testid="descriptionInput"
            wrap="hard"
            value={description}
            maxLength={250}
            placeholder={placeholder()}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            ref={textArea}
          />
          {!inFocus && !checked.descriptionRequired && !description && (
            <Optional>
              <Trans>optionalString</Trans>
            </Optional>
          )}
          {!inFocus && checked.descriptionRequired && !description && (
            <Required>
              <Trans>requiredString</Trans>
            </Required>
          )}
        </StyledArea>
      </ScrollContainer>
      <ButtonWrapper>
        <StyledButton
          data-testid="selectConfirm"
          type="submit"
          text={<Trans>continue2String</Trans>}
          primary={true}
          dark={true}
          onClick={() => {
            props.onSubmit({ issue: selector.checked, description });
          }}
          disabled={
            !selector.checked || (checked.descriptionRequired && !description)
          }
        />
      </ButtonWrapper>
    </Container>
  );
};

export default SelectIssue;
