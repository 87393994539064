import React from "react";
import styled, { css } from "styled-components";
import tracker from "../../helpers/tracker";
import { InsightsSkeleton } from "@coworker/components";
import useFormatter from "../../hooks/useFormatter";
import { ReactComponent as HomeIcon } from "../../assets/svg/icon-insights-home.svg";
import { ReactComponent as AwayIcon } from "../../assets/svg/icon-insights-away.svg";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import { Trans } from "@coworker/locales";
import { ReactComponent as Arrow } from "../../assets/svg/arrow-back.svg";
import { useTranslation } from "@coworker/locales";
import { calculateFontSize } from "@coworker/reusable";
const _ = require("lodash");

const StyledHomeIcon = styled(HomeIcon)`
  position: absolute;
  left: 15px;
`;
const StyledAwayIcon = styled(AwayIcon)`
  position: absolute;
  left: 15px;
`;

const Nothing = styled.div`
  color: #484848;
  font-size: 14px;
  margin-bottom: 36px;
  width: 100%;
  margin-left: -15px;
  margin-top: 30px;
`;

const Bottom = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  & > div {
    min-height: 100%;
    box-sizing: border-box;
    width: 50%;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
  }
`;

const Number = styled.div`
  height: 38px;
  font-weight: bold;
  margin-bottom: 13px;
  overflow: hidden;
  max-width: calc(50vw - 20px);
  word-break: break-all;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  ${(size) => css`
    font-size: ${size || "28px"};
  `}
`;

const LinkButton = styled.div`
  position: relative;
  background: var(--grey100);
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  color: ${(props) => props.disabled && "var(--grey700)"};
  ${(props) => props.clickable && !props.disabled && "cursor: pointer;"}
  & > * {
    color: ${(props) => props.disabled && "var(--grey700)"};
    ${(props) => props.clickable && !props.disabled && "cursor: pointer;"}
  }
`;

const Text = styled.div`
  margin-left: 30px;
  font-size: 11px;
  font-weight: bold;
`;

const StyledArrow = styled(Arrow)`
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  margin-left: 17px;
`;

export function DepartmentDetail({
  onChangeInsightsParams,
  department,
  data,
  loading,
  savedHomeValueToShow,
}) {
  const { push } = useWorkspacesAction();
  const { t } = useTranslation();

  const default_home_value = savedHomeValueToShow;

  const pieces = default_home_value === "pieces";
  const { formatNumber, formatCurrency } = useFormatter();

  function formatPieces(value) {
    return typeof value === "number"
      ? `${formatNumber(Math.abs(value))} ${t("pcsString")}`
      : "";
  }
  const reformat = (value) =>
    pieces ? formatPieces(value) : formatCurrency(value, data?.currency);
  const home = reformat(pieces ? data?.home_pieces : data?.home_turnover);
  const away = reformat(pieces ? data?.away_pieces : data?.away_turnover);

  if (loading) {
    return <InsightsSkeleton />;
  }
  if (!home && !away) {
    return (
      <Bottom>
        <Nothing data-testid={`insights_department_empty_${department}`}>
          {<Trans>nothingToShowString</Trans>}
        </Nothing>
      </Bottom>
    );
  }
  const fontSize = calculateFontSize(Math.max(home.length, away.length));
  return (
    <Bottom
      data-testid={`department_detail_box`}
      data-departmentid={department}
    >
      <div>
        <div>
          <Number data-testid="department_home_number" fontSize={fontSize}>
            {home}
          </Number>
        </div>
        <div>
          <LinkButton
            disabled={!home}
            data-testid={`home_${department}`}
            onClick={
              !home
                ? null
                : () => {
                    tracker.insights.trackHFBLinkTap("home", department);
                    const updatedInsightsFilter = {};
                    _.set(
                      updatedInsightsFilter,
                      ["deptFilters", "hfb"],
                      department
                    );
                    _.set(
                      updatedInsightsFilter,
                      ["deptFilters", "salesShare"],
                      "HOME"
                    );
                    onChangeInsightsParams(updatedInsightsFilter);
                    push(`/insights/department/${department}`);
                  }
            }
          >
            <StyledHomeIcon />
            <Text>
              <Trans>homeDepartmentString</Trans>
            </Text>
            <StyledArrow />
          </LinkButton>
        </div>
      </div>
      <div>
        <div>
          <Number data-testid="department_away_number" fontSize={fontSize}>
            {away}
          </Number>
        </div>
        <div>
          <LinkButton
            disabled={!away}
            data-testid={`away_${department}`}
            onClick={
              !away
                ? null
                : () => {
                    tracker.insights.trackHFBLinkTap("away", department);
                    const updatedInsightsFilter = {};
                    _.set(
                      updatedInsightsFilter,
                      ["deptFilters", "hfb"],
                      department
                    );
                    _.set(
                      updatedInsightsFilter,
                      ["deptFilters", "salesShare"],
                      "AWAY"
                    );
                    onChangeInsightsParams(updatedInsightsFilter);
                    push(`/insights/department/${department}`);
                  }
            }
          >
            <StyledAwayIcon />
            <Text>
              <Trans>awayDepartmentString</Trans>
            </Text>
            <StyledArrow />
          </LinkButton>
        </div>
      </div>
    </Bottom>
  );
}
