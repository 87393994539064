import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { DetailSection, DetailSectionLeft } from "./_common";
import { issueTypeTitle } from "../../issueTypeTranslations";
import { isFinishedState } from "../../../constants/taskState";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import Badges from "../../Badges";

const TaskTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TaskTitle = styled.h1`
  line-height: 34px;
  font-size: 28px;
  letter-spacing: -0.34px;
  color: black;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  flex-grow: 1;
  flex-shrink: 1;
`;

const TaskStatusText = styled.div`
  align-self: flex-end;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 2px;
  color: var(${({ color }) => color});
`;

function BasicInfo({ task }) {
  return (
    <DetailSection>
      <DetailSectionLeft>
        <Badges task={task} skipFinishedState />
        <TaskTitleRow>
          <TaskTitle data-testid="taskTitle">
            {task.picked ||
            (task.task_type === taskTypeOptions.ADDON &&
              (task.state === taskStateOptions.CLOSED ||
                task.state === taskStateOptions.COMPLETED)) ? (
              <Trans>refillString</Trans>
            ) : (
              task.custom_title ||
              issueTypeTitle[task.task_type][task.issue_type] ||
              issueTypeTitle[task.task_type].default
            )}
          </TaskTitle>
          {isFinishedState(task.state) && (
            <TaskStatus state={task.state} type={task.task_type} />
          )}
        </TaskTitleRow>
      </DetailSectionLeft>
    </DetailSection>
  );
}

function TaskStatus({ state, type }) {
  return (
    <TaskStatusText
      data-testid="taskStatus"
      color={getStatusColor(state, type)}
    >
      <Trans>{getStatusTranslationKey(state, type)}</Trans>
    </TaskStatusText>
  );
}

function getStatusColor(state, type) {
  if (type === taskTypeOptions.PRODUCT_QUALITY) {
    if (state === taskStateOptions.COMPLETED) {
      return "--success";
    } else {
      return "--error";
    }
  } else {
    if (state === taskStateOptions.COMPLETED) {
      return "--success";
    } else {
      return "--grey500";
    }
  }
}

function getStatusTranslationKey(state, type) {
  if (type === taskTypeOptions.PRODUCT_QUALITY) {
    if (state === taskStateOptions.COMPLETED) {
      return "acceptedString";
    } else {
      return "rejectedString";
    }
  } else {
    if (state === taskStateOptions.COMPLETED) {
      return "completedTask";
    } else {
      return "closedTask";
    }
  }
}

export default BasicInfo;
