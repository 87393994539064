import React from "react";
import styled from "styled-components";
import { TestBuy } from "@coworker/types";
import { usePeriodTimestamps } from "../../../../InputPopup/UserFiltersRow/usePeriodTimestamps";
import { useUserPreference } from "../../../../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences.json";
import { defaultFilters } from "../../../../InputPopup/UserFiltersRow/filterChoices";
import { PassRatioProps } from "../PassRatioProps";
import { Trans, useTranslation } from "@coworker/locales";
import dayjs from "dayjs";
import { useGetOptions } from "./hooks/useGetOptions";
import { useGetData } from "./hooks/useGetData";
import { ItemRow, ItemRowWrapper } from "../PassRatioDonutOverview";
import { useLazyChartJs } from "./hooks/useLazyChartJS";
import { getChartData } from "./helper/LineChart.helper";
import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";

const Container = styled.div`
  margin-bottom: 20px;
`;

const TitleWrapper = styled.div`
  font-size: 12px;
  color: var(--grey700);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleBold = styled(TitleWrapper)`
  margin: 0px 3px;
  font-weight: bold;
`;
interface LineChartProps {
  filters: string;
  testbuys: TestBuy[];
  passRatio: PassRatioProps;
}

export const LineChart = ({
  filters: period,
  testbuys,
  passRatio: totalPassRatio,
}: LineChartProps) => {
  const [title, setTitle] = React.useState<string>("");
  const { t } = useTranslation();
  const [filters] = useUserPreference(
    profilePreferences.TESTBUY_PREFIX + profilePreferences.USER_FILTERS,
    defaultFilters
  );

  const Line = useLazyChartJs();

  const [sinceTs, untilTs] = usePeriodTimestamps(
    filters,
    profilePreferences.TESTBUY_PREFIX
  );

  const fromCustomDate = dayjs(new Date(sinceTs || 0)).format("DD/MM/YY");
  const toCustomDate = dayjs(new Date(untilTs || 0)).format("DD/MM/YY");

  React.useEffect(() => {
    if (period === "period=7days") {
      setTitle("last7DaysString");
    } else {
      setTitle("last4WeeksString");
    }
  }, [title, period]);

  const data = useGetData(testbuys, sinceTs || 0, untilTs || 0, period);
  const labelsAndDataset = { ...data };

  if (period === "period=7days") {
    labelsAndDataset.labels =
      data?.labels?.map((label) => t(label as string)) || [];
  }

  const dataForOptions = getChartData(
    testbuys,
    sinceTs || 0,
    untilTs || 0,
    period
  );
  const options = useGetOptions(dataForOptions, period);

  if (typeof window !== "undefined") {
    window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
  }

  return (
    <Container>
      <TitleWrapper>
        <Trans>percentageFromString</Trans>
        {period !== "period=custom" ? (
          <TitleBold>
            <Trans>{title}</Trans>
          </TitleBold>
        ) : (
          <>
            <TitleBold>{fromCustomDate}</TitleBold>
            <Trans>itemsToString</Trans>
            <TitleBold>{toCustomDate}</TitleBold>
          </>
        )}
      </TitleWrapper>
      <Line options={options} data={labelsAndDataset} />
      <ItemRowWrapper>
        {/* Following dashboards way to just get data from 1 sep, 2022. Also because finishedAt does not exist on old data. */}
        {dayjs(sinceTs).isBefore(dayjs("2022-09-01")) && (
          <ItemRow>
            <Trans>dataSinceString</Trans>
            <b>Sep 1, 2022</b>
          </ItemRow>
        )}
        <ItemRow>
          <Trans>testBuysDoneString</Trans>
          <b>{totalPassRatio.totalTestbuysDone}</b>
        </ItemRow>
        <ItemRow>
          <Trans>totalArticlesBoughtString</Trans>
          <b>{totalPassRatio.totalArticles}</b>
        </ItemRow>
      </ItemRowWrapper>
    </Container>
  );
};
